import { ACTION_TYPE } from "../action/type";
const initialState = {
  homeResponesLoading: false,
  homeResponseSuccess: null,
  homeResponseError: null,
  //contactus
  contactusLoding: false,
  contactusSuccess: null,
  contactusFail: null,
  //contactus
  dailyMetalPriceLoding: false,
  dailyMetalPriceSuccess: {
    "data": {
        "allDailyMetalPrices": {
            "nodes": []
        },
        "allMasterCountries": {
            "nodes": [
                {
                    "currency": "India Rupee",
                    "currencyAlias": "INR",
                    "currencySymbol": "₹",
                    "fxConversionRate": 1,
                    "id": 1,
                    "isActive": true,
                    "iso": "IN",
                    "iso3": "IND",
                    "name": "INDIA",
                    "nicename": "India",
                    "numcode": "356",
                    "phonecode": "91"
                },
                {
                    "currency": "Australian Dollar",
                    "currencyAlias": "AUD",
                    "currencySymbol": "$",
                    "fxConversionRate": 53.48,
                    "id": 13,
                    "isActive": true,
                    "iso": "AU",
                    "iso3": "AUS",
                    "name": "AUSTRALIA",
                    "nicename": "Australia",
                    "numcode": "36",
                    "phonecode": "61"
                },
                {
                    "currency": "Malaysian Ringgit",
                    "currencyAlias": "MYR",
                    "currencySymbol": "RM",
                    "fxConversionRate": 17.67,
                    "id": 129,
                    "isActive": true,
                    "iso": "MY",
                    "iso3": "MYS",
                    "name": "MALAYSIA",
                    "nicename": "Malaysia",
                    "numcode": "458",
                    "phonecode": "60"
                },
                {
                    "currency": "Singapore Dollar",
                    "currencyAlias": "SGD",
                    "currencySymbol": "$",
                    "fxConversionRate": 57.18,
                    "id": 192,
                    "isActive": true,
                    "iso": "SG",
                    "iso3": "SGP",
                    "name": "SINGAPORE",
                    "nicename": "Singapore",
                    "numcode": "702",
                    "phonecode": "65"
                },
                {
                    "currency": "United Arab Emirates Dirham",
                    "currencyAlias": "AED",
                    "currencySymbol": "د.إ",
                    "fxConversionRate": 22.11,
                    "id": 224,
                    "isActive": true,
                    "iso": "AE",
                    "iso3": "ARE",
                    "name": "UNITED ARAB EMIRATES",
                    "nicename": "United Arab Emirates",
                    "numcode": "784",
                    "phonecode": "971"
                },
                {
                    "currency": "Pound Sterling",
                    "currencyAlias": "GBP",
                    "currencySymbol": "£",
                    "fxConversionRate": 91.74,
                    "id": 225,
                    "isActive": true,
                    "iso": "GB",
                    "iso3": "GBR",
                    "name": "UNITED KINGDOM",
                    "nicename": "United Kingdom",
                    "numcode": "826",
                    "phonecode": "44"
                },
                {
                    "currency": "United States Dollar",
                    "currencyAlias": "USD",
                    "currencySymbol": "$",
                    "fxConversionRate": 82.22,
                    "id": 226,
                    "isActive": true,
                    "iso": "US",
                    "iso3": "USA",
                    "name": "UNITED STATES",
                    "nicename": "United States",
                    "numcode": "840",
                    "phonecode": "1"
                }
            ]
        }
    }
},
  dailyMetalPriceFail: null,
  //selectedcountry
  selectedcountryLoading: false,
  selectedcountrySuccess: {
    currency: "India Rupee",
    currencyAlias: "INR",
    currencySymbol: "₹",
    fxConversionRate: 1,
    id: 1,
    isActive: true,
    iso: "IN",
    iso3: "IND",
    name: "INDIA",
    nicename: "India",
    numcode: "356",
    phonecode: "91",
  },
  selectedcountryFail: null,
  //mailsubscription
  mailsubscriptionResponesLoading: false,
  mailsubscriptionResponseSuccess: null,
  mailsubscriptionResponseError: null,
  //searchmenu
  searchMenuResponseLoading: false,
  searchMenuResponseSuccess: null,
  searchMenuResponseError: null,
  //homeMainBannerResponse
  homeMainBannerResponseLoading: false,
  homeMainBannerResponseSuccess: null,
  homeMainBannerResponseError: null,
  //homeSubBannerResponse
  homeSubBannerResponseLoading: false,
  homeSubBannerResponseSuccess: null,
  homeSubBannerResponseError: null,
  //bestSellerResponse
  bestSellerResponseLoading: false,
  bestSellerResponseSuccess: null,
  bestSellerResponseError: null,
  //navarathnaCollectionResponse
  shopbyfinishCollectionResponseLoading: false,
  shopbyfinishCollectionResponseSuccess: null,
  shopbyfinishCollectionResponseError: null,
  //readOurBlogsDataResponse
  readOurBlogsDataResponseLoading: false,
  readOurBlogsDataResponseSuccess: null,
  readOurBlogsDataResponseError: null,
  //video DataResponse
  videoDataResponesLoading: false,
  videoDataResponseSuccess: null,
  videoDataResponseError: null,
  //testimonial
  testimonialDataResponesLoading: false,
  testimonialDataResponseSuccess: null,
  testimonialDataResponseError: null,

  //products count card
  productsCountCardDataResponesLoading: false,
  productsCountCardDataResponseSuccess: null,
  productsCountCardDataResponseError: null,
  //INSTAGRAM LINK LIST
  instagramLinkResponseLoading: false,
  instagramLinkResponseSuccess: null,
  instagramLinkResponseError: null,
  //get faq data
  faqDataResponseLoading: false,
  faqDataResponseSuccess: null,
  faqDataResponseError: null,
  //privacy policy
  privacyPolicyDataResponseLoading: false,
  privacyPolicyDataResponseSuccess: null,
  privacyPolicyDataResponseError: null,
  //terms of use
  termsofUseDataResponseLoading: false,
  termsofUseDataResponseSuccess: null,
  termsofUseDataResponseError: null,
};
const homeNotificationinitialState = {
  homeNotificationResponesLoading: false,
  homeNotificationResponseSuccess: [],
  homeNotificationResponseError: null,
};

const headerInitialState = {
  headerResponesLoading: false,
  headerResponseSuccess: null,
  headerResponseError: null,
};

export function homeReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.GET_HOME_DATA_REQUEST:
      return {
        ...state,
        homeResponesLoading: true,
        homeResponseSuccess: null,
        homeResponseError: null,
      };
    //request mail subscription
    case ACTION_TYPE.MAIL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        mailsubscriptionResponesLoading: true,
        mailsubscriptionResponseSuccess: null,
        mailsubscriptionResponseError: null,
      };
    //success mail subscription
    case ACTION_TYPE.MAIL_SUBSCRIPTION_RESPONSE_SUCCESS:
      return {
        ...state,
        mailsubscriptionResponesLoading: false,
        mailsubscriptionResponseSuccess: action.payload,
        mailsubscriptionResponseError: null,
      };
    case ACTION_TYPE.MAIL_SUBSCRIPTION_RESPONSE_SUCCESS_CLEAR:
      return {
        ...state,
        mailsubscriptionResponesLoading: false,
        mailsubscriptionResponseSuccess: null,
        mailsubscriptionResponseError: null,
      };
    //failed mail subscription
    case ACTION_TYPE.MAIL_SUBSCRIPTION_RESPONSE_FAILURE:
      return {
        ...state,
        mailsubscriptionResponesLoading: false,
        mailsubscriptionResponseSuccess: null,
        mailsubscriptionResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_HOME_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        homeResponesLoading: false,
        homeResponseSuccess: action.payload.homeResponseSuccess,
        homeResponseError: null,
      };
    case ACTION_TYPE.GET_HOME_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        homeResponesLoading: false,
        homeResponseSuccess: null,
        homeResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_HOME_MAIN_BANNER_DATA_REQUEST:
      return {
        ...state,
        homeMainBannerResponseLoading: true,
        homeMainBannerResponseSuccess: null,
        homeMainBannerResponseError: null,
      };
    case ACTION_TYPE.GET_HOME_MAIN_BANNER_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        homeMainBannerResponseLoading: false,
        homeMainBannerResponseSuccess:
          action.payload.homeMainBannerResponseSuccess,
        homeMainBannerResponseError: null,
      };
    case ACTION_TYPE.SELECTEDCOUNTRY_REQUEST:
      return {
        ...state,
        selectedcountryLoading: true,
        selectedcountrySuccess: null,
        selectedcountryFail: null,
      };
    case ACTION_TYPE.SELECTEDCOUNTRY_SUCCESS:
      return {
        ...state,
        selectedcountryLoading: false,
        selectedcountrySuccess: action.payload.countryselectedvalues,
        selectedcountryFail: null,
      };
    case ACTION_TYPE.SELECTEDCOUNTRY_FAIL:
      return {
        ...state,
        selectedcountryLoading: false,
        selectedcountrySuccess: null,
        selectedcountryFail: action.errorMessage,
      };
    case ACTION_TYPE.GET_HOME_MAIN_BANNER_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        homeMainBannerResponseLoading: false,
        homeMainBannerResponseSuccess: null,
        homeMainBannerResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_HOME_SUB_BANNER_DATA_REQUEST:
      return {
        ...state,
        homeSubBannerResponseLoading: true,
        homeSubBannerResponseSuccess: null,
        homeSubBannerResponseError: null,
      };
    case ACTION_TYPE.GET_HOME_SUB_BANNER_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        homeSubBannerResponseLoading: false,
        homeSubBannerResponseSuccess:
          action.payload.homeSubBannerResponseSuccess,
        homeSubBannerResponseError: null,
      };
    case ACTION_TYPE.GET_HOME_SUB_BANNER_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        homeSubBannerResponseLoading: false,
        homeSubBannerResponseSuccess: null,
        homeSubBannerResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_BESTSELLER_DATA_REQUEST:
      return {
        ...state,
        bestSellerResponseLoading: true,
        bestSellerResponseSuccess: null,
        bestSellerResponseError: null,
      };
    case ACTION_TYPE.GET_BESTSELLER_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        bestSellerResponseLoading: false,
        bestSellerResponseSuccess: action.payload.bestSellerResponseSuccess,
        bestSellerResponseError: null,
      };
    case ACTION_TYPE.GET_BESTSELLER_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        bestSellerResponseLoading: false,
        bestSellerResponseSuccess: null,
        bestSellerResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_SHOPBYFINISH_COLLECTION_DATA_REQUEST:
      return {
        ...state,
        shopbyfinishCollectionResponseLoading: true,
        shopbyfinishCollectionResponseSuccess: null,
        shopbyfinishCollectionResponseError: null,
      };
    case ACTION_TYPE.GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        shopbyfinishCollectionResponseLoading: false,
        shopbyfinishCollectionResponseSuccess:
          action.payload.shopbyfinishCollectionResponseSuccess,
        shopbyfinishCollectionResponseError: null,
      };
    case ACTION_TYPE.GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        shopbyfinishCollectionResponseLoading: false,
        shopbyfinishCollectionResponseSuccess: null,
        shopbyfinishCollectionResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_READ_OUR_BLOGS_DATA_REQUEST:
      return {
        ...state,
        readOurBlogsDataResponseLoading: true,
        readOurBlogsDataResponseSuccess: null,
        readOurBlogsDataResponseError: null,
      };
    case ACTION_TYPE.GET_READ_OUR_BLOGS_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        readOurBlogsDataResponseLoading: false,
        readOurBlogsDataResponseSuccess:
          action.payload.readOurBlogsDataResponseSuccess,
        readOurBlogsDataResponseError: null,
      };
    case ACTION_TYPE.GET_READ_OUR_BLOGS_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        readOurBlogsDataResponseLoading: false,
        readOurBlogsDataResponseSuccess: null,
        readOurBlogsDataResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_VIDEO_DATA_REQUEST:
      return {
        ...state,
        videoDataResponesLoading: true,
        videoDataResponseSuccess: null,
        videoDataResponseError: null,
      };
    case ACTION_TYPE.GET_VIDEO_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        videoDataResponesLoading: false,
        videoDataResponseSuccess: action.payload.videoDataResponseSuccess,
        videoDataResponseError: null,
      };
    case ACTION_TYPE.GET_VIDEO_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        videoDataResponesLoading: false,
        videoDataResponseSuccess: null,
        videoDataResponseError: action.errorMessage,
      };
    //testimonial
    case ACTION_TYPE.GET_TESTIMONIAL_DATA_REQUEST:
      return {
        ...state,
        testimonialDataResponesLoading: true,
        testimonialDataResponseSuccess: null,
        testimonialDataResponseError: null,
      };
    case ACTION_TYPE.GET_TESTIMONIAL_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        testimonialDataResponesLoading: false,
        testimonialDataResponseSuccess:
          action.payload.gettestimonialdataResponseSuccess,
        testimonialDataResponseError: null,
      };
    case ACTION_TYPE.GET_TESTIMONIAL_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        testimonialDataResponesLoading: false,
        testimonialDataResponseSuccess: null,
        testimonialDataResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_PRODUCT_COUNT_CARD_DATA_REQUEST:
      return {
        ...state,
        productsCountCardDataResponesLoading: true,
        productsCountCardDataResponseSuccess: null,
        productsCountCardDataResponseError: null,
      };
    case ACTION_TYPE.GET_PRODUCT_COUNT_CARD_DATA_SUCCESS:
      return {
        ...state,
        productsCountCardDataResponesLoading: false,
        productsCountCardDataResponseSuccess:
          action.payload.productsCountCardDataResponseSuccess,
        productsCountCardDataResponseError: null,
      };
    case ACTION_TYPE.GET_PRODUCT_COUNT_CARD_DATA_FAILURE:
      return {
        ...state,
        productsCountCardDataResponesLoading: false,
        productsCountCardDataResponseSuccess: null,
        productsCountCardDataResponseError: action.errorMessage,
      };
    case ACTION_TYPE.CONTACTUSFORM_REQUEST:
      return {
        ...state,
        contactusLoding: true,
        contactusSuccess: null,
        contactusFail: null,
      };
    case ACTION_TYPE.CONTACTUSFORM_RESET:
      return {
        ...state,
        contactusLoding: false,
        contactusSuccess: null,
        contactusFail: null,
      };
    case ACTION_TYPE.CONTACTUSFORM_SUCCESS:
      return {
        ...state,
        contactusLoding: false,
        contactusSuccess: action.payload,
        contactusFail: null,
      };
    case ACTION_TYPE.CONTACTUSFORM_FAIL:
      return {
        ...state,
        contactusLoding: false,
        contactusSuccess: null,
        contactusFail: action.errorMessage,
      };
    case ACTION_TYPE.SEARCHMENU_REQUEST:
      return {
        ...state,
        searchMenuResponseLoading: true,
        searchMenuResponseSuccess: null,
        searchMenuResponseError: null,
      };
    case ACTION_TYPE.SEARCHMENU_SUCCESS:
      return {
        ...state,
        searchMenuResponseLoading: false,
        searchMenuResponseSuccess: action.payload.searchdatainmenu,
        searchMenuResponseError: null,
      };
    case ACTION_TYPE.SEARCHMENU_FAIL:
      return {
        ...state,
        searchMenuResponseLoading: false,
        searchMenuResponseSuccess: null,
        searchMenuResponseError: action.errorMessage,
      };
    //for daily metalprice and currency
    case ACTION_TYPE.DAILYMETALANDCURRENCY_REQUEST:
      return {
        ...state,
        dailyMetalPriceLoding: true,
        dailyMetalPriceSuccess: null,
        dailyMetalPriceFail: null,
      };
    case ACTION_TYPE.DAILYMETALANDCURRENCY_SUCCESS:    
    return {
        ...state,
        dailyMetalPriceLoding: false,
        dailyMetalPriceSuccess: action.payload.data,
        dailyMetalPriceFail: null,
      };
    case ACTION_TYPE.DAILYMETALANDCURRENCY_FAIL:
      return {
        ...state,
        dailyMetalPriceLoding: false,
        dailyMetalPriceSuccess: null,
        dailyMetalPriceFail: action.errorMessage,
      };
    case ACTION_TYPE.INSTAGRAM_LINK_REQUEST:
      return {
        ...state,
        instagramLinkResponseLoading: true,
        instagramLinkResponseSuccess: null,
        instagramLinkResponseError: null,
      };
    case ACTION_TYPE.INSTAGRAM_LINK_REQUEST_SUCCESS:
      return {
        ...state,
        instagramLinkResponseLoading: false,
        instagramLinkResponseSuccess:
          action.payload.instagramLinkResponseSuccess,
        instagramLinkResponseError: null,
      };
    case ACTION_TYPE.INSTAGRAM_LINK_REQUEST_FAILURE:
      return {
        ...state,
        instagramLinkResponseLoading: false,
        instagramLinkResponseSuccess: null,
        instagramLinkResponseError: action.errorMessage,
      };
    case ACTION_TYPE.FAQ_DATA_REQUEST:
      return {
        ...state,
        faqDataResponseLoading: true,
        faqDataResponseSuccess: null,
        faqDataResponseError: null,
      };
    case ACTION_TYPE.FAQ_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        faqDataResponseLoading: false,
        faqDataResponseSuccess: action.payload.faqDataResponseSuccess,
        faqDataResponseError: null,
      };
    case ACTION_TYPE.FAQ_DATA_REQUEST_FAILURE:
      return {
        ...state,
        faqDataResponseLoading: false,
        faqDataResponseSuccess: null,
        faqDataResponseError: action.errorMessage,
      };
    case ACTION_TYPE.PRIVACY_POLICY_DATA_REQUEST:
      return {
        ...state,
        privacyPolicyDataResponseLoading: true,
        privacyPolicyDataResponseSuccess: null,
        privacyPolicyDataResponseError: null,
      };
    case ACTION_TYPE.PRIVACY_POLICY_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        privacyPolicyDataResponseLoading: false,
        privacyPolicyDataResponseSuccess:
          action.payload.privacyPolicyDataResponseSuccess,
        privacyPolicyDataResponseError: null,
      };
    case ACTION_TYPE.PRIVACY_POLICY_DATA_REQUEST_FAILURE:
      return {
        ...state,
        privacyPolicyDataResponseLoading: false,
        privacyPolicyDataResponseSuccess: null,
        privacyPolicyDataResponseError: action.errorMessage,
      };
    case ACTION_TYPE.TERMS_OF_USE_DATA_REQUEST:
      return {
        ...state,
        termsofUseDataResponseLoading: true,
        termsofUseDataResponseSuccess: null,
        termsofUseDataResponseError: null,
      };
    case ACTION_TYPE.TERMS_OF_USE_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        termsofUseDataResponseLoading: false,
        termsofUseDataResponseSuccess:
          action.payload.termsofUseDataResponseSuccess,
        termsofUseDataResponseError: null,
      };
    case ACTION_TYPE.TERMS_OF_USE_DATA_REQUEST_FAILURE:
      return {
        ...state,
        termsofUseDataResponseLoading: false,
        termsofUseDataResponseSuccess: null,
        termsofUseDataResponseError: action.errorMessage,
      };
    default:
      return state;
  }
}

export function homeNotificationReducer(
  state = homeNotificationinitialState,
  action
) {
  switch (action.type) {
    case ACTION_TYPE.GET_HOME_NOTIFICATION_REQUEST:
      return {
        ...state,
        homeNotificationResponesLoading: true,
        homeNotificationResponseSuccess: [],
        homeNotificationResponseError: null,
      };
    case ACTION_TYPE.GET_HOME_NOTIFICATION_RESPONSE_SUCCESS:
      return {
        ...state,
        homeNotificationResponesLoading: false,
        homeNotificationResponseSuccess:
          action.payload.homenotificationResponseSuccess,
        homeNotificationResponseError: null,
      };
    case ACTION_TYPE.GET_HOME_NOTIFICATION_RESPONSE_FAILURE:
      return {
        ...state,
        homeNotificationResponesLoading: false,
        homeNotificationResponseSuccess: [],
        homeNotificationResponseError: action.errorMessage,
      };

    default:
      return state;
  }
}

export function headerReducer(state = headerInitialState, action) {
  switch (action.type) {
    case ACTION_TYPE.GET_HEADER_DATA_REQUEST:
      return {
        ...state,
        headerResponesLoading: true,
        headerResponseSuccess: null,
        headerResponseError: null,
      };
    case ACTION_TYPE.GET_HEADER_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        headerResponesLoading: false,
        headerResponseSuccess: action.payload.headerResponseSuccess,
        headerResponseError: null,
      };
    case ACTION_TYPE.GET_HEADER_DATA_REQUEST_FAILURE:
      return {
        ...state,
        headerResponesLoading: false,
        headerResponseSuccess: null,
        headerResponseError: action.errorMessage,
      };

    default:
      return state;
  }
}
