import React, { useEffect } from 'react';
import Config from "../utilities/config";
import constant from '../utilities/constants';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import { formatCurrency, combineValues, formatDate,getValue,formatDatewithspecialcahracters } from '../utilities/helper';
import {getOrderfailPage} from "../redux/action/orderAction";
export default function PaymentFail(){
    const dispatch = useDispatch();
    const { orderid } = useParams();    
    const {orderResponseFail,orderResponseFailError,orderfailResponesLoading} = useSelector((state) => state.orderReducer);
    Config.logGeneral && console.log(orderResponseFailError,"orderResponseFailError");
    Config.logGeneral && console.log(orderResponseFail,"orderResponseFail");
    useEffect(() => {
        dispatch(getOrderfailPage(orderid));
    }, [dispatch, orderid]);
    if (orderfailResponesLoading) {
        return (
          <div className="bg-blue-500 text-white p-6">Loading data...</div>
        );
      }
    
      if (orderResponseFailError) {
        return (
          <div className="bg-red-500 text-white p-6">
            Error: {orderResponseFailError}
          </div>
        );
      }
    if (orderResponseFail && orderResponseFail.data?.data?.allOrders?.nodes[0]) {
        const orderData = orderResponseFail.data.data.allOrders.nodes[0];
        const shoppingCart = orderData.shoppingCartByCartId;
        const subtotal = shoppingCart?.discountedPrice || "NA";
        const formattedAmount = (subtotal !== "NA") ? formatCurrency(subtotal) : "₹0";
        //get the product info... from shoppingCartItemsByShoppingCartId
        const productInfo = orderData?.shoppingCartByCartId?.shoppingCartItemsByShoppingCartId?.nodes;
        const productName = combineValues(productInfo, 'transSkuListByProductSku.productListByProductId.productName', ' & ');
        const productskuIds = combineValues(productInfo, 'transSkuListByProductSku.generatedSku', ' & ');
        const discountPrice = combineValues(productInfo, 'transSkuListByProductSku.discountPrice', ',').split(",").reduce((acc, curr) => acc + parseFloat(curr), 0);
        const MarkPrice = combineValues(productInfo, 'transSkuListByProductSku.sellingPrice', ',').split(",").reduce((acc, curr) => acc + parseFloat(curr), 0);
        const discountshow = Math.floor(discountPrice - MarkPrice).toLocaleString('en-IN');
        const orderdate = orderData?.createdAt;
        const cartId = orderData?.cartId;
        const shippingCharge=formatCurrency(shoppingCart?.shippingCharge || 0) || "Free";  
        
        //DDth Month YYYY at HH:mm AM/PM
        const orderDate = orderdate && formatDate(orderdate, "DDth Month YYYY  HH:mm AM/PM");
        console.log(orderdate,"orderdatebbb")
        //DD-MM-YYYY
        const orderdateHeading=orderdate && formatDatewithspecialcahracters(orderdate, "DD/MM/YYYY");//formatDatewithspecialcahracters
        //ship by date
        const shipDate = orderdate && formatDate(orderdate, "DDth DayOfWeek",constant.shipping_days);    
        //delivery date
        const deliveryDate = orderdate && formatDate(orderdate, "DD-MM-YYYY",constant.shipping_days);    
        // const orderDateTime=orderdate && formatTime(orderdate);
        const cartAddress = shoppingCart?.cartAddressesByCartId?.nodes[0];
        return (          
          <>
            <div class="bg-custom-gradient-fail">
              <div class="container mx-auto px-4">
                <div class="grid grid-cols-12">
                  <div class="col-span-12">
                    <div className='pt-9 pb-14'>
                      <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg" className='mx-auto'>
                      <path d="M56.0591 56.5591C66.3088 46.3094 66.3088 29.6913 56.0591 19.4416C45.8093 9.1919 29.1913 9.1919 18.9416 19.4416C8.69185 29.6913 8.69185 46.3094 18.9416 56.5591C29.1913 66.8088 45.8093 66.8088 56.0591 56.5591Z" fill="#E33838"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M63.7551 42.4537C61.8873 53.3824 53.2645 62.0048 42.3361 63.8727L28.2048 49.7413C24.8232 46.9506 22.668 42.7273 22.668 38.0006C22.668 29.5974 29.4798 22.7855 37.883 22.7855C42.6098 22.7855 46.8331 24.9409 49.6238 28.3223L63.7551 42.4537Z" fill="#AC2727"/>
                      <circle cx="37.3828" cy="38.0004" r="15" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M37.3824 40.1224L42.6854 45.4254C42.9668 45.7067 43.3485 45.8648 43.7464 45.8648C44.1444 45.8648 44.526 45.7067 44.8074 45.4254C45.0888 45.144 45.2469 44.7623 45.2469 44.3644C45.2469 43.9664 45.0888 43.5847 44.8074 43.3034L39.5024 38.0004L44.8064 32.6974C44.9457 32.558 45.0561 32.3926 45.1315 32.2106C45.2068 32.0286 45.2456 31.8335 45.2455 31.6365C45.2455 31.4395 45.2066 31.2444 45.1312 31.0625C45.0558 30.8805 44.9452 30.7151 44.8059 30.5759C44.6666 30.4366 44.5012 30.3261 44.3192 30.2508C44.1371 30.1754 43.9421 30.1367 43.7451 30.1367C43.5481 30.1368 43.353 30.1756 43.171 30.2511C42.989 30.3265 42.8237 30.437 42.6844 30.5764L37.3824 35.8794L32.0794 30.5764C31.9411 30.433 31.7757 30.3187 31.5927 30.24C31.4097 30.1613 31.2129 30.1198 31.0137 30.118C30.8146 30.1162 30.617 30.154 30.4327 30.2294C30.2483 30.3047 30.0807 30.416 29.9398 30.5568C29.7989 30.6975 29.6875 30.865 29.612 31.0493C29.5365 31.2336 29.4984 31.4311 29.5001 31.6302C29.5017 31.8294 29.543 32.0263 29.6215 32.2093C29.7 32.3923 29.8142 32.5579 29.9574 32.6964L35.2624 38.0004L29.9584 43.3044C29.8152 43.4428 29.701 43.6084 29.6225 43.7914C29.544 43.9744 29.5027 44.1713 29.5011 44.3705C29.4994 44.5696 29.5375 44.7671 29.613 44.9514C29.6885 45.1358 29.7999 45.3032 29.9408 45.4439C30.0817 45.5847 30.2493 45.696 30.4337 45.7714C30.618 45.8467 30.8156 45.8846 31.0147 45.8827C31.2139 45.8809 31.4107 45.8394 31.5937 45.7607C31.7767 45.682 31.9421 45.5677 32.0804 45.4244L37.3824 40.1224Z" fill="#AC2727"/>
                      </svg>
                      <p className='font-roboto text-center font-medium text-[#222222] my-2 text-[26px]'>"Oops! Your Payment Failed"</p>
                      {/* <p className='text-center text-lg'>Your order <span className='text-teal-500 text-base font-bold'>#{orderData.id}</span> has been successfully placed.</p> */}
                      <p className='font-roboto text-center font-normal text-[#222222] text-base'>We couldn't process your payment. Don't worry, you can try again or use a different payment method.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white">
              <div class="container mx-auto lg:px-28 px-0 2xl:mb-16">
                <div class="lg:grid lg:grid-flow-col xs:grid-cols-12 gap-4">
                  <div class="lg:col-span-2 sm:col-span-1 lg:mb-0 xs:mb-2">
                    <div className='border-solid border border-slate-300 px-7 py-11'>
                          <p className='font-roboto flex items-center gap-2 font-medium text-xl text-[#222222] mb-3'>Your Payment Has Failed</p>
                          <div className='flex mb-3'>
                            <h3 className='font-roboto font-bold text-sm text-[#222222]'>Attempted Amount: <span className='font-normal'>{formattedAmount}</span></h3>
                            <h3 className='font-roboto font-bold text-sm text-[#222222] pl-10'>Date: <span className='font-normal'>{orderdateHeading}</span></h3>
                          </div>
                          <div className='flex items-center'>
                            <p className='font-roboto font-normal text-sm text-[#222222]'>Please check your payment details or contact your bank for further assistance</p>
                            <a href="#" className='text-[#06ab9f] font-bold text-sm border border-[#06ab9f] py-2 px-3 ml-5' 
                                // onClick={(e) => { 
                                // e.preventDefault(); 
                                // dispatch(resend_order_mail(orderid)); }}
                            >
                              <button>
                                  Try Again
                              </button>
                              
                            </a>
                          </div>
                    </div>
                  </div>
                  <div class="lg:col-span-2 sm:col-span-1">
                    <div className='border-solid border border-slate-300 px-7 py-10 mb-5'>
                          <p className='font-roboto font-medium text-xl text-[#222222] mb-6'>Customer Details</p>
                          <div className=' mb-6'>
                          <h4 className='font-roboto font-medium text-base text-[#222222]'>Email Address</h4>
                          <p className='font-roboto text-sm font-normal text-[#222222]'>georgevinoth09@gmail.com</p>
                          </div>
                          <div className='grid grid-cols-12'>
                            <div className='col-span-12 lg:col-span-6 lg:px-4 2xl:pr-10 2xl:pl-0 mb-4'>
                              <h5 className='font-roboto text-base font-medium text-[#222222] mb-2'>Billing Address</h5>
                              <p className='font-roboto text-sm font-normal text-[#222222] mb-1'>{cartAddress?.firstname} {cartAddress?.lastname}</p>
                              <p className='font-roboto text-sm font-normal text-[#222222] mb-1'>{cartAddress?.addressline1 || "NA"}</p>
                              <p className='font-roboto text-sm font-normal text-[#222222] mb-1'>{cartAddress?.city || "NA"} - {cartAddress?.pincode || "NA"}</p>
                              <p className='font-roboto text-sm font-normal text-[#222222]'>{cartAddress?.shoppingCartByCartId?.userProfileByUserprofileId?.email || "NA"}</p>
                            </div>
                            <div className='col-span-12 lg:col-span-6 lg:px-4 2xl:pr-10 2xl:pl-0 mt-[20px] sm:mt-0 mb-4'>
                              <h5 className='font-roboto text-base font-medium text-[#222222] mb-2'>Shipping address</h5>
                              <p className='font-roboto text-sm font-normal text-[#222222] mb-1'>{cartAddress?.firstname} {cartAddress?.lastname}</p>
                              <p className='font-roboto text-sm font-normal text-[#222222] mb-1'>{cartAddress?.addressline1 || "NA"}</p>
                              <p className='font-roboto text-sm font-normal text-[#222222] mb-1'>{cartAddress?.city || "NA"} - {cartAddress?.pincode || "NA"}</p>
                              <p className='font-roboto text-sm font-normal text-[#222222] mb-1'>{cartAddress?.shoppingCartByCartId?.userProfileByUserprofileId?.email || "NA"}</p>
                            </div>
                          </div>
                    </div>
                  </div>
                  <div class="lg:row-span-3 sm:row-span-1">
                    <div className='border-solid border border-slate-300 p-8 mb-5 lg:pt-10'>
                      <div className='flex items-center pb-4'>
                        <svg width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1127_161)">
                            <path d="M5.41634 15.8333H3.74967C3.51967 15.8333 3.33301 15.6467 3.33301 15.4167C3.33301 15.1867 3.51967 15 3.74967 15H5.41634C5.64634 15 5.83301 15.1867 5.83301 15.4167C5.83301 15.6467 5.64634 15.8333 5.41634 15.8333Z" fill="black" />
                            <path d="M18.9588 15.834H17.9171C17.6871 15.834 17.5005 15.6473 17.5005 15.4173C17.5005 15.1873 17.6871 15.0007 17.9171 15.0007H18.613L19.1746 12.0073C19.1671 10.4757 17.8588 9.16732 16.2505 9.16732H13.5146L12.1888 15.0007H14.5838C14.8138 15.0007 15.0005 15.1873 15.0005 15.4173C15.0005 15.6473 14.8138 15.834 14.5838 15.834H11.6671C11.5405 15.834 11.4205 15.7765 11.3413 15.6773C11.2621 15.579 11.2321 15.449 11.2605 15.3257L12.7755 8.65898C12.8188 8.46815 12.9871 8.33398 13.1821 8.33398H16.2505C18.318 8.33398 20.0005 10.0165 20.0005 12.084L19.368 15.494C19.3313 15.6915 19.1596 15.834 18.9588 15.834Z" fill="black" />
                            <path d="M16.2503 17.5007C15.102 17.5007 14.167 16.5665 14.167 15.4173C14.167 14.2682 15.102 13.334 16.2503 13.334C17.3987 13.334 18.3337 14.2682 18.3337 15.4173C18.3337 16.5665 17.3987 17.5007 16.2503 17.5007ZM16.2503 14.1673C15.5612 14.1673 15.0003 14.7282 15.0003 15.4173C15.0003 16.1065 15.5612 16.6673 16.2503 16.6673C16.9395 16.6673 17.5003 16.1065 17.5003 15.4173C17.5003 14.7282 16.9395 14.1673 16.2503 14.1673Z" fill="black" />
                            <path d="M7.08333 17.5007C5.935 17.5007 5 16.5665 5 15.4173C5 14.2682 5.935 13.334 7.08333 13.334C8.23167 13.334 9.16667 14.2682 9.16667 15.4173C9.16667 16.5665 8.23167 17.5007 7.08333 17.5007ZM7.08333 14.1673C6.39417 14.1673 5.83333 14.7282 5.83333 15.4173C5.83333 16.1065 6.39417 16.6673 7.08333 16.6673C7.7725 16.6673 8.33333 16.1065 8.33333 15.4173C8.33333 14.7282 7.7725 14.1673 7.08333 14.1673Z" fill="black" />
                            <path d="M5.41699 8.33333H2.08366C1.85366 8.33333 1.66699 8.14667 1.66699 7.91667C1.66699 7.68667 1.85366 7.5 2.08366 7.5H5.41699C5.64699 7.5 5.83366 7.68667 5.83366 7.91667C5.83366 8.14667 5.64699 8.33333 5.41699 8.33333Z" fill="black" />
                            <path d="M5.41634 10.8333H1.24967C1.01967 10.8333 0.833008 10.6467 0.833008 10.4167C0.833008 10.1867 1.01967 10 1.24967 10H5.41634C5.64634 10 5.83301 10.1867 5.83301 10.4167C5.83301 10.6467 5.64634 10.8333 5.41634 10.8333Z" fill="black" />
                            <path d="M5.41667 13.3333H0.416667C0.186667 13.3333 0 13.1467 0 12.9167C0 12.6867 0.186667 12.5 0.416667 12.5H5.41667C5.64667 12.5 5.83333 12.6867 5.83333 12.9167C5.83333 13.1467 5.64667 13.3333 5.41667 13.3333Z" fill="black" />
                            <path d="M11.6663 15.834H8.74967C8.51967 15.834 8.33301 15.6473 8.33301 15.4173C8.33301 15.1873 8.51967 15.0007 8.74967 15.0007H11.3338L13.2272 6.66732H3.74967C3.51967 6.66732 3.33301 6.48065 3.33301 6.25065C3.33301 6.02065 3.51967 5.83398 3.74967 5.83398H13.7497C13.8763 5.83398 13.9963 5.89148 14.0755 5.99065C14.1547 6.08898 14.1847 6.21898 14.1563 6.34232L12.073 15.509C12.0297 15.6998 11.8605 15.834 11.6663 15.834Z" fill="black" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1127_161">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      <p className='pl-3 font-roboto font-medium text-[#222222] text-sm underline underline-offset-2'>Ships By {shipDate}</p>
                      </div>
                      <p className='font-roboto font-normal text-[#222222] text-sm pb-4'>Shipping Method: [Standard / Express Shipping]</p>
                      <p className='font-roboto font-normal text-[#222222] text-sm'>Estimated Delivery Date: {deliveryDate}</p>
                      <hr className=" border-[#CFD4E5] my-6 rounded-lg" />
                      <p className='font-roboto font-medium text-[#222222] text-xl pb-3'>{productName}</p>
                      <p className='font-roboto font-normal text-[#222222] text-sm pb-4'>{productskuIds}</p>
                      <p className='font-roboto font-normal text-[#222222] text-sm'>Date: {orderDate}
                        {/* <span>{orderDateTime}</span> */}
                      </p>
                      <hr className='border-[#CFD4E5] my-6 rounded-lg' />
                      <div className='flex justify-between pb-4'>
                        <div>
                          <p className='font-roboto font-normal text-[#222222] text-sm pb-4'>Subtotal</p>
                          <p className='font-roboto font-normal text-[#222222] text-sm pb-4'>Discount</p>
                          <p className='font-roboto font-normal text-[#222222] text-sm pb-4'>Shipping Charges</p>
                          <p className='font-roboto font-normal text-[#222222] text-sm'>Payment Mode</p>
                        </div>
                        <div className='text-right'>
                          <p className='font-roboto font-normal text-[#222222] text-sm pb-4'>{formattedAmount}</p>
                          <p className='font-roboto font-normal text-[#222222] text-sm pb-4'>₹{discountshow}</p>
                          <p className='font-roboto font-bold text-[#32B205] text-sm pb-4'>{shippingCharge}</p>
                          <p className='font-roboto font-normal text-[#222222] text-sm'>{orderData.paymentMode}</p>
                        </div>
                      </div>
                      <div className="border border-[#CFD4E5] rounded-md cursor-pointer duration-100 p-3 text-center">
                        <Link className='text-center text-[#222222] text-base font-normal font-roboto'>Your Order Savings <span className="text-base font-roboto font-bold text-[#32B205]">₹{discountshow}</span></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-center mb-7'>
                  <h5 className='text-[#20B4A9] text-xs font-normal pb-3'>Thank you for shopping with us!</h5>
                  <p className='text-[#8F8F8F] text-xs font-normal'>We look forward to serving you again.</p>
                </div>
            </div>
          </>
        );
      } else {
        return (
          <div className="bg-yellow-500 text-white p-6">No data available for this order.</div>
        );
      }
}