// src/components/Footer.js
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Toast } from "../../src/components/Atoms/Toast";
import {
  subscribeUserWithMail,
  clearMessage,
} from "../redux/action/homeAction";
import constants from "../utilities/constants";
import { AccordionItem } from "./AccordionItem";

const Footer = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      Toast("Please enter a valid email address", "error");
      return;
    }

    // Dispatch the action with the email
    dispatch(subscribeUserWithMail(email));

    // Optional: Reset the input field after submission
    setEmail("");
  };
  const { mailsubscriptionResponseSuccess, mailsubscriptionResponseError } =
    useSelector((state) => state.homeReducer);
  console.log(
    mailsubscriptionResponseSuccess,
    "mailsubscriptionResponseSuccess",
    mailsubscriptionResponseError
  );
  useEffect(() => {
    // If there's a success or error message, set a timeout to clear it after 5 seconds
    if (mailsubscriptionResponseSuccess || mailsubscriptionResponseError) {
      const timeout = setTimeout(() => {
        dispatch(clearMessage());
      }, 5000);

      return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
    }
  }, [
    mailsubscriptionResponseSuccess,
    mailsubscriptionResponseError,
    dispatch,
  ]);

  return (
    <>
      <div className="relative">
        {/* Your existing footer content */}

        {/* Absolute WhatsApp Icon */}
        <a
          href="https://wa.me/919952625252?text=Hi"
          target="_blank"
          rel="noopener noreferrer"
          className="fixed bottom-24 right-5 z-50"
        >
          <img
            src={constants.DEFAULT_IMAGE + "/whatsapp-icon-trans.gif"}
            alt="whatsapp"
            className="w-24 h-24"
          />
        </a>
      </div>
      <div className="bg-[#06ab9f] text-white pt-10 pb-8 w-full border border-t-[#EFEBE8] border-t-[12px]">
        <div className="container mx-auto grid grid-cols-3">
          <div className="lg:grid lg:grid-cols-3 hidden w-full col-span-2 border-r border-[#3CCEC1]">
            <div className="col-span-1 sm:block">
              <ul>
                <li className="py-2 text-lg font-Cormorant">
                  <b>Shop by Categories</b>
                </li>
                <li className="py-2">
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      (window.location.href =
                        constants.ADDITIONAL_PATH + "/silver-jewellery")
                    }
                  >
                    Jewellery
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      (window.location.href =
                        constants.ADDITIONAL_PATH +
                        "/silver-cocktail-jewellery")
                    }
                  >
                    Style
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      (window.location.href =
                        constants.ADDITIONAL_PATH + "/silver-jewellery")
                    }
                  >
                    Collections
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      (window.location.href =
                        constants.ADDITIONAL_PATH +
                        "/silver-jewellery-finish+others")
                    }
                  >
                    Finish
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      (window.location.href =
                        constants.ADDITIONAL_PATH +
                        "/silver-jewellery-shipping+in+1+day")
                    }
                  >
                    Ready to Shop
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-span-1 sm:block">
              <ul>
                <li className="py-2 text-lg font-Cormorant">
                  <b>About us</b>
                </li>
                <li className="py-2">
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      (window.location.href =
                        "https://www.nacjewellers.com/aboutus")
                    }
                  >
                    NAC Jewellers
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      (window.location.href =
                        constants.ADDITIONAL_PATH + "/aboutus")
                    }
                  >
                    About Stylori
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      (window.location.href =
                        constants.ADDITIONAL_PATH + "/careers")
                    }
                  >
                    Careers
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      (window.location.href =
                        constants.ADDITIONAL_PATH + "/contactus")
                    }
                  >
                    Contact Us
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      (window.location.href =
                        constants.ADDITIONAL_PATH + "/account")
                    }
                  >
                    My Account
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-span-1 sm:block">
              <ul>
                <li className="py-2 text-lg font-Cormorant">
                  <b>Contact us</b>
                </li>
                <li className="py-2" target="_blank">
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      (window.location.href =
                        "https://wa.me/919952625252?text=Hi - ${window.location.href}")
                    }
                  >
                    Whatsapp: +91 99526 25252
                  </a>
                </li>
                <li className="py-2" target="_blank">
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      (window.location.href = "mailto: hello@stylori.com")
                    }
                  >
                    Email: hello@stylori.com
                  </a>
                </li>
                <li className="py-2" target="_blank">
                  <a
                    className="cursor-pointer"
                    onClick={() => (window.location.href = "tel:18001020330")}
                  >
                    Customer Care: 1800 102 0330
                  </a>
                </li>
                {/* <li className='py-2'>Seattle, WA</li> */}
              </ul>
            </div>
          </div>
          <div className="w-full lg:block hidden">
            <div class="px-8">
              <h2 className="text-[32px] font-Cormorant font-bold">
                You can be one step ahead
              </h2>
              <h6 className="text-xl pb-5 font-light">
                Sign up to hear about our updates on the dot.
              </h6>
              <form onSubmit={handleSubmit} class="newsletter-form relative">
                <input
                  type="email"
                  required
                  pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Your email address"
                  className="text-[#A59D9D] text-[16px] py-2 px-4 border-[#BEBEBE] w-3/4 focus:outline-none"
                />
                <button
                  className="bg-[#EFEBE8] text-[#222222] py-2 px-6 uppercase font-semibold text-[16px]"
                  type="submit"
                >
                  Sign Up
                </button>
              </form>

              {mailsubscriptionResponseSuccess && (
                <p className="text-white">{mailsubscriptionResponseSuccess}</p>
              )}
              {mailsubscriptionResponseError && (
                <p className="text-red-500">{mailsubscriptionResponseError}</p>
              )}

              <div className="flex justify-end pt-10 items-center">
                <Link
                  to="https://www.instagram.com/stylorisilver/?hl=en"
                  target="_blank"
                >
                  <img
                    src={constants.DEFAULT_IMAGE + "/instagram.svg"}
                    alt="Instagram"
                    className="px-3"
                  />
                </Link>
                <Link to="https://x.com/StyloriLove?mx=2" target="_blank">
                  <img
                    src={constants.DEFAULT_IMAGE + "/twitter.svg"}
                    alt="Twitter"
                    className="px-3"
                  />
                </Link>
                <Link to="https://www.facebook.com/stylori/" target="_blank">
                  <img
                    src={constants.DEFAULT_IMAGE + "/facebook.svg"}
                    alt="Facebook"
                    className="px-3"
                  />
                </Link>
                <Link to="https://www.youtube.com/@Stylori" target="_blank">
                  <img
                    src={constants.DEFAULT_IMAGE + "/youtube.svg"}
                    alt="Youtube"
                    className="px-3"
                  />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/stylori-silver/"
                  target="_blank"
                >
                  <img
                    src={constants.DEFAULT_IMAGE + "/linkedin.svg"}
                    alt="Linkedin"
                    className="pl-3"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:grid lg:grid-cols-6 hidden w-full col-span-full">
            <ul className="pt-10 col-span-1 sm:block">
              <li className="text-sm">© 2025 Stylori Silver</li>
            </ul>
            <ul className="pt-10 flex col-span-3 justify-center ml-8">
              <li
                className="pr-2 uppercase text-sm cursor-pointer"
                onClick={() =>
                  Navigate(constants.ADDITIONAL_PATH + "/privacyPolicy")
                }
              >
                PRIVACY POLICY
              </li>
              <li className="px-2 text-sm"> • </li>
              <li
                className="px-2 uppercase text-sm cursor-pointer"
                onClick={() =>
                  Navigate(constants.ADDITIONAL_PATH + "/termsconditions")
                }
              >
                {" "}
                TERMS OF USE{" "}
              </li>
              <li className="px-2 text-sm"> • </li>
              <li
                className="px-2 uppercase text-sm cursor-pointer"
                onClick={() => Navigate(constants.ADDITIONAL_PATH + "/sitemap")}
              >
                {" "}
                SITEMAP{" "}
              </li>
              <li className="px-2 text-sm"> • </li>
              <li
                className="px-2 uppercase text-sm cursor-pointer"
                onClick={() =>
                  Navigate(constants.ADDITIONAL_PATH + "/deliveryreturns")
                }
              >
                {" "}
                Shipping & Return{" "}
              </li>
              <li className="px-2 text-sm"> • </li>
              <li
                className="pl-2 uppercase text-sm cursor-pointer"
                onClick={() => Navigate(constants.ADDITIONAL_PATH + "/faqs")}
              >
                {" "}
                FAQs
              </li>
              <li className="px-2 text-sm"> • </li>
              <li
                className="pl-2 uppercase text-sm cursor-pointer"
                onClick={() =>
                  Navigate(constants.ADDITIONAL_PATH + "/productcare")
                }
              >
                {" "}
                Product Care
              </li>
            </ul>
            <div className="lg:flex items-center col-span-2 mt-5 justify-end hidden">
              <img
                src={constants.DEFAULT_IMAGE + "/visa.png"}
                alt="Visa Card"
                className="px-3"
              />
              <img
                src={constants.DEFAULT_IMAGE + "/master.png"}
                alt="Master Card"
                className="px-3"
              />
              <img
                src={constants.DEFAULT_IMAGE + "/american.png"}
                alt="American Express"
                className="px-3"
              />
              <img
                src={constants.DEFAULT_IMAGE + "/diners.png"}
                alt="Diners Club"
                className="h-[34px] px-3"
              />
              <img
                src={constants.DEFAULT_IMAGE + "/netbanking.png"}
                alt="Netbanking"
                className="h-[34px] px-3"
              />
            </div>
          </div>
        </div>

        {/* For Mobile */}
        <div className="lg:hidden px-4">
          <div className="border-b border-b-gray-200">
            <AccordionItem header="Shop by Categories">
              <ul>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      constants.ADDITIONAL_PATH + "/silver-jewellery")
                  }
                >
                  Jewellery
                </li>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      constants.ADDITIONAL_PATH + "/silver-cocktail-jewellery")
                  }
                >
                  Style
                </li>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      constants.ADDITIONAL_PATH + "/silver-jewellery")
                  }
                >
                  Collections
                </li>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      constants.ADDITIONAL_PATH +
                      "/silver-jewellery-finish+others")
                  }
                >
                  Finish
                </li>
                <li
                  className="mb-4 cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      constants.ADDITIONAL_PATH +
                      "/silver-jewellery-shipping+in+1+day")
                  }
                >
                  Ready to Shop
                </li>
              </ul>
            </AccordionItem>

            <AccordionItem header="About Us">
              <ul>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      "https://www.nacjewellers.com/aboutus")
                  }
                >
                  NAC Jewellers
                </li>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      constants.ADDITIONAL_PATH + "/aboutus")
                  }
                >
                  About Stylori
                </li>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      constants.ADDITIONAL_PATH + "/careers")
                  }
                >
                  Careers
                </li>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      constants.ADDITIONAL_PATH + "/contactus")
                  }
                >
                  Contact Us
                </li>
                <li
                  className="mb-4 cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      constants.ADDITIONAL_PATH + "/account")
                  }
                >
                  My Account
                </li>
              </ul>
            </AccordionItem>

            <AccordionItem header="Contact Us">
              <ul>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      "https://wa.me/919952625252?text=Hi - ${window.location.href}")
                  }
                  target="_blank"
                >
                  Whatsapp:- +91 99526 25252
                </li>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() =>
                    (window.location.href = "mailto: hello@stylori.com")
                  }
                  target="_blank"
                >
                  Email:- hello@stylori.com
                </li>
                <li
                  className="mb-4 cursor-pointer"
                  onClick={() => (window.location.href = "tel:18001020330")}
                  target="_blank"
                >
                  Customer Care:- 1800 102 0330
                </li>
              </ul>
            </AccordionItem>
            <AccordionItem header="Subscribe and Save">
              <div className="mb-5">
                <h2 className="text-sm font-century">
                  You can be one step ahead
                </h2>
                <h6 className="text-sm font-century pb-5">
                  Sign up to hear about our updates on the dot.
                </h6>
                <form onSubmit={handleSubmit} className="flex">
                  <input
                    type="email"
                    required
                    pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Your email address"
                    className="text-[#A59D9D] text-[16px] py-2 px-4 border-[#BEBEBE] w-3/4 focus:outline-none"
                  />
                  <button
                    className="bg-[#EFEBE8] text-[#222222] py-3 px-4 uppercase font-semibold text-[16px]"
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    Sign Up
                  </button>
                </form>
                {mailsubscriptionResponseSuccess && (
                  <p className="text-green-500">
                    {mailsubscriptionResponseSuccess}
                  </p>
                )}
                {mailsubscriptionResponseError && (
                  <p className="text-red-500">
                    {mailsubscriptionResponseError}
                  </p>
                )}
              </div>
            </AccordionItem>
          </div>
        </div>

        <div className="text-center mt-7 lg:hidden">
          <h3 className="font-century font-semibold text-base text-white">
            Follow us on
          </h3>
          <div className="flex justify-center pt-3 items-center">
            <Link
              to="https://www.instagram.com/stylorisilver/?hl=en"
              target="_blank"
            >
              <img
                src={constants.DEFAULT_IMAGE + "/instagram.svg"}
                alt="Instagram"
                className="px-3 cursor-pointer"
              />
            </Link>
            <Link to="https://x.com/StyloriLove?mx=2" target="_blank">
              <img
                src={constants.DEFAULT_IMAGE + "/twitter.svg"}
                alt="Twitter"
                className="px-3 cursor-pointer"
              />
            </Link>
            <Link to="https://www.facebook.com/stylori/" target="_blank">
              <img
                src={constants.DEFAULT_IMAGE + "/facebook.svg"}
                alt="Facebook"
                className="px-3 cursor-pointer"
              />
            </Link>
            <Link to="https://www.youtube.com/@Stylori" target="_blank">
              <img
                src={constants.DEFAULT_IMAGE + "/youtube.svg"}
                alt="Youtube"
                className="px-3 cursor-pointer"
              />
            </Link>
            <Link
              to="https://www.linkedin.com/company/stylori-silver/"
              target="_blank"
            >
              <img
                src={constants.DEFAULT_IMAGE + "/linkedin.svg"}
                alt="Linkedin"
                className="pl-3 cursor-pointer"
              />
            </Link>
          </div>

          <div className="grid grid-cols-3 lg:p-12 p-4 gap-4">
            <p
              className="text-sm font-century font-medium cursor-pointer col-span-1"
              onClick={() =>
                Navigate(constants.ADDITIONAL_PATH + "/privacyPolicy")
              }
            >
              {" "}
              Privacy policy{" "}
            </p>
            <p
              className="text-sm font-century font-medium cursor-pointer col-span-1"
              onClick={() =>
                Navigate(constants.ADDITIONAL_PATH + "/termsconditions")
              }
            >
              {" "}
              Terms of use{" "}
            </p>
            <p
              className="text-sm font-century font-medium cursor-pointer col-span-1"
              onClick={() => Navigate(constants.ADDITIONAL_PATH + "/sitemap")}
            >
              {" "}
              Site Map{" "}
            </p>
            <p
              className="text-sm font-century font-medium cursor-pointer col-span-1"
              onClick={() =>
                Navigate(constants.ADDITIONAL_PATH + "/deliveryreturns")
              }
            >
              {" "}
              Shipping & Return{" "}
            </p>
            <p
              className="text-sm font-century font-medium cursor-pointer col-span-1"
              onClick={() => Navigate(constants.ADDITIONAL_PATH + "/faqs")}
            >
              {" "}
              FAQs{" "}
            </p>
            <p
              className="text-sm font-century font-medium cursor-pointer col-span-1"
              onClick={() =>
                Navigate(constants.ADDITIONAL_PATH + "/productcare")
              }
            >
              {" "}
              Product Care{" "}
            </p>
          </div>
          <div className="flex items-center col-span-2 mt-5 justify-center lg:hidden lg:pb-0 pb-4">
            <img
              src={constants.DEFAULT_IMAGE + "/visa.png"}
              alt="Visa Card"
              className="px-1"
            />
            <img
              src={constants.DEFAULT_IMAGE + "/master.png"}
              alt="Master Card"
              className="px-1"
            />
            <img
              src={constants.DEFAULT_IMAGE + "/american.png"}
              alt="American Express"
              className="px-1"
            />
            <img
              src={constants.DEFAULT_IMAGE + "/diners.png"}
              alt="Diners Club"
              className="h-[34px] px-1"
            />
            <img
              src={constants.DEFAULT_IMAGE + "/netbanking.png"}
              alt="Netbanking"
              className="h-[34px] px-1"
            />
          </div>
          <div className="">
            <p className="font-century text-xs font-medium">
              © 2025 Stylori Silver. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Footer);
