import React from "react";
import { useNavigate } from "react-router-dom";
import constants from "../utilities/constants";
const Page404 = () => {
  const navigation = useNavigate();
  return (
    <main className="mb-5 h-full">
      <section>
        <div className="flex  flex-col  justify-center items-center">
          <img src={constants.DEFAULT_IMAGE + "/404pageimage.png"} className="w-[36%] h-1/2" alt="Img404" />
          <button
            onClick={() => navigation(constants.ADDITIONAL_PATH)}
            className="text-white py-2 px-5 bg-customGreen"
          >
            Back to Home
          </button>
        </div>
      </section>
    </main>
  );
};

export default Page404;
