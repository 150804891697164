import { allcdn } from "../../utilities/api";
import constants from "../../utilities/constants";
import { allCdnApiData } from "../../utilities/helper";
import { ACTION_TYPE } from "./type";

export const siteMapData = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.SITE_MAP_DATA_REQUEST });
  try {
    const data = await allcdn();
    const filterdData = allCdnApiData(
      data?.data?.data?.allCdns?.nodes,
      constants.SilverSitemap
    );

    dispatch({
      type: ACTION_TYPE.SITE_MAP_DATA_REQUEST_SUCCESS,
      payload: {
        siteMapDataSuccess: filterdData,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.SITE_MAP_DATA_REQUEST_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const careerPageData = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.CARREER_PAGE_DATA_REQUEST });
  try {
    const data = await allcdn();
    // console.log("careerPageData12", data);

    const filterdData = allCdnApiData(
      data?.data?.data?.allCdns?.nodes,
      constants.Careers
    );

    dispatch({
      type: ACTION_TYPE.CARREER_PAGE_DATA_REQUEST_SUCCESS,
      payload: {
        careerPageDataSuccess: filterdData,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.CARREER_PAGE_DATA_REQUEST_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
