import React from "react";
import constants from "../../utilities/constants";

import { toast } from "react-toastify";
export const Toast = (message = "toastify message", type = "success") => {
  if (type === "success") {
    return toast.success(
      <div className={`flex items-center gap-2 `}>
        <span className="text-sm font-medium font-century text-[#56AD6A]">
          {message}
        </span>
      </div>
    );
  } else if (type === "error") {
    return toast.error(
      <div className={`flex items-center gap-2`}>
        <span className="text-sm font-medium font-century text-[#7E0000]">
          {message}
        </span>
      </div>,
      {
        icon: <img src={constants.DEFAULT_IMAGE + "/error_info.png"} alt="error icon" />,
      }
    );
  } else {
    return toast.success(
      <div className={`flex items-center gap-2 `}>
        <span className="text-sm font-medium font-century text-[#56AD6A]">
          {message}
        </span>
      </div>
    );
  }

  // className={`text-[${textcolour}]`}
  //   return toast.error(
  //     <div className={`flex items-center gap-2 `}>
  //       {/* {Icon == null ? (
  //         <FaCheckCircle size={16} color={iconcolor} />
  //       ) : (
  //         <Icon size={16} color={iconcolor} />
  //       )} */}
  //       <span>{message}</span>
  //     </div>,
  //     {
  //       style: {
  //         width: "100%",
  //         backgroundColor: bgcolor,
  //         color: textcolour,
  //       },
  //       //   hideProgressBar: true,
  //       theme: "light",
  //       //   transition: "",
  //       //   progressStyle: {
  //       //     backgroundColor: bgcolor,
  //       //     color: bgcolor,
  //       //   },
  //     }
  //   );
};
