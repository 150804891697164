import React, { useEffect, useState } from "react";
import { siteMapData } from "../redux/action/siteMapAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import constants from "../utilities/constants";

function SiteMap() {
  const dispatch = useDispatch();
  const { siteMapDataLoading, siteMapDataSuccess, siteMapDataError } =
    useSelector((state) => state.siteMapReducer);
  const [siteMapDatas, setSiteMapDatas] = useState(null);
  const navigation = useNavigate();
  useEffect(() => {
    dispatch(siteMapData());
  }, []);
  useEffect(() => {
    if (Array.isArray(siteMapDataSuccess)) {
      const data = JSON.parse(siteMapDataSuccess[0]?.data);
      console.log("siteMapDataSuccess", data[0].props);
      if (data[0]?.props) {
        const sortedData = [...data[0].props].sort(
          (a, b) => a.categoryList.length - b.categoryList.length
        );
        setSiteMapDatas(sortedData);
      }
      // setSiteMapDatas(data[0]?.props);
      // ,
      // siteMapDataSuccess[0]?.data
    }
  }, [siteMapDataSuccess]);
  console.log("siteMapDataslog", siteMapDatas);

  return (
    <main className="min-h-full">
      <section className="flex flex-col  lg:mx-auto lg:container">
        <div className="flex flex-col justify-center items-center">
          <spna className="font-Cormorant  font-bold text-[40px]">
            Site Index
          </spna>
        </div>
        <div>
          <div className="flex flex-row grow-0 max-lg:justify-center max-lg:items-center flex-wrap pt-3 gap-4">
            {siteMapDatas !== null &&
              siteMapDatas.slice(0, 4).map((item) => (
                <div className="row-start-2 ">
                  <div className="rounded-t-[20px] bg-gradient-to-l h-14 from-[#CEFFFB] via-[#D8FFEB] to-[#FFFFFF]  shadow-[0px_2px_20.8px_0px_rgba(0,0,0,0.15)] w-[355px]  flex flex-col  justify-center items-center   text-[#262626] font-Cormorant font-bold   ">
                    <span className="text-[20px] ">
                      {item?.categoryHeading}
                    </span>
                  </div>
                  <div className="flex flex-col lg:h-[500px] py-5 space-y-2  font-century   items-center font-medium w-[355px]   shadow-[0px_2px_20.8px_0px_rgba(0,0,0,0.08)]">
                    {Array.isArray(item?.categoryList) &&
                      item?.categoryList.map((subitem) => (
                        <span
                          className="text-[16px] cursor-pointer hover:text-customGreen"
                          onClick={() => {
                            const urlPath = new URL(subitem?.url).pathname;
                            navigation(constants.ADDITIONAL_PATH+`${urlPath}`);
                          }}
                        >
                          {subitem?.name}
                        </span>
                      ))}
                  </div>
                </div>
              ))}
          </div>
          <div className="flex flex-row grow-0 max-lg:justify-center max-lg:items-center  flex-wrap pt-4 gap-4">
            {siteMapDatas !== null &&
              siteMapDatas.slice(4, 8).map((item) => (
                <div className="row-start-2 ">
                  <div className="rounded-t-[20px] bg-gradient-to-l h-14 from-[#CEFFFB] via-[#D8FFEB] to-[#FFFFFF]  shadow-[0px_2px_20.8px_0px_rgba(0,0,0,0.15)] w-[355px]  flex flex-col  justify-center items-center   text-[#262626] font-Cormorant font-bold   ">
                    <span className="text-[20px] ">
                      {item?.categoryHeading}
                    </span>
                  </div>
                  <div className="flex flex-col h-auto mb-2  py-5 space-y-2  font-century   items-center font-medium w-[355px]   shadow-[0px_2px_20.8px_0px_rgba(0,0,0,0.08)]">
                    {Array.isArray(item?.categoryList) &&
                      item?.categoryList.map((subitem) => (
                        <span
                          className="text-[16px] cursor-pointer hover:text-customGreen"
                          onClick={() => {
                            const urlPath = new URL(subitem?.url).pathname;
                            navigation(constants.ADDITIONAL_PATH+`${urlPath}`);
                          }}
                        >
                          {subitem?.name}
                        </span>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </main>
  );
}

export default SiteMap;
