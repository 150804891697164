// Asset and API base paths
const ASSETS_PATH = "https://assets.stylori.com";
const BASE_API_URL = "https://api-staging-new.stylori.com";
const GRAPHQL_API_URL = `${BASE_API_URL}/graphql`;

// Default Image Path
const DEFAULT_IMAGE_PATH = `${ASSETS_PATH}/assets/images/default_image.jpg`;
const DOMAIN_URLS = [
  "stylori.com",
  "https://stylori.com",
  "https://staging-new.stylori.com",
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SITE_PATHS: DOMAIN_URLS,
  DEFAULT_NO_PRODUCT_IMAGE: DEFAULT_IMAGE_PATH,
  DEFAULT_IMAGE: `${ASSETS_PATH}/assets/images`,
  ADDITIONAL_PATH: "/styloriSilver",
  // api_url:'http://localhost:8002',
  // api_url_graphql:'http://localhost:8002/graphql',
  // api_url:'http://localhost',
  // api_url_graphql:'http://localhost/graphql',
  image_url: ASSETS_PATH + "/",
  // for staging
  api_url: BASE_API_URL,
  api_url_graphql: GRAPHQL_API_URL,
  // for localhost
  // api_url:'http://192.168.1.215:8002',
  // api_url_graphql:'http://192.168.1.215:8002/graphql',
  shipping_days: 2, // shipping days intervals...
  HomePageBanner: "HomePageBanner",
  StyloriCard: "StyloriCard",
  StyloriTitle: "StyloriTitle",
  BestSellers: "BESTSELLERS",
  StyloriCardPrice: "StyloriCardPrice",
  ShopbyFinish: "SHOPBYFINISH",
  testimonial: "TestimonialSlider",
  BlogPageCard: "blogPageCard",
  PersonalInformation: "Personal Information",
  ManageAddress: "Manage Address",
  Wishlist: "Wishlist",
  AllOrders: "All Orders",
  RedeemPoints: "Redeem Points",
  SingleOrder: "View Orders",
  ShoppingBag: "Shopping Bag",
  Logout: "Logout",
  MobileNumber: "Mobile Number",
  EmailAddress: "Email Address",
  Password: "Password",
  AddressEdit: "AddressEdit",
  AddressDelete: "AddressDelete",
  Confirmation: "Confirmation",
  RemoveWishlist: "Remove Wishlist",
  imageCurrency:
    "https://raw.githubusercontent.com/madebybowtie/FlagKit/master/Assets/PNG/",
  Signin: "signin",
  SigninOtp: "signinotp",
  Register: "register",
  Login: "login",
  Reset: "reset",
  Resetpassword: "resetpassword",
  Subscribe: "subscribe",
  Forgot: "forgot",
  SilverSitemap: "silver-sitemap",
  Careers: "careers",
  Account_Wishlist: "account-wishlist",
  Account_Profile: "account-profile",
  FaqTitle: "faqTitle",
  FaqComponents: "faqComponents",
  BestSeller: "best seller",
  Account_Addresses: "account-addresses",
  Account_Allorders: "account-allorders",
  Account_Redeempoints: "account-redeempoints",
};
