import { useState } from "react";
import constants from "../utilities/constants";

export const AccordionItem = ({ header, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 last:border-b-transparent">
      <button
        className="flex justify-between items-center w-full py-4 text-left text-lg text-white font-Cormorant font-bold focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        {header}
        <span className="text-xl">
          {isOpen ? (
            <img src={constants.DEFAULT_IMAGE + "/plus.svg"} />
          ) : (
            <img src={constants.DEFAULT_IMAGE + "/minus.svg"} />
          )}
        </span>
      </button>
      {isOpen && (
        <div className="px-4 text-white font-century font-medium text-sm">
          {children}
        </div>
      )}
    </div>
  );
};
