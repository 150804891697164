import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyPolicy } from "../redux/action/homeAction";
import { parseData } from "../utilities/helper";
import constants from "../utilities/constants";

function PrivacyPolicy() {
  const dispatch = useDispatch();
  const {
    privacyPolicyDataResponseLoading,
    privacyPolicyDataResponseSuccess,
    privacyPolicyDataResponseError,
  } = useSelector((state) => state.homeReducer);
  useEffect(() => {
    dispatch(getPrivacyPolicy());
  }, []);

  const Accordion = ({ children }) => {
    return <div className="md:w-1/2">{children}</div>;
  };

  const AccordionItem = ({ header, children }) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
      <div className="border-b mb-2 w-full pb-5">
        <button
          className=" flex flex-row justify-between  lg:items-center max-lg:px-4  w-full"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className=" text-black ">{header}</div>
          <span className="text-xl">
            {isOpen ? (
              <img
                src={constants.DEFAULT_IMAGE + "/accortian_close.png"}
                alt="close icon"
                className="w-7 h-7 max-lg:ms-2"
              />
            ) : (
              <img alt="plus icon" className="w-5 h-5 max-lg:ms-2" src={constants.DEFAULT_IMAGE + "/accortian_open.png"} />
            )}
          </span>
        </button>
        {isOpen && (
          <div className="px-4  font-century font-medium text-sm">
            {children}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {privacyPolicyDataResponseSuccess &&
      privacyPolicyDataResponseSuccess[0].isActive ? (
        <main>
          {privacyPolicyDataResponseSuccess &&
            parseData(privacyPolicyDataResponseSuccess[0]?.data).map(
              (item, index) => (
                <div>
                  {item.component === constants.HomePageBanner && (
                    <section>
                      <img
                        className="w-full md:block hidden"
                        src={item?.props?.banners[0]?.web}
                        alt="FaqBanner"
                      />
                      <img
                        className="w-full block md:hidden"
                        src={item?.props?.banners[0]?.mobile}
                        alt="FaqBanner"
                      />
                    </section>
                  )}
                  {item.component === constants.FaqTitle && (
                    <section className="pt-12 pb-12 text-black flex flex-col items-center p-2">
                      <span className="font-Cormorant font-bold text-3xl ">
                        {item?.props?.title_Text}
                      </span>
                      <span className="font-century font-medium text-lg">
                        {item?.props?.sub_Text}
                      </span>
                    </section>
                  )}
                  {item.component === constants.FaqComponents.slice(0, -1) && (
                    <section>
                      <div
                        key={index}
                        className="flex flex-col justify-center items-center"
                      >
                        {item?.props.map((propsitem, propsindex) => (
                          <>
                            <Accordion>
                              <AccordionItem header={propsitem?.role}>
                                <div
                                  className="custom-content  text-[#64636B] "
                                  dangerouslySetInnerHTML={{
                                    __html: propsitem?.JobDescription,
                                  }}
                                />
                                
                              </AccordionItem>
                            </Accordion>
                          </>
                        ))}
                        
                      </div>
                    </section>
                  )}
                </div>
              )
            )}
        </main>
      ) : privacyPolicyDataResponseLoading ? (
        <div className="text-center py-6 flex flex-col items-center">
          {/* SVG Image from Path */}
          <img src={constants.DEFAULT_IMAGE + "/loaderimagesilver.gif"} alt="loading logo" className="w-120 h-120" />
        </div>
      ) : (
        <section>
          <div className="text-center py-6 flex flex-col items-center">
            {/* SVG Image from Path */}
            <img
              src={constants.DEFAULT_IMAGE + "/no-product.svg"}
              alt="Sorry, No Product Found"
              className="w-120 h-120"
            />
            <p className="text-xl font-semibold text-[#262626]">
              Sorry, No data Found
            </p>
          </div>
        </section>
      )}
    </>
  );
}

export default PrivacyPolicy;
