//Network Constants

export const ACTION_TYPE = {
  GET_HOME_DATA_REQUEST: "GET_HOME_DATA_REQUEST",
  GET_HOME_DATA_RESPONSE_SUCCESS: "GET_HOME_DATA_RESPONSE_SUCCESS",
  GET_HOME_DATA_RESPONSE_FAILURE: "GET_HOME_DATA_RESPONSE_FAILURE",
  //mail subscription
  MAIL_SUBSCRIPTION_REQUEST: "MAIL_SUBSCRIPTION_REQUEST",
  MAIL_SUBSCRIPTION_RESPONSE_SUCCESS: "MAIL_SUBSCRIPTION_RESPONSE_SUCCESS",
  MAIL_SUBSCRIPTION_RESPONSE_SUCCESS_CLEAR:
    "MAIL_SUBSCRIPTION_RESPONSE_SUCCESS_CLEAR",
  MAIL_SUBSCRIPTION_RESPONSE_FAILURE: "MAIL_SUBSCRIPTION_RESPONSE_FAILURE",
  // home banner type
  GET_HOME_MAIN_BANNER_DATA_REQUEST: "GET_HOME_MAIN_BANNER_DATA_REQUEST",
  GET_HOME_MAIN_BANNER_DATA_RESPONSE_SUCCESS:
    "GET_HOME_MAIN_BANNER_DATA_RESPONSE_SUCCESS",
  GET_HOME_MAIN_BANNER_DATA_RESPONSE_FAILURE:
    "GET_HOME_MAIN_BANNER_DATA_RESPONSE_FAILURE",

  // home collection banner type
  GET_HOME_SUB_BANNER_DATA_REQUEST: "GET_HOME_SUB_BANNER_DATA_REQUEST",
  GET_HOME_SUB_BANNER_DATA_RESPONSE_SUCCESS:
    "GET_HOME_SUB_BANNER_DATA_RESPONSE_SUCCESS",
  GET_HOME_SUB_BANNER_DATA_RESPONSE_FAILURE:
    "GET_HOME_SUB_BANNER_DATA_RESPONSE_FAILURE",
  //best seller type
  GET_BESTSELLER_DATA_REQUEST: "GET_BESTSELLER_DATA_REQUEST",
  GET_BESTSELLER_DATA_RESPONSE_SUCCESS: "GET_BESTSELLER_DATA_RESPONSE_SUCCESS",
  GET_BESTSELLER_DATA_RESPONSE_FAILURE: "GET_BESTSELLER_DATA_RESPONSE_FAILURE",
  //shopbyfinish collection type
  GET_SHOPBYFINISH_COLLECTION_DATA_REQUEST:
    "GET_SHOPBYFINISH_COLLECTION_DATA_REQUEST",
  GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_SUCCESS:
    "GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_SUCCESS",
  GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_FAILURE:
    "GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_FAILURE",
  //read our blogs type
  GET_READ_OUR_BLOGS_DATA_REQUEST: "GET_READ_OUR_BLOGS_DATA_REQUEST",
  GET_READ_OUR_BLOGS_DATA_RESPONSE_SUCCESS:
    "GET_READ_OUR_BLOGS_DATA_RESPONSE_SUCCESS",
  GET_READ_OUR_BLOGS_DATA_RESPONSE_FAILURE:
    "GET_READ_OUR_BLOGS_DATA_RESPONSE_FAILURE",
  //home page video data  type
  GET_VIDEO_DATA_REQUEST: "GET_VIDEO_DATA_REQUEST",
  GET_VIDEO_DATA_RESPONSE_SUCCESS: "GET_VIDEO_DATA_RESPONSE_SUCCESS",
  GET_VIDEO_DATA_RESPONSE_FAILURE: "GET_VIDEO_DATA_RESPONSE_FAILURE",
  //home page product count data type
  GET_PRODUCT_COUNT_CARD_DATA_REQUEST: "GET_PRODUCT_COUNT_CARD_DATA_REQUEST",
  GET_PRODUCT_COUNT_CARD_DATA_SUCCESS: "GET_PRODUCT_COUNT_CARD_DATA_SUCCESS",
  GET_PRODUCT_COUNT_CARD_DATA_FAILURE: "GET_PRODUCT_COUNT_CARD_DATA_FAILURE",
  //header type
  GET_HEADER_DATA_REQUEST: "GET_HEADER_DATA_REQUEST",
  GET_HEADER_DATA_REQUEST_SUCCESS: "GET_HEADER_DATA_REQUEST_SUCCESS",
  GET_HEADER_DATA_REQUEST_FAILURE: "GET_HEADER_DATA_REQUEST_FAILURE",

  GET_PRODUCT_DATA_REQUEST: "GET_PRODUCT_DATA_REQUEST",
  GET_PRODUCT_DATA_RESPONSE_SUCCESS: "GET_PRODUCT_DATA_RESPONSE_SUCCESS",
  GET_PRODUCT_DATA_RESPONSE_FAILURE: "GET_PRODUCT_DATA_RESPONSE_FAILURE",

  GET_PRODUCT_FILTER_LIST_DATA_REQUEST: "GET_PRODUCT_FILTER_LIST_DATA_REQUEST",
  GET_PRODUCT_FILTER_LIST_DATA_SUCCESS: "GET_PRODUCT_FILTER_LIST_DATA_SUCCESS",
  GET_PRODUCT_FILTER_LIST_DATA_FAILURE: "GET_PRODUCT_FILTER_LIST_DATA__FAILURE",
  //to get products in product list
  GET_PRODUCT_LIST_DATA_REQUEST: "GET_PRODUCT_LIST_DATA_REQUEST",
  GET_PRODUCT_LIST_DATA_SUCCESS: "GET_PRODUCT_LIST_DATA_SUCCESS",
  GET_PRODUCT_LIST_DATA_LOADING_FALSE: "GET_PRODUCT_LIST_DATA_LOADING_FALSE",

  GET_PRODUCT_LIST_DATA_FAILURE: "GET_PRODUCT_LIST_DATA_FAILURE",
  //Get SEO Data
  GET_SEO_DATA_REQUEST: "GET_SEO_DATA_REQUEST",
  GET_SEO_DATA_SUCCESS: "GET_SEO_DATA_SUCCESS",
  GET_SEO_DATA_FAILURE: "GET_SEO_DATA_FAILURE",
  //voucher
  VOUCHER_REQUEST:"VOUCHER_REQUEST",
  VOUCHER_SUCCESS:"VOUCHER_SUCCESS",
  VOUCHER_FAILURE:"VOUCHER_FAILURE",
  CLEAR_VOUCHER_SUCCESS:"CLEAR_VOUCHER_SUCCESS",

  //for home notification
  GET_HOME_NOTIFICATION_REQUEST: "GET_HOME_NOTIFICATION_REQUEST",
  GET_HOME_NOTIFICATION_RESPONSE_SUCCESS:
    "GET_HOME_NOTIFICATION_RESPONSE_SUCCESS",
  GET_HOME_NOTIFICATION_RESPONSE_FAILURE:
    "GET_HOME_NOTIFICATION_RESPONSE_FAILURE",

  GET_CART_DATA_REQUEST: "GET_CART_DATA_REQUEST",
  GET_CART_DATA_RESPONSE_SUCCESS: "GET_CART_DATA_RESPONSE_SUCCESS",
  GET_CART_DATA_RESPONSE_FAILURE: "GET_CART_DATA_RESPONSE_FAILURE",
  CART_DATA_RESPONSE_CLEAR:"CART_DATA_RESPONSE_CLEAR",

  //kirthi
  //ordersuccess...
  GET_ORDERSUCCESS_DATA_REQUEST: "GET_ORDERSUCCESS_DATA_REQUEST",
  GET_ORDERSUCCESS_DATA_RESPONSE_SUCCESS:
    "GET_ORDERSUCCESS_DATA_RESPONSE_SUCCESS",
  GET_ORDERSUCCESS_DATA_RESPONSE_FAILURE:
    "GET_ORDERSUCCESS_DATA_RESPONSE_FAILURE",
  //orderfailed...
  GET_ORDERFAILED_DATA_REQUEST: "GET_ORDERFAILED_DATA_REQUEST",
  GET_ORDERFAILED_DATA_RESPONSE_SUCCESS:
    "GET_ORDERFAILED_DATA_RESPONSE_SUCCESS",
  GET_ORDERFAILED_DATA_RESPONSE_FAILURE:
    "GET_ORDERFAILED_DATA_RESPONSE_FAILURE",
  //resendordermail..
  RESENDORDERMAIL_REQUEST: "RESENDORDERMAIL_REQUEST",
  RESENDORDERMAIL_SUCCESS: "RESENDORDERMAIL_SUCCESS",
  RESENDORDERMAIL_FAIL: "RESENDORDERMAIL_FAIL",
  //search menu
  SEARCHMENU_REQUEST: "SEARCHMENU_REQUEST",
  SEARCHMENU_SUCCESS: "SEARCHMENU_SUCCESS",
  SEARCHMENU_FAIL: "SEARCHMENU_FAIL",
  UPDATECART_ID: "UPDATECART_ID",
  //daily metal price
  DAILYMETALANDCURRENCY_REQUEST: "DAILYMETALANDCURRENCY_REQUEST",
  DAILYMETALANDCURRENCY_SUCCESS: "DAILYMETALANDCURRENCY_SUCCESS",
  DAILYMETALANDCURRENCY_FAIL: "DAILYMETALANDCURRENCY_FAIL",
  //selected country
  SELECTEDCOUNTRY_REQUEST: "SELECTEDCOUNTRY_REQUEST",
  SELECTEDCOUNTRY_SUCCESS: "SELECTEDCOUNTRY_SUCCESS",
  SELECTEDCOUNTRY_FAIL: "SELECTEDCOUNTRY_FAIL",
  //contact us...
  CONTACTUSFORM_REQUEST: "CONTACTUSFORM_REQUEST",
  CONTACTUSFORM_SUCCESS: "CONTACTUSFORM_SUCCESS",
  CONTACTUSFORM_FAIL: "CONTACTUSFORM_FAIL",
  CONTACTUSFORM_RESET: "CONTACTUSFORM_RESET",
  //login....
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_MODAL: "LOGIN_MODAL",
  LOGIN_MODAL_TYPE: "LOGIN_MODAL_TYPE",
  CLEAR_LOGIN_RESPONSE: "CLEAR_LOGIN_RESPONSE",
  LOGOUT: "LOGOUT",
  //cart_count...
  CART_COUNT_REQUEST: "CART_COUNT_REQUEST",
  CLEAR_CART_COUNT:"CLEAR_CART_COUNT",
  CART_COUNT_SUCCESS: "CART_COUNT_SUCCESS",
  CART_COUNT_FAILURE: "CART_COUNT_FAILURE",
  REMOVE_CART_ITEM_SUCCESS: "REMOVE_CART_ITEM_SUCCESS",
  REMOVE_CART_ITEM_SUCCESS_WITHOUT_LOGIN:
    "REMOVE_CART_ITEM_SUCCESS_WITHOUT_LOGIN",
  CART_COUNT_WITHOUT_LOGIN: "CART_COUNT_WITHOUT_LOGIN",

  UPDATE_CART_LATEST_PRICE_REQUEST: "UPDATE_CART_LATEST_PRICE_REQUEST",
  UPDATE_CART_LATEST_PRICE_SUCCESS: "UPDATE_CART_LATEST_PRICE_SUCCESS",
  UPDATE_CART_LATEST_PRICE_FAILURE: "UPDATE_CART_LATEST_PRICE_FAILURE",
  GET_TESTIMONIAL_DATA_REQUEST: "GET_TESTIMONIAL_DATA_REQUEST",
  GET_TESTIMONIAL_DATA_RESPONSE_SUCCESS:
    "GET_TESTIMONIAL_DATA_RESPONSE_SUCCESS",
  GET_TESTIMONIAL_DATA_RESPONSE_FAILURE:
    "GET_TESTIMONIAL_DATA_RESPONSE_FAILURE",

  ADD_TO_CART_REQUEST: "ADD_TO_CART_REQUEST",
  ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
  ADD_TO_CART_FAILURE: "ADD_TO_CART_FAILURE",

  //you may like
  YOU_MAY_LIKE_REQUEST: "YOU_MAY_LIKE_REQUEST",
  YOU_MAY_LIKE_SUCCESS: "YOU_MAY_LIKE_SUCCESS",
  YOU_MAY_LIKE_FAILURE: "YOU_MAY_LIKE_FAILURE",

  //forgot password
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  CLEAR_FORGOTPASSWORD_RESPONSE: "CLEAR_FORGOTPASSWORD_RESPONSE",

  //reset password
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  CLEAR_RESETPASSWORD_RESPONSE: "CLEAR_RESETPASSWORD_RESPONSE",

  //user address...
  USER_ADDRESSES_REQUEST: "USER_ADDRESSES_REQUEST",
  USER_ADDRESSES_SUCCESS: "USER_ADDRESSES_SUCCESS",
  USER_ADDRESSES_FAILURE: "USER_ADDRESSES_FAILURE",
  // add or edit user address...
  ADD_USER_ADDRESS_REQUEST: "ADD_USER_ADDRESS_REQUEST",
  ADD_USER_ADDRESS_SUCCESS: "ADD_USER_ADDRESS_SUCCESS",
  ADD_USER_ADDRESS_FAIL: "ADD_USER_ADDRESS_FAIL",
  //remove wishlist...
  REMOVE_WISHLIST_REQUEST: "REMOVE_WISHLIST_REQUEST",
  REMOVE_WISHLIST_SUCCESS: "REMOVE_WISHLIST_SUCCESS",
  REMOVE_WISHLIST_FAILURE: "REMOVE_WISHLIST_FAILURE",

  //remove useraddress...
  REMOVE_ADDRESS_REQUEST: "REMOVE_ADDRESS_REQUEST",
  REMOVE_ADDRESS_SUCCESS: "REMOVE_ADDRESS_SUCCESS",
  REMOVE_ADDRESS_FAILURE: "REMOVE_ADDRESS_FAILURE",

  //countries....currency...
  CURRENCY_AND_COUNTRIES_REQUEST: "USER_ADDRESSES_REQUEST",
  CURRENCY_AND_COUNTRIES_SUCCESS: "USER_ADDRESSES_SUCCESS",
  CURRENCY_AND_COUNTRIES_FAILURE: "USER_ADDRESSES_FAILURE",
  // persional information
  GET_PERSIONAL_INFORMATION_REQUEST: "GET_PERSIONAL_INFORMATION_REQUEST",
  GET_PERSIONAL_INFORMATION_REQUEST_SUCCESS:
    "GET_PERSIONAL_INFORMATION_REQUEST_SUCCESS",
  GET_PERSIONAL_INFORMATION_REQUEST_FAILURE:
    "GET_PERSIONAL_INFORMATION_REQUEST_FAILURE",
  CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS:
    "CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS",
  // edit persional information
  EDIT_PERSIONAL_INFORMATION_REQUEST: "EDIT_PERSIONAL_INFORMATION_REQUEST",
  EDIT_PERSIONAL_INFORMATION_REQUEST_SUCCESS:
    "EDIT_PERSIONAL_INFORMATION_REQUEST_SUCCESS",
  EDIT_PERSIONAL_INFORMATION_REQUEST_FAILURE:
    "EDIT_PERSIONAL_INFORMATION_REQUEST_FAILURE",

  // edit user password
  EDIT_USER_PASSWORD_REQUEST: "EDIT_USER_PASSWORD_REQUEST",
  EDIT_USER_PASSWORD_REQUEST_SUCCESS: "EDIT_USER_PASSWORD_REQUEST_SUCCESS",
  EDIT_USER_PASSWORD_REQUEST_FAILURE: "EDIT_USER_PASSWORD_REQUEST_FAILURE",

  // MANAGE ADDRESS
  GET_PERSIONAL_ADDRESS_REQUEST: "GET_PERSIONAL_ADDRESS_REQUEST",
  GET_PERSIONAL_ADDRESS_REQUEST_SUCCESS:
    "GET_PERSIONAL_ADDRESS_REQUEST_SUCCESS",
  GET_PERSIONAL_ADDRESS_REQUEST_FAILURE:
    "GET_PERSIONAL_ADDRESS_REQUEST_FAILURE",
  CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS:
    "CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS",
  //WISHLIST DATA
  GET_WISH_LIST_DATA_REQUEST: "GET_WISH_LIST_DATA_REQUEST",
  GET_WISH_LIST_DATA_SUCCESS: "GET_WISH_LIST_DATA_SUCCESS",
  GET_WISH_LIST_DATA_FAILURE: "GET_WISH_LIST_DATA_FAILURE",
  CLEAR_WISH_LIST_DATA_SUCCESS: "CLEAR_WISH_LIST_DATA_SUCCESS",
  // ALL ORDERS
  GET_ALL_ORDERS_DATA_REQUEST: "GET_ALL_ORDERS_DATA_REQUEST",
  GET_ALL_ORDERS_DATA_SUCCESS: "GET_ALL_ORDERS_DATA_SUCCESS",
  GET_ALL_ORDERS_DATA_FAILURE: "GET_ALL_ORDERS_DATA_FAILURE",
  CLEAR_ALL_ORDERS_DATA_SUCCESS: "CLEAR_ALL_ORDERS_DATA_SUCCESS",
  // SINGLE ORDER

  GET_SINGLE_ORDER_DATA_REQUEST: "GET_SINGLE_ORDER_DATA_REQUEST",
  GET_SINGLE_ORDER_DATA_SUCCESS: "GET_SINGLE_ORDER_DATA_SUCCESS",
  GET_SINGLE_ORDER_DATA_FAILURE: "GET_SINGLE_ORDER_DATA_FAILURE",
  CLEAR_SINGLE_ORDER_DATA_SUCCESS: "CLEAR_SINGLE_ORDER_DATA_SUCCESS",
  //AUTH MODEL
  AUTH_MODAL_TYPE_REQUEST: "AUTH_MODAL_TYPE_REQUEST",
  AUTH_MODAL_TYPE_SUCCESS: "AUTH_MODAL_TYPE_SUCCESS",
  AUTH_MODAL_TYPE_CLEAR: "AUTH_MODAL_TYPE_CLEAR",
  //SITE MAP
  SITE_MAP_DATA_REQUEST: "SITE_MAP_DATA_REQUEST",
  SITE_MAP_DATA_REQUEST_SUCCESS: "SITE_MAP_DATA_REQUEST_SUCCESS",
  SITE_MAP_DATA_REQUEST_FAILURE: "SITE_MAP_DATA_REQUEST_FAILURE",
  //Carrier page
  CARREER_PAGE_DATA_REQUEST: "CARREER_PAGE_DATA_REQUEST",
  CARREER_PAGE_DATA_REQUEST_SUCCESS: "CARREER_PAGE_DATA_REQUEST_SUCCESS",
  CARREER_PAGE_DATA_REQUEST_FAILURE: "CARREER_PAGE_DATA_REQUEST_FAILURE",
  //SIGNUP
  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_ERROR: "SIGNUP_ERROR",
  //instagram links
  INSTAGRAM_LINK_REQUEST: " INSTAGRAM_LINK_REQUEST",
  INSTAGRAM_LINK_REQUEST_SUCCESS: "INSTAGRAM_LINK_REQUEST_SUCCESS",
  INSTAGRAM_LINK_REQUEST_FAILURE: "INSTAGRAM_LINK_REQUEST_FAILURE",

  //faq data
  FAQ_DATA_REQUEST: "FAQ_DATA_REQUEST",
  FAQ_DATA_REQUEST_SUCCESS: "FAQ_DATA_REQUEST_SUCCESS",
  FAQ_DATA_REQUEST_FAILURE: "FAQ_DATA_REQUEST_FAILURE",
  //privacy policy data
  PRIVACY_POLICY_DATA_REQUEST: "PRIVACY_POLICY_DATA_REQUEST",
  PRIVACY_POLICY_DATA_REQUEST_SUCCESS: "PRIVACY_POLICY_DATA_REQUEST_SUCCESS",
  PRIVACY_POLICY_DATA_REQUEST_FAILURE: "PRIVACY_POLICY_DATA_REQUEST_FAILURE",
  //terms of use
  TERMS_OF_USE_DATA_REQUEST: "TERMS_OF_USE_DATA_REQUEST",
  TERMS_OF_USE_DATA_REQUEST_SUCCESS: "TERMS_OF_USE_DATA_REQUEST_SUCCESS",
  TERMS_OF_USE_DATA_REQUEST_FAILURE: "TERMS_OF_USE_DATA_REQUEST_FAILURE",
};
