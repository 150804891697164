import React, { useEffect, useState } from "react";
import config from "../utilities/config";
import { useDispatch, useSelector } from "react-redux";
import constants from "../utilities/constants";
import { getalluseraddressess } from "../redux/action/orderAction";
import { Toast } from "../../src/components/Atoms/Toast";
import {
  getCartpagedata,
  getCartpagedata_count,
  youMayLikeData,
  apply_voucher,
  clearvoucherdata,Update_Cart_Latest_Price,
  Add_To_Cart,
} from "../redux/action/cartAction";
import { Link } from "react-router-dom";
import {
  getAllCountryNames,
  createOrder,
  sendToAirPay,
  addUserAddress,
  cartMessageAdd,
  cartaddressadd,
  fetchPincodeMasterData,
} from "../utilities/api";
import {
  formatCurrency,
  getValue,
  formatCurrencywithamount,
} from "../utilities/helper";
import { getAuthModalType, loginModalAction } from "../redux/action/authAction";

function Checkout() {
  const dispatch = useDispatch();
  const { selectedcountrySuccess } = useSelector((state) => state.homeReducer);
  const dailyMetalPriceSuccess = useSelector(
    (state) => state.homeReducer.dailyMetalPriceSuccess
  );
  //apply voucher
  const {
    voucherResponesLoading,
    voucherSuccessmessage,
    voucherPriceResponse,
    voucherResponseError,
  } = useSelector((state) => state.voucherreducer);
  console.log(voucherPriceResponse, "voucherPriceResponse");
  const {
    //persionalinformation
    persionalInformationDataLoading,
    persionalInformationDataSuccess,
    persionalInformationDataError,
    //address
    persionalAddressDataLoading,
    persionalAddressDataSuccess,
    persionalAddressDataError,
    //wishlist
    wishListDataLoading,
    wishListDataSuccess,
    wishListDataError,
    //all orders
    allOrdersDataLoading,
    allOrdersDataSuccess,
    allOrdersDataError,
    //single order
    singleOrderDataLoading,
    singleOrderDataSuccess,
    singleOrderDataError,
  } = useSelector((state) => state.myAccountReducer);
  console.log(persionalAddressDataSuccess, "persionalAddressDataSuccesslll");

  console.log(
    selectedcountrySuccess,
    "selectedcountrySuccess, dailyMetalPriceSuccessincheckoutpage",
    dailyMetalPriceSuccess
  );
  let voucherCode = "",
    buyerPhone = "",
    buyerPinCode = "";
  const [formData, setFormData] = useState({
    name: "",
    country: "India",
    address: "",
    contact: "",
    zipCode: "",
    city: "",
    state: "",
  });
  const [pincodeData, setPincodeData] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  //to get the user info from authReducer
  const { accessToken, userProfile_id, userProfile_email, cartID } =
    useSelector((state) => state.authReducer);
  console.log(
    userProfile_email,
    "userProfile_emailincheckoutpage",
    userProfile_id,
    "userProfile_idincheckoutpage"
  );
  //to get the cart count...
  const {
    cart_items_numberResponesLoading,
    cart_items_numberResponseSuccess,
    cart_items_numberResponseSuccess_info,
    cart_items_numberResponseError,
  } = useSelector((state) => state.cartNumberReducer);
  const { add_to_cartResponseSuccess } = useSelector(
      (state) => state.addtocartreducer
    );
  const cartId = cart_items_numberResponseSuccess
    ? cart_items_numberResponseSuccess?.nodes[0]?.id
    : "";
  const [giftmessage, setgiftmessage] = useState({
    cart_id: cartId,
    gift_from: userProfile_email,
    gift_to: "",
    message: "",
  });
  const [errors, setErrors] = useState({}); //address modal form errors
  const [isLoading, setIsLoading] = useState(false);
  const [isAddressLoading, setisAddressLoading] = useState(false); //address form submitted
  const [iscartmessageLoading, setiscartmessageLoading] = useState(false); //address form submitted
  const [selectedOption, setSelectedOption] = useState(null); //for shipping..
  const [billingOption, setbillingOption] = useState(null); //for billing
  const [billing_shipping_same, setbilling_shipping_same] = useState(1); //for checkbox if billing and shipping address same
  const [countryNames, setCountryNames] = useState([]); //all country names to list in address modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [SelectedOptionEditAddress, setSelectedOptionEditAddress] =
    useState(null);
  console.log(selectedOption, "selectedOption");
  console.log(billingOption, "billingOption");
  console.log(billing_shipping_same, "billing_shipping_same");

  //to get all user address..
  const {
    userAddressess_success,
    USER_ADDRESSES_FAILURE,
    USER_ADDRESSES_REQUEST,
  } = useSelector((state) => state.useraddressreducer);
  console.log(userAddressess_success, "userAddressess_success&&&&&");
  let useraddress =
    userAddressess_success &&
    userAddressess_success?.data?.data?.allUserAddresses?.nodes;
  let billing_address =
    billing_shipping_same === 1
      ? useraddress?.slice(0, 1)
      : useraddress.filter((item, index) => index !== selectedOption);

  console.log(
    selectedOption,
    "selectedOption",
    billingOption,
    "billingOption",
    billing_shipping_same,
    "billing_shipping_same"
  );
  //modal addresss..
  // const addressFormValueChange = (e) => {
  //   const { name, value } = e.target;
  //   alert(name)
  //   setFormData({ ...formData, [name]: value });
  // };
  //addressFormSubmit
  const validateadressformFields = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `Please fill in your ${
          key.charAt(0).toUpperCase() + key.slice(1)
        }`;
      }
      else if(key === 'contact' && parseInt(formData[key]) === 0){
        newErrors[key] = `Enter Valid Contact Number`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };
  const handleApplyClick = () => {
    if (!promoCode.trim()) {
      // Show toast message if promo code is not entered
      Toast("Please enter the Promo Code!", "error");
      return;
    }
    config.logGeneral && console.log(cartID,"cartID7777777")
    config.logGeneral && console.log("Voucher Code Applied Successfully")
    

    // Prepare form data for the apply_voucher action
    // Toast("Voucher Code Applied Successfully!", "success");

    // Dispatch the apply_voucher action
    if (userProfile_id) {
      dispatch(apply_voucher(userProfile_id, cartID, promoCode));
    } else {
      dispatch(
        apply_voucher((userProfile_id = null), (cartID = null), promoCode)
      );
    }
  };

  const cartmessageFieldscheck = () => {
    const newErrors = {};
    if (giftmessage.message && !giftmessage.gift_to) {
      newErrors.gift_to =
        "Please provide the recipient's email if you are adding a message.";
    } else if (!giftmessage.message && giftmessage.gift_to) {
      newErrors.message =
        "Please provide a message if you are adding a recipient's email.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const cartMessageFormSubmit = async (e) => {
    e.preventDefault();
    if (cartmessageFieldscheck()) {
      setiscartmessageLoading(true); // Set loading to true
      try {
        // Prepare data for API
        let gift_from = giftmessage.gift_from,
          gift_to = giftmessage.gift_to,
          cart_id = giftmessage.cart_id,
          message = giftmessage.message;
        // Call the API function
        await cartMessageAdd(cart_id, gift_from, gift_to, message);
        Toast("Message added successfully!!!", "success");
      } catch (error) {
        console.error("Error adding message:", error);
        Toast("Error adding message", "error");
      } finally {
        setiscartmessageLoading(false); // Reset loading state
      }
    } else {
      console.log("Please fill in all required fields.");
    }
  };

  const addressFormSubmit = async (e) => {
    e.preventDefault();

    if (validateadressformFields()) {
      setisAddressLoading(true); // Set loading to true

      try {
        // Prepare data for API
        let address_to_sendforAPI = [
          {
            salutation: "",
            firstname: formData.name,
            lastname: "",
            addressline1: formData.address,
            addressline2: "",
            pincode: formData.zipCode,
            city: formData.city,
            state: formData.state,
            country: formData.country,
            country_code: "",
            contactno: formData.contact,
            addresstype: "",
            errortext: { pinerr: "", pinerr1: "", country: "" },
          },
        ];

        // Assuming user_id and id are available in your component
        const user_id = userProfile_id; /* Get user ID from state or props */
        const id_in_useraddress =
          useraddress[SelectedOptionEditAddress]?.id; /* Get ID from state */

        // Call the API function
        await addUserAddress(address_to_sendforAPI, user_id, id_in_useraddress);
        setIsModalOpen(false);
        dispatch(getalluseraddressess(userProfile_id));
        Toast("Address added successfully!", "success");
      } catch (error) {
        console.error("Error adding address:", error);
        Toast("Error adding address", "error");
      } finally {
        setisAddressLoading(false); // Reset loading state
      }
    } else {
      console.log("Please fill in all required fields.");
      Toast("Please fill in all required fields", "error");
    }
  };
  const addressFormValueChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "zipCode" && value.length > 3) {
      try {
        const response = await fetchPincodeMasterData(value);
        console.log(response, "Pincode details fetched successfully");

        const pincodeData = response.data.data.allPincodeMasters.nodes;
        setPincodeData(pincodeData); // Update the state with the fetched data

        if (pincodeData && pincodeData.length > 0) {
          // Prioritize "India" if it exists
          const uniqueCountries = [
            ...new Set(pincodeData.map((node) => node.country)),
          ];
          const prioritizedCountries = uniqueCountries.includes("India")
            ? [
                "India",
                ...uniqueCountries.filter((country) => country !== "India"),
              ]
            : uniqueCountries;

          setCountryNames(prioritizedCountries);

          // Set the first matching district and city for the first country
          const firstCountry = prioritizedCountries[0];
          const firstMatch = pincodeData.find(
            (node) => node.country === firstCountry
          );
          if (firstMatch) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              country: firstCountry,
              city: firstMatch.district,
              state: firstMatch.state,
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching pincode data:", error);
        Toast("Error fetching pincode data", "error");
      }
    } else if (name === "zipCode" && value.length <= 3) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        country: "",
        city: "",
        state: "",
      }));
      setCountryNames([]);
      setPincodeData([]); // Clear the pincode data
    }
  };
  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      country: selectedCountry,
    }));

    // Find the first matching pincode data for the selected country
    const firstMatch = pincodeData.find(
      (node) => node.country === selectedCountry
    );
    if (firstMatch) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        city: firstMatch.district,
        state: firstMatch.state,
      }));
    }
  };
  useEffect(() => {
      dispatch(getCartpagedata_count(userProfile_id));
    }, [add_to_cartResponseSuccess]);
  useEffect(() => {
    
    const storedCartDetails = JSON.parse(
        localStorage.getItem("cartDetails") || "{}"
      );
  
      if (userProfile_id && storedCartDetails?.products?.length > 0) {
        if (cartID) {
          console.log("kkkkk 01")
          dispatch(Update_Cart_Latest_Price(userProfile_id));
        }
        console.log("kkkkkkkkk nw cartid:", cartID)
        dispatch(
          Add_To_Cart(
            userProfile_id,
            storedCartDetails.products,
            cartID
          )
        );
        console.log("kkkk nw cartid:", cartID)
        localStorage.removeItem("cartDetails");
      }
    }, [userProfile_id, dispatch,cartID]);
  useEffect(() => {
    if (userProfile_id !== null) {
      dispatch(getalluseraddressess(userProfile_id));
      dispatch(getCartpagedata_count(userProfile_id));
      // fetchCountryNames();
    } else {
      dispatch(getAuthModalType(constants.Login));
      // dispatch(loginModalAction({ isOpenModal: true, modalType: 'cart' }));
    }
    // fetchCountryNames();//to get all country names in address modal
  }, [userProfile_id]);

  // Prevent unchecking
  const handleChange = (event) => {
    // Force the checkbox to stay checked
    if (event.target.checked) {
      setbilling_shipping_same(1);
    } else {
      setbilling_shipping_same(0);
    }
  };

  const changeGiftMessage = (event) => {
    const { name, value } = event.target;
    if (name === "gift_to" && value === giftmessage.gift_from) {
      Toast(
        "Recipient's email cannot be the same as the sender's email.",
        "error"
      );
      return; // Do not update the state if the emails are the same
    }
    setgiftmessage({ ...giftmessage, [name]: value });
  };

  const validateAddresses = () => {
    if (!useraddress || useraddress?.length === 0) {
      Toast("Please add an address.", "error");
      return false;
    }

    if (billing_shipping_same === 1) {
      if (selectedOption === null) {
        Toast("Please select a shipping address.", "error");
        return false;
      }
    } else {
      if (billingOption === null || selectedOption === null) {
        config.logGeneral && console.log(billingOption,"billingOptioninaddresscheck",selectedOption,"selectedOption")
        if (useraddress?.length === 1) {
          if (billingOption === null) {
            Toast("Please add Billing address.", "error");
          } else {
            Toast("Please add shipping address.", "error");
          }
        } else {
          if (billingOption === null) {
            Toast("Billing address must be selected.", "error");
          } else {
            Toast("Shipping address must be selected.", "error");
          }
        }
        return false;
      }
      else if(selectedOption === 0 && billingOption === 0 && billing_shipping_same === 0){
        Toast("Please add Billing address.", "error");
        return false;        
      }
    }

    return true;
  };

  //for payment in airpay...
  const handlePayment = async () => {
    setIsLoading(true);
    if (!validateAddresses()) {
      setIsLoading(false);
      return; // Stop if validation fails
    }
    try {
      // 1. Step: Call createorder API to create an order cartId, voucherCode, userId      
      
      const createOrderResponse = await createOrder(
        cartId,
        userProfile_id,
        voucherCode
      );
      console.log(createOrderResponse, "createOrderResponse...");

      const createOrderData = createOrderResponse;

      if (!createOrderData || !createOrderData?.id) {
        throw new Error("Failed to create order");
      }
      dispatch(clearvoucherdata());

      // 2. Step: Call sendtoairpay API with the order details
      const sendToAirPayResponse = await sendToAirPay(
        createOrderResponse.id,
        createOrderResponse.total_amount,
        buyerPhone,
        buyerPinCode
      );

      const sendToAirPayData = sendToAirPayResponse;
      console.log(sendToAirPayData, "sendToAirPayData");
      if (!sendToAirPayData || !sendToAirPayData.checksum) {
        throw new Error("Failed to get AirPay payment data");
      }
      

      // 3. Step: Create a hidden form dynamically and submit to AirPay
      const form = document.createElement("form");
      form.method = "POST";
      form.action = "https://payments.airpay.co.in/pay/index.php";
      form.id = "sendtoairpay";

      // Add input fields dynamically from the response
      // for dynamic below should be used
      const fields = [
        { name: "privatekey", value: sendToAirPayData.privatekey },
        { name: "mercid", value: sendToAirPayData.mercid },
        { name: "orderid", value: createOrderData.payment_id },
        { name: "currency", value: sendToAirPayData.currency },
        { name: "isocurrency", value: sendToAirPayData.isocurrency },
        { name: "chmod", value: sendToAirPayData.chmod },
        { name: "buyerEmail", value: sendToAirPayData.buyerEmail },
        { name: "buyerPhone", value: sendToAirPayData.buyerPhone },
        { name: "buyerFirstName", value: sendToAirPayData.buyerFirstName },
        { name: "buyerLastName", value: sendToAirPayData.buyerLastName },
        { name: "buyerAddress", value: sendToAirPayData.buyerAddress },
        { name: "buyerCity", value: sendToAirPayData.buyerCity },
        { name: "buyerState", value: sendToAirPayData.buyerState },
        { name: "buyerCountry", value: sendToAirPayData.buyerCountry },
        { name: "buyerPinCode", value: sendToAirPayData.buyerPinCode },
        { name: "amount", value: sendToAirPayData.amount },
        { name: "checksum", value: sendToAirPayData.checksum },
      ];

      //   const fields = [
      //     { name: 'privatekey', value: "7c6fbe38e75f1b6baaf22926544b289a90793e3af7888a49c07c45094d20388d" },
      //     { name: 'mercid', value: "34701" },
      //     { name: 'orderid', value: "6618" },
      //     { name: 'currency', value: "356" },
      //     { name: 'isocurrency', value: "INR" },
      //     { name: 'chmod', value: "" },
      //     { name: 'buyerChitNumber', value: "1" },
      //     { name: 'buyerPhone', value: "" },
      //     { name: 'buyerFirstName', value: "test" },
      //     { name: 'buyerLastName', value: "" },
      //     { name: 'buyerEmail', value: "kiruthika@yahoo.com" },
      //     { name: 'amount', value: "1" },
      //     { name: 'paymentMode', value: "airpay" },
      //     { name: 'customvar', value: "" },
      //     { name: 'checksum', value: "9bdf3fc6057675891d2e2f0b9d66264b9148554c1f5899c782ba993c9a46102d" }
      // ];

      // Loop through the fields and create hidden inputs
      fields.forEach((field) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = field.name;
        input.value = field.value;
        form.appendChild(input);
      });

      // Append the form to the document body (hidden from the user)
      document.body.appendChild(form);

      // 4. Step: Submit the form automatically
      form.submit();
    } catch (error) {
      console.error("Payment process error:", error.message);
    } finally {
      setIsLoading(false); // Reset isLoading to false when the payment process is complete
    }
  };

  // for shipping
  const handleChange1 = async (event,type) => {
    // Get the radio button groups by name 
    config.logGeneral && console.log(type,"typecalledhandlechange11")
    
    // if(type === 'shipping' && billing_shipping_same === 0  && billingOption === 0 && selectedOption === 0){
    //   setbillingOption(null);
    // }
    const selectedOptionElements = document.getElementsByName("responsive1");
    const billingOptionElements = document.getElementsByName("responsive");

    // Find the selected radio button for both groups
    let selectedValue = null;
    for (let i = 0; i < selectedOptionElements.length; i++) {
      if (selectedOptionElements[i].checked) {
        selectedValue = selectedOptionElements[i].value;
        break; // Exit the loop once we find the selected radio button
      }
    }

    let billingValue = null;
    for (let i = 0; i < billingOptionElements.length; i++) {
      if (billingOptionElements[i].checked) {
        billingValue = billingOptionElements[i].value;
        break; // Exit the loop once we find the selected radio button
      }
    }
    // Update React state to keep everything in sync
    if (selectedValue !== null) {
      setSelectedOption(parseInt(selectedValue)); // Update the state with the selected shipping address
    }
    if (billingValue !== null) {
      setbillingOption(parseInt(billingValue)); // Update the state with the selected billing address
    }

    // Check if both shipping and billing addresses are selected
    if (billing_shipping_same === 0) {
      if (selectedValue === null && billingValue === null) {
        Toast("Select both shipping and billing addresses!", "error");
        return; // Exit if either of the options is not selected
      }
      else if(billingValue === null){
        Toast("Select billing address!", "error");
        return; // Exit if either of the options is not selected        
      }
      else if(selectedValue === null){
        Toast("Select shipping address!", "error");
        return; // Exit if either of the options is not selected 
      }      
    }

    let address_info = []; // Array to hold addresses to send to API
    let addressId = "";

    // Check if billing and shipping addresses are the same
    if (billing_shipping_same === 1) {
      // If billing and shipping are the same, use the selected shipping address from useraddress
      let address_to_send_api = {
        ...useraddress[parseInt(selectedValue)],
        addresstype: 1,
      };
      console.log(address_to_send_api, "address_to_send_api");
      address_info.push(address_to_send_api); // Add shipping address to address_info
      addressId = address_info.id;
    } else {
      // Add shipping address from useraddress
      let address_to_send_api = {
        ...useraddress[parseInt(selectedValue)],
        addresstype: 1,
      };
      address_info.push(address_to_send_api); // Add shipping address to address_info

      // Add billing address from billing_address
      if (
        billing_address &&
        billing_address.length > 0 &&
        billingValue >= 0 &&
        billingValue < billing_address.length
      ) {
        let billing_address_to_send_api = {
          ...billing_address[parseInt(billingValue)],
          addresstype: 2,
        };
        address_info.push(billing_address_to_send_api); // Add billing address to address_info
        addressId = address_info.id;
      } else {
        console.error(
          "Invalid billingOption selected or billing address not found."
        );
        Toast(
          "Invalid billing option selected or no billing addresses available",
          "error"
        );
        return; // Exit if billing address is not valid
      }
    }

    // Now that address_info is populated, call the cartaddressadd API function with the correct parameters
    try {
      await cartaddressadd(userProfile_id, address_info, cartId);
      console.log("Address information sent to API:", address_info);
      Toast("Address selected has been added to the cart", "success");
    } catch (error) {
      console.error("Error in cartaddressadd:", error);
      Toast("Failed to update the cart address", "error");
    }
  };
  const cartItemsProductInfo =
    cart_items_numberResponseSuccess?.nodes[0]
      ?.shoppingCartItemsByShoppingCartId?.nodes || [];
  const getImage = (image, size = "300X300") => {
    let productId = image.split("/");
    return `${constants.image_url}${productId?.[0]}/${productId?.[1]}/${size}/${productId?.[2]}`;
  };
  const actualPrice = cartItemsProductInfo?.reduce((total, item) => {
    return total + (item?.transSkuListByProductSku?.discountPrice || 0);
  }, 0);

  //get all country names from api..
  // const fetchCountryNames = async () => {
  //   try {
  //     const response = await getAllCountryNames();
  //     setCountryNames(response);
  //   } catch (error) {
  //     console.log(error, " error in getting the all country names....");
  //   }
  // };

  // For Apply Coupon
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {/* {userAddressess_success && <h2>{JSON.stringify(useraddress)}</h2>} */}
      {/* {cart_items_numberResponseSuccess && <div>{JSON.stringify(cart_items_numberResponseSuccess)}</div>} */}
      <div className="relative top-14">
        <div className="container mx-auto px-4 2xl:mb-16">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 lg:col-span-8">
              <div className="border border-solid px-6 py-6 gap-4 w-full border-none px-0 sm:border sm:border-solid sm:px-6">
                <div className="md:flex justify-start items-center gap-4 mt-10 md:mt-0">
                  <div>
                    <svg
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="40.0003"
                        cy="40"
                        r="28"
                        transform="rotate(-45 40.0003 40)"
                        fill="url(#paint0_linear_1345_54)"
                      />
                      <g clip-path="url(#clip0_1345_54)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M46.8125 30.6885C49.5869 31.3323 50.6482 32.9546 51.9788 35.1875H46.8125V30.6885ZM30.5986 35.375C31.1418 35.375 31.5361 35.6372 31.5361 36.25C31.5361 36.7668 31.1179 37.186 30.6013 37.1875H24.9375C24.4197 37.1875 24 37.6074 24 38.125C24 38.6428 24.4197 39.0625 24.9375 39.0625H33.375C33.8977 39.0625 34.3162 39.4819 34.3162 40C34.3162 40.5178 33.8965 40.9375 33.3787 40.9375H24.9375C24.4197 40.9375 24 41.3572 24 41.875C24 42.3928 24.4197 42.8125 24.9375 42.8125H27V45.625C27 46.1428 27.4197 46.5625 27.9375 46.5625H29.822C30.158 48.2173 31.6201 49.4375 33.3438 49.4375C35.0674 49.4375 36.5295 48.2173 36.8655 46.5625H46.3845C46.7205 48.2173 48.1826 49.4375 49.9062 49.4375C51.6299 49.4375 53.092 48.2173 53.428 46.5625H55.0625C55.5803 46.5625 56 46.1428 56 45.625V40C56 37.2493 53.0986 37.0667 53.0959 37.0625H45.875C45.3572 37.0625 44.9375 36.6428 44.9375 36.125V30.5H27.9375C27.4197 30.5 27 30.9197 27 31.4375V33.5H25.875C25.3572 33.5 24.9375 33.9197 24.9375 34.4375C24.9375 34.9553 25.3572 35.375 25.875 35.375H30.5986ZM51.1216 44.6284C51.7927 45.2996 51.7927 46.3882 51.1216 47.0593C50.042 48.1389 48.1875 47.3708 48.1875 45.8438C48.1875 44.3169 50.042 43.5488 51.1216 44.6284ZM34.5591 44.6284C35.2302 45.2996 35.2302 46.3882 34.5591 47.0593C33.4795 48.1389 31.625 47.3708 31.625 45.8438C31.625 44.3169 33.4795 43.5488 34.5591 44.6284Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <linearGradient
                          id="paint0_linear_1345_54"
                          x1="40.0003"
                          y1="12"
                          x2="40.0003"
                          y2="68"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#06AB9F" />
                          <stop offset="0.23" stop-color="#007970" />
                          <stop offset="0.44" stop-color="#06AB9F" />
                          <stop offset="0.65" stop-color="#007970" />
                          <stop offset="0.84" stop-color="#06AB9F" />
                          <stop offset="1" stop-color="#007970" />
                        </linearGradient>
                        <clipPath id="clip0_1345_54">
                          <rect
                            width="32"
                            height="32"
                            fill="white"
                            transform="translate(24 24)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div>
                    <p className="text-lg font-bold">
                      Delivery/ Pickup Information
                    </p>
                  </div>
                  <div className="md:ml-auto mr-6">
                    <Link
                      to=""
                      className="text-white text-base text-center py-2 px-10 bg-customGreen border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 block"
                      onClick={() => {
                        setIsModalOpen(true);
                        setErrors({});
                        setSelectedOptionEditAddress(null);
                        setFormData({
                          name: "",
                          country: "India",
                          address: "",
                          contact: "",
                          zipCode: "",
                          city: "",
                          state: "",
                        });
                      }}
                    >
                      Add New Address
                    </Link>
                  </div>
                </div>
                {/*shipping address */}
                {useraddress && useraddress.length > 0 ? (
                  useraddress.map((e, i) => {
                    return (
                      <div
                        key={i}
                        className="border border-solid px-6 py-6 gap-4 mx-0 my-5 sm:mx-0"
                      >
                        <div className="flex justify-between flex-col md:flex-row items-center md:items-center space-y-2 md:space-y-0 md:space-x-4">
                          <div className="flex justify-between">
                            <label className="flex items-start space-x-2 mt-[7px]">
                            <input
                              type="radio"
                              name="responsive1"
                              value={i}
                              className="w-4 h-4 mr-2 text-green border-gray-300 focus:ring-green checked:bg-green checked:border-green"
                              checked={selectedOption === i}
                              onChange={(e) => handleChange1(e,"shipping")}
                            />
                            </label>
                            <div>
                              <p className="text-xl font-medium mb-2">
                                {e.firstname} {e.lastname}
                              </p>
                              <p className="text-base text-gray-500">
                                {e.addressline1} {e.city || "Chennai"}{" "}
                                {e.state || "Tamil Nadu"} {e.pincode}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col justify-center items-center">
                            <Link
                              to=""
                              onClick={() => {                                
                                setIsModalOpen(true);
                                setErrors({});
                                setSelectedOptionEditAddress(parseInt(i));
                                setFormData({
                                  name: `${e.firstname} ${e.lastname}`,
                                  country: e.country,
                                  address: e.addressline1,
                                  contact: e.contactno,
                                  zipCode: e.pincode,
                                  city: e.city,
                                  state: e.state,
                                });
                              }}
                              className="text-black text-base text-center py-2 px-10 border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 mb-3 w-[220px]"
                            >
                              Edit Address
                            </Link>
                            {/* {i + 1 === useraddress.length && (
                              <Link
                                to=""
                                className="text-white text-base text-center py-2 px-10 bg-customGreen border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 w-[220px]"
                                onClick={() => setIsModalOpen(true)}
                              >
                                Add New Address
                              </Link>
                            )} */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h2>No address to list</h2>
                )}

                {useraddress && useraddress.length > 0 && (
                  <div className="flex gap-2">
                    <div>
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 peer"
                        checked={billing_shipping_same}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div>
                      <p className="text-base text-sm text-gray-700">
                        Please check the box if your Billing address is same as
                        your shipping address.
                      </p>
                    </div>
                  </div>
                )}

                {billing_address &&
                  billing_shipping_same !== 1 &&
                  billing_address?.map((e, i) => {
                    return (
                      <div
                        key={i}
                        className="border border-solid px-6 py-6 gap-4 mx-0 my-5 sm:mx-0"
                      >
                        <div className="flex justify-between flex-col md:flex-row items-center md:items-center space-y-2 md:space-y-0 md:space-x-4">
                          <div className="flex justify-between">
                            <label className="flex items-start space-x-2 mt-2">
                              <input
                                type="radio"
                                name="responsive"
                                value={i}
                                className="w-4 h-4 text-green-500 border-gray-300 focus:ring-green-500 mr-2"
                                checked={billingOption === i}
                                // onChange={() => changeBillingaddress(i)}
                                onChange={(e) => handleChange1(e,"billing")}
                              />
                            </label>
                            <div>
                              <p className="text-sm text-bold">
                                Billing Address
                              </p>
                              <p className="text-xl font-medium mb-2">{`${e.firstname} ${e.lastname}`}</p>
                              <p className="text-base text-gray-500">
                                {e.addressline1} {e.city || "Chennai"}{" "}
                                {e.state || "Tamil Nadu"} {e.pincode}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col justify-center items-center">
                            {/* <Link 
                          onClick={() =>                            
                            {
                              setIsModalOpen(true);
                              setSelectedOptionEditAddress(parseInt(i));
                              setFormData({
                                name: `${e.firstname} ${e.lastname}`,
                                country: e.country,
                                address: e.addressline1,
                                contact: e.contactno,
                                zipCode: e.pincode,
                                city: e.city,
                                state: e.state
                              });
                            }}
                           to="" className='text-black text-base text-center py-2 px-10 border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 mb-3 w-[220px]'>
                            Edit Address
                          </Link> */}
                            {/* {i + 1 == billing_address.length && <Link to="" className='text-white text-base text-center py-2 px-10 bg-customGreen border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 w-[220px]'>
                            Add New Address
                          </Link>} */}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <div className="md:flex justify-start items-center gap-4 mt-10 md:mt-10">
                  <div>
                  <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="38.9987" cy="38.9997" r="28" transform="rotate(-30 38.9987 38.9997)" fill="url(#paint0_linear_3652_7438)"/>
<g clip-path="url(#clip0_3652_7438)">
<path d="M49.4348 31.6747H47.6878C47.9182 31.31 48.0703 30.9006 48.1137 30.4677C48.2522 29.0841 47.5235 27.8437 46.283 27.2939C45.1427 26.7884 43.8613 26.995 42.9385 27.8319L40.7326 29.8312C40.303 29.3613 39.6854 29.066 39 29.066C38.3134 29.066 37.6948 29.3622 37.2651 29.8335L35.0564 27.8317C34.1321 26.9948 32.8512 26.789 31.7115 27.2941C30.4712 27.8441 29.7424 29.0848 29.8814 30.4684C29.9248 30.901 30.0768 31.3102 30.3072 31.6747H28.5652C27.7007 31.6747 27 32.3754 27 33.2399V35.5877C27 36.0199 27.3504 36.3703 27.7826 36.3703H50.2174C50.6496 36.3703 51 36.0199 51 35.5877V33.2399C51 32.3754 50.2993 31.6747 49.4348 31.6747ZM36.6522 31.4138V31.6747H32.9705C31.9977 31.6747 31.2322 30.7678 31.4751 29.755C31.5821 29.309 31.9041 28.9288 32.3205 28.7363C32.8922 28.4721 33.5256 28.5569 34.0058 28.9916L36.6528 31.3907C36.6527 31.3985 36.6522 31.4061 36.6522 31.4138ZM46.5607 30.2599C46.5023 31.0733 45.7676 31.6747 44.9521 31.6747H41.3478V31.4139C41.3478 31.4047 41.3473 31.3955 41.3472 31.3863C41.9468 30.8427 43.2476 29.6637 43.9531 29.0242C44.3541 28.6608 44.9152 28.4911 45.4338 28.6458C46.178 28.8678 46.6151 29.5007 46.5607 30.2599Z" fill="white"/>
<path d="M28.5664 37.9355V49.4138C28.5664 50.2783 29.2671 50.979 30.1316 50.979H37.436V37.9355H28.5664Z" fill="white"/>
<path d="M40.5664 37.9355V50.979H47.8708C48.7352 50.979 49.436 50.2783 49.436 49.4138V37.9355H40.5664Z" fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear_3652_7438" x1="38.9987" y1="10.9997" x2="38.9987" y2="66.9997" gradientUnits="userSpaceOnUse">
<stop stop-color="#06AB9F"/>
<stop offset="0.23" stop-color="#007970"/>
<stop offset="0.44" stop-color="#06AB9F"/>
<stop offset="0.65" stop-color="#007970"/>
<stop offset="0.84" stop-color="#06AB9F"/>
<stop offset="1" stop-color="#007970"/>
</linearGradient>
<clipPath id="clip0_3652_7438">
<rect width="24" height="24" fill="white" transform="translate(27 27)"/>
</clipPath>
</defs>
                  </svg> 
                  </div>
                  <div>
                    <p className="text-lg font-bold">Add a Gift Message</p>
                  </div>
                </div>
                <div className="border border-solid px-6 py-6 gap-4 mx-0 my-5 sm:mx-0">
                  <div className="w-full mx-auto p-6 bg-white rounded-lg sm: !p-0">
                    <form onSubmit={cartMessageFormSubmit}>
                      <div className="mb-4">
                        <label
                          for="message"
                          className="block text-gray-700 font-medium"
                        >
                          Add a Special Message! (Optional)
                        </label>
                        <textarea
                          id="message"
                          name="message"
                          rows="4"
                          onChange={changeGiftMessage}
                          className="mt-1 p-3 w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 bg-gray-100"
                          placeholder="Enter your special message here..."
                        ></textarea>
                        {errors.message && (
                          <p className="text-red-500 text-xs">
                            {errors.message}
                          </p>
                        )}
                      </div>

                      <div class="mb-4">
                        <label
                          for="gift_to"
                          className="block text-gray-700 font-medium"
                        >
                          Gift Recipient’s Email! (Optional)
                        </label>
                        <input
                          type="email"
                          id="gift_to"
                          name="gift_to"
                          onChange={changeGiftMessage}
                          className="mt-1 mb-2 p-3 w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 bg-gray-100"
                          placeholder="Enter recipient's email"
                        />
                        {errors.gift_to && (
                          <p className="text-red-500 text-xs">
                            {errors.gift_to}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        {/* <Link to="" className='text-white text-base text-center py-2 px-10 bg-customGreen border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 w-[220px]' 
                    >
                     Add Cart Message
                  </Link> */}
                        <button
                          disabled={iscartmessageLoading}
                          className="text-white text-base text-center py-2 px-10 bg-customGreen border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 w-[220px]"
                        >
                          Add Cart Message
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                {/* <div className="md:flex justify-start items-center gap-4 mt-10 md:mt-10">
                  <div>
                  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="39.598" cy="39.598" r="28" transform="rotate(-45 39.598 39.598)" fill="url(#paint0_linear_3652_7479)"/>
                    <g clip-path="url(#clip0_3652_7479)">
                    <path d="M37.0286 29.8496C37.0286 28.3418 38.2552 27.1152 39.763 27.1152H40.7093L40.4238 26.3217C40.2633 25.8754 39.8428 25.5979 39.3945 25.5979C39.2703 25.5979 39.144 25.6192 39.0205 25.6642L29.4583 29.1445C28.8898 29.3515 28.5972 29.9806 28.8053 30.5486L29.3106 31.9277H37.0286V29.8496Z" fill="white"/>
                    <path d="M39.7617 28.7559C39.1576 28.7559 38.668 29.2455 38.668 29.8496V31.9277H48.2383V29.8496C48.2383 29.2455 47.7486 28.7559 47.1445 28.7559H39.7617Z" fill="white"/>
                    <path d="M51.1367 47.7051H47.3086C45.047 47.7051 43.207 45.8651 43.207 43.6035C43.207 41.3419 45.047 39.502 47.3086 39.502H51.1367V35.7559C51.1367 34.5478 50.1573 33.5684 48.9492 33.5684H27.7852C26.5771 33.5684 25.5977 34.5478 25.5977 35.7559V51.4104C25.5977 52.6185 26.5771 53.5979 27.7852 53.5979H48.9492C50.1573 53.5979 51.1367 52.6185 51.1367 51.4104V47.7051Z" fill="white"/>
                    <path d="M52n   .7773 41.1426H47.3086C45.9516 41.1426 44.8477 42.2466 44.8477 43.6035C44.8477 44.9605 45.9516 46.0645 47.3086 46.0645H52.7773C53.2297 46.0645 53.5977 45.6965 53.5977 45.2441V41.9629C53.5977 41.5106 53.2297 41.1426 52.7773 41.1426ZM47.3086 44.4238C46.8556 44.4238 46.4883 44.0565 46.4883 43.6035C46.4883 43.1505 46.8556 42.7832 47.3086 42.7832C47.7616 42.7832 48.1289 43.1505 48.1289 43.6035C48.1289 44.0565 47.7616 44.4238 47.3086 44.4238Z" fill="white"/>
                    </g>
                    <defs>
                    <linearGradient id="paint0_linear_3652_7479" x1="39.598" y1="11.598" x2="39.598" y2="67.598" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#06AB9F"/>
                    <stop offset="0.23" stop-color="#007970"/>
                    <stop offset="0.44" stop-color="#06AB9F"/>
                    <stop offset="0.65" stop-color="#007970"/>
                    <stop offset="0.84" stop-color="#06AB9F"/>
                    <stop offset="1" stop-color="#007970"/>
                    </linearGradient>
                    <clipPath id="clip0_3652_7479">
                    <rect width="28" height="28" fill="white" transform="translate(25.5977 25.5979)"/>
                    </clipPath>
                    </defs>
                    </svg>

                  </div>
                  <div className="flex gap-4 items-center">
                    <p className="text-lg font-bold">Select Payment Mode</p>
                    <div className="flex gap-1 items-center">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.6693 2.62655L8.0026 0.293213L1.33594 2.62655V7.99988C1.33594 10.7512 3.02527 12.6745 4.59994 13.8685C5.55066 14.5822 6.59342 15.1641 7.69994 15.5985C7.77549 15.627 7.85149 15.6541 7.92794 15.6799L8.0026 15.7065L8.0786 15.6799C8.22437 15.6292 8.36886 15.575 8.51194 15.5172C9.54243 15.0927 10.5147 14.5386 11.4053 13.8685C12.9806 12.6745 14.6693 10.7512 14.6693 7.99988V2.62655ZM7.3366 10.2765L4.50927 7.44788L5.45194 6.50455L7.33727 8.39055L11.1086 4.61921L12.0519 5.56188L7.3366 10.2765Z" fill="#ADADAD"/>
                    </svg>
                    <span>Secured payment with</span>
                    </div>
                  </div>
                </div> */}
                {/* <div className="">
                      <div className="bg-white shadow-2xl">
                        <input type="radio" />
                         <img src={constants.DEFAULT_IMAGE + "/cashfree_img.png"} />
                      </div>
                      <div className="bg-white shadow-2xl">
                        <input type="radio" />
                         <img src={constants.DEFAULT_IMAGE + "/airpay_img.png"} />
                      </div>
                </div> */}
              </div>
            </div>
            <div className="col-span-12 lg:col-span-4">
              <div
                className={`w-full px-4 py-4 font-bold text-lg transition-all duration-300 ${
                  isOpen
                    ? "border border-[#06AB9F] bg-white text-[#06AB9F]"
                    : "bg-[#06AB9F] text-white"
                }`}
              >
                <div className="relative w-full flex justify-between items-center">
                  <div className="flex items-center gap-3">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="fill-current transition-transform duration-100"
                    >
                      <path d="M22.3594 6.49163H14.0096C14.2809 6.04758 14.4375 5.52614 14.4375 4.9688C14.4375 3.35339 13.1232 2.03911 11.5078 2.03911C10.5042 2.03911 9.58561 2.5417 9.04308 3.38545L7.21875 5.74336L5.39442 3.38545C4.85194 2.5417 3.93338 2.03906 2.92969 2.03906C1.31428 2.03906 0 3.35334 0 4.96875C0 5.66948 0.247594 6.31336 0.659484 6.81806C0.259406 7.11755 0 7.59516 0 8.13225V20.3198C0 21.2244 0.735984 21.9604 1.64062 21.9604H22.3594C23.264 21.9604 24 21.2244 24 20.3198V8.13225C24 7.22756 23.264 6.49163 22.3594 6.49163ZM22.5938 20.3198C22.5938 20.449 22.4886 20.5541 22.3594 20.5541H7.92188V8.96569L9.34223 10.9358C9.47967 11.1265 9.69483 11.2278 9.91317 11.2278C10.0556 11.2278 10.1994 11.1847 10.3238 11.095C10.6388 10.8679 10.71 10.4285 10.4829 10.1135L8.88586 7.8983L22.3594 7.89788C22.4886 7.89788 22.5938 8.00302 22.5938 8.13225V20.3198ZM2.92969 3.44531C3.45802 3.44531 3.94116 3.71353 4.22217 4.16273C4.23455 4.18252 4.24791 4.20169 4.26216 4.22011L6.01997 6.492L2.95355 6.49158C2.95148 6.49158 2.94942 6.49158 2.94736 6.49158C2.93925 6.49163 2.93114 6.49186 2.92219 6.49214C2.08561 6.48811 1.40625 5.80631 1.40625 4.96875C1.40625 4.12875 2.08969 3.44531 2.92969 3.44531ZM10.2153 4.16273C10.4963 3.71353 10.9795 3.44531 11.5078 3.44531C12.3478 3.44531 13.0312 4.12875 13.0312 4.96875C13.0312 5.80636 12.3518 6.4883 11.5151 6.49219C11.5074 6.49195 11.4998 6.49177 11.4922 6.49167C11.4894 6.49167 11.4867 6.49163 11.484 6.49163L8.41753 6.49205L10.1753 4.22011C10.1896 4.20164 10.203 4.18252 10.2153 4.16273ZM1.40625 20.3198V8.13225C1.40625 8.00302 1.51139 7.89788 1.64062 7.89788L5.60967 7.89825L4.2195 9.93488C4.00059 10.2556 4.08309 10.6931 4.40386 10.912C4.72462 11.1309 5.16206 11.0484 5.38102 10.7277L6.51562 9.06539V20.5541H1.64062C1.51139 20.5541 1.40625 20.4489 1.40625 20.3198Z" />
                      <path d="M12.9375 12.1172H16.4531C16.8414 12.1172 17.1562 11.8024 17.1562 11.4141C17.1562 11.0257 16.8414 10.7109 16.4531 10.7109H12.9375C12.5492 10.7109 12.2344 11.0257 12.2344 11.4141C12.2344 11.8024 12.5492 12.1172 12.9375 12.1172Z" />
                      <path d="M19.2656 13.5234H12.9375C12.5492 13.5234 12.2344 13.8382 12.2344 14.2266C12.2344 14.6149 12.5492 14.9297 12.9375 14.9297H19.2656C19.6539 14.9297 19.9688 14.6149 19.9688 14.2266C19.9688 13.8382 19.6539 13.5234 19.2656 13.5234Z" />
                      <path d="M19.2656 16.3359H12.9375C12.5492 16.3359 12.2344 16.6507 12.2344 17.0391C12.2344 17.4274 12.5492 17.7422 12.9375 17.7422H19.2656C19.6539 17.7422 19.9688 17.4274 19.9688 17.0391C19.9688 16.6507 19.6539 16.3359 19.2656 16.3359Z" />
                    </svg>
                    <p className="font-roboto font-medium text-sm">
                      Apply Coupon
                    </p>
                  </div>
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="p-2 transition-transform duration-300 flex justify-between items-center"
                  >
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`fill-current transition-transform duration-100 ${
                        isOpen ? "rotate-180" : ""
                      }`}
                    >
                      <path d="M14.3154 6.87727L13.3665 5.86865L8.49964 11.0419L3.63274 5.86865L2.68385 6.87726L8.49964 13.0265L14.3154 6.87727Z" />
                    </svg>
                  </button>
                </div>
                {isOpen && (
                  <div className="mt-2 flex items-center relative">
                    <input
                      type="text"
                      placeholder="Enter Promo Code"
                      className="w-full p-4 border text-[#787878] border-[#F0F0F0] font-roboto font-normal text-xs focus:outline-none"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                    <button
                      className="bg-[#06AB9F] text-white text-xs font-roboto font-normal absolute right-0 py-4 px-7"
                      onClick={handleApplyClick}
                    >
                      Apply
                    </button>
                  </div>
                )}
              </div>
              <div className="px-4 py-4 border border-solid mt-5 pb-10">
                <p className="font-medium text-lg mb-8 mt-5 text-[#222222] font-roboto">
                  Order Summary
                </p>
                <div className="flex justify-between">
                  <div>
                    <p className="mb-5 font-normal text-xs font-roboto text-[#222222]">
                      Subtotal
                    </p>
                    <p className="text-[#27BA4F] mb-5 font-normal font-roboto text-xs">
                      You Saved
                    </p>
                    <p className="mb-5 font-roboto text-xs text-[#222222] font-normal">
                      Delivery Charges
                    </p>
                    <p className="mb-5 font-semibold text-sm font-roboto">
                      Total Cost
                    </p>
                  </div>
                  {voucherPriceResponse === null ? (
                    <div className="text-right">
                      <p className="mb-5 text-xs font-roboto font-normal text-[#222222]">
                        {actualPrice !==
                          cart_items_numberResponseSuccess?.nodes[0]
                            ?.netAmount &&
                          formatCurrencywithamount(
                            actualPrice,
                            selectedcountrySuccess?.currencyAlias,
                            `en-${selectedcountrySuccess?.iso}`
                          )}{" "}
                        {formatCurrencywithamount(
                          cart_items_numberResponseSuccess?.nodes[0]?.netAmount,
                          selectedcountrySuccess?.currencyAlias,
                          `en-${selectedcountrySuccess?.iso}`
                        )}
                      </p>
                      <p className="text-[#27BA4F] mb-5 font-normal text-xs font-roboto">
                        -{" "}
                        {formatCurrencywithamount(
                          actualPrice -
                            cart_items_numberResponseSuccess?.nodes[0]
                              ?.netAmount,
                          selectedcountrySuccess?.currencyAlias,
                          `en-${selectedcountrySuccess?.iso}`
                        )}
                      </p>
                      <p className="mb-5 font-roboto font-normal text-[#222222] text-xs">
                        {formatCurrencywithamount(
                          cart_items_numberResponseSuccess?.nodes[0]
                            ?.shippingCharge,
                          selectedcountrySuccess?.currencyAlias,
                          `en-${selectedcountrySuccess?.iso}`
                        )}
                      </p>
                      <p className="mb-5 font-semibold text-sm font-roboto">
                        {formatCurrencywithamount(
                          cart_items_numberResponseSuccess?.nodes[0]
                            ?.grossAmount,
                          selectedcountrySuccess?.currencyAlias,
                          `en-${selectedcountrySuccess?.iso}`
                        )}
                      </p>
                    </div>
                  ) : (
                    <div className="text-right">
                      <p className="mb-5 text-xs font-roboto font-normal text-[#222222]">
                        {voucherPriceResponse?.gross_amount !==
                          voucherPriceResponse?.net_amount &&
                          formatCurrencywithamount(
                            voucherPriceResponse?.gross_amount,
                            selectedcountrySuccess?.currencyAlias,
                            `en-${selectedcountrySuccess?.iso}`
                          )}{" "}
                        {formatCurrencywithamount(
                          voucherPriceResponse?.net_amount,
                          selectedcountrySuccess?.currencyAlias,
                          `en-${selectedcountrySuccess?.iso}`
                        )}
                      </p>
                      <p className="text-[#27BA4F] mb-5 font-normal text-xs font-roboto">
                        -{" "}
                        {formatCurrencywithamount(
                          voucherPriceResponse?.discount,
                          selectedcountrySuccess?.currencyAlias,
                          `en-${selectedcountrySuccess?.iso}`
                        )}
                      </p>
                      <p className="mb-5 font-roboto font-normal text-[#222222] text-xs">
                        {formatCurrencywithamount(
                          voucherPriceResponse?.shipping_charge,
                          selectedcountrySuccess?.currencyAlias,
                          `en-${selectedcountrySuccess?.iso}`
                        )}
                      </p>
                      <p className="mb-5 font-semibold text-sm font-roboto">
                        {formatCurrencywithamount(
                          voucherPriceResponse?.discounted_price,
                          selectedcountrySuccess?.currencyAlias,
                          `en-${selectedcountrySuccess?.iso}`
                        )}
                      </p>
                    </div>
                  )}
                </div>
                <p className="font-normal text-[#858585] text-xs">
                  International shipping charges will be calculated at the
                  checkout page Checkout Securely
                </p>
              </div>
              {cart_items_numberResponseSuccess_info &&
                cart_items_numberResponseSuccess_info.map((item, index) => (
                  <div
                    key={index}
                    className="px-4 py-4 border border-solid mt-5"
                  >
                    <div className="flex">
                      <div>
                        <img
                          src={getImage(
                            item?.transSkuListByProductSku
                              ?.productListByProductId?.productImagesByProductId
                              .nodes[0].imageUrl
                          )}
                          alt="Ring"
                          className="w-28 mr-3 rounded-lg p-1"
                        />
                      </div>
                      <div>
                        <p className="text-base font-medium mb-1">
                          {
                            item?.transSkuListByProductSku
                              ?.productListByProductId?.productName
                          }
                        </p>
                        <p className="text-gray-500 mb-1">{item?.productSku}</p>
                        <p className="text-gray-500 mb-1"> {formatCurrencywithamount(
                                                          item?.price,
                                                          selectedcountrySuccess.currencyAlias,
                                                          `en-${selectedcountrySuccess.iso}`,
                                                          dailyMetalPriceSuccess?.data
                                                            ?.allMasterCountries?.nodes
                                                        )}</p>
                        <p className="text-gray-500 text-sm">Qty: 1</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="bg-white border border-slate-200 py-8 px-0 shadow-top-only mt-5 sticky bottom-0 w-full z-0">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 lg:col-span-6 mx-auto ">
                <p className="text-lg font-semibold text-gray-800">
                  Total ({cartItemsProductInfo?.length || "0"}Items) :{" "}
                  <span>
                    {formatCurrency(
                      cart_items_numberResponseSuccess?.nodes[0]?.grossAmount
                    )}
                  </span>
                </p>
              </div>
              <div className="col-span-12 lg:col-span-6 mx-auto">
                <Link
                  to=""
                  onClick={handlePayment}
                  disabled={isLoading}
                  className="text-white text-base text-center py-4 shadow-md px-10 bg-customGreen border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 w-[220px]"
                >
                  Proceed to Pay
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-xl w-full md:w-3/4 lg:w-2/6 shadow-lg p-5 md:p-7 relative">
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-4 right-4  hover:text-gray-800 text-2xl text-[#CACACA] w-7 h-7 rounded-2xl leading-1"
            >
              &times;
            </button>
            <div className="grid grid-cols-1 gap-4">
              <div className="text-left">
                <p className="text-[#353535] font-roboto font-medium text-xl">
                  {SelectedOptionEditAddress !== null ? `Edit` : `Add`} Address
                </p>
              </div>
              <form className="space-y-4" onSubmit={addressFormSubmit}>
                {/* Name and Country */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-[#353535] font-roboto mb-2">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      className="w-full p-2 border border-[#CACACA]"
                      onChange={addressFormValueChange}
                      placeholder="Enter your name"
                    />
                    {errors.name && (
                      <p className="text-red-500 text-xs">{errors.name}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#353535] font-roboto mb-2">
                      Contact
                    </label>
                    <input
                      type="text"
                      name="contact"
                      value={formData.contact}
                      className="w-full p-2 border border-gray-300"
                      placeholder="Enter your contact"
                      onChange={addressFormValueChange}
                    />
                    {errors.contact && (
                      <p className="text-red-500 text-xs">{errors.contact}</p>
                    )}
                  </div>
                </div>
                {/* Address */}
                <div>
                  <label className="block text-sm font-medium text-[#353535] font-roboto mb-2">
                    Street Address
                  </label>
                  <textarea
                    className="w-full p-2 border border-gray-300"
                    name="address"
                    rows="3"
                    // value={useraddress[SelectedOptionEditAddress]?.firstname + " "+ useraddress[SelectedOptionEditAddress]?.lastname}
                    placeholder="Address"
                    onChange={addressFormValueChange}
                  >
                    {formData.address}
                  </textarea>
                  {errors.address && (
                    <p className="text-red-500 text-xs">{errors.address}</p>
                  )}
                </div>
                {/* Contact and ZIP Code */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-[#353535] font-roboto mb-2">
                      ZIP Code
                    </label>
                    <input
                      type="text"
                      name="zipCode"
                      value={formData.zipCode}
                      className="w-full p-2 border border-gray-300"
                      placeholder="Enter ZIP Code"
                      onChange={addressFormValueChange}                      
                    />
                    {errors.zipCode && (
                      <p className="text-red-500 text-xs">{errors.zipCode}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#353535] font-roboto mb-2">
                      Country
                    </label>
                    {countryNames.length > 0 ? (
                      <select
                        name="country"
                        className="w-full p-2 border border-[#CACACA]"
                        value={formData.country}
                        onChange={handleCountryChange}
                        
                      >
                        {countryNames.map((countryName, index) => (
                          <option key={index} value={countryName}>
                            {countryName}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        name="country"
                        value={formData.country}
                        className="w-full p-2 border border-[#CACACA]"
                        onChange={addressFormValueChange}
                        placeholder="Country"
                        readOnly
                      />
                    )}
                    {errors.country && (
                      <p className="text-red-500 text-xs">{errors.country}</p>
                    )}
                  </div>
                </div>

                {/* City and State */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-[#353535] font-roboto mb-2">
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      className="w-full p-2 border border-gray-300"
                      onChange={addressFormValueChange}
                      placeholder="Enter your city"
                      readOnly
                    />
                    {errors.city && (
                      <p className="text-red-500 text-xs">{errors.city}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#353535] font-roboto mb-2">
                      State
                    </label>
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      className="w-full p-2 border border-gray-300"
                      onChange={addressFormValueChange}
                      placeholder="Enter your state"
                      readOnly
                    />
                    {errors.state && (
                      <p className="text-red-500 text-xs">{errors.state}</p>
                    )}
                  </div>
                </div>
                {/* Buttons */}
                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    onClick={() => setIsModalOpen(false)}
                    className="px-4 py-3 bg-white border border-[#353535] rounded-md w-3/6 text-[#353535] mt-5"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isAddressLoading} // Disable button if loading
                    className={`px-4 py-3 ${
                      isAddressLoading ? "bg-gray-400" : "bg-[#06AB9F]"
                    } text-[white] rounded-lg w-3/6 mt-5`}
                  >
                    {isAddressLoading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* Modal */}
    </>
  );
}

export default Checkout;
