import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Home from "./screens/Home";
import Cart from "./screens/Cart";
import Checkout from "./screens/Checkout";
import Thanks from "./screens/Thanks";
import PaymentFail from "./screens/PaymentFail";
import Header from "./components/Header";
import ProductDetails from "./screens/ProductDetails";
import ContactUsForm from "./screens/ContactUsForm";
import ProductList from "./screens/ProductList";
import Register from "./screens/Register";
import Account from "./screens/Account";
import Login from "./screens/Login";
import Footer from "./components/Footer";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsofUse from "./screens/TermsofUse";
import SiteMap from "./screens/SiteMap";
import ShippingReturns from "./screens/ShippingReturns";
import Faqs from "./screens/Faqs";
import ProductCare from "./screens/ProductCare";
import Page404 from "./screens/Page404";
import {
  ToastContainer,
  Slide,
  Zoom,
  Bounce,
  CloseButton,
  toast,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "./components/Atoms/Toast";
import { ACTION_TYPE } from "./redux/action/type";
import Careers from "./screens/Careers";
import AboutStylori from "./screens/AboutStylori";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import constants from "./utilities/constants";
import StyloriBlog from "./screens/StyloriBlog";
import { voucherreducer } from "./redux/reducer/cartReducer";

function App() {
  const { loginResponse, error, authModalType } = useSelector(
    (state) => state.authReducer
  );
  const { voucherResponesLoading, voucherSuccessmessage, voucherPriceResponse, voucherResponseError } = useSelector(
    (state) => state.voucherreducer
  );
  const { forgotpassword_responseSuccess, forgotpassword_responseError } =
    useSelector((state) => state.forgotpasswordreducer);
  const { resetpassword_responseSuccess, resetpassword_responseError } =
    useSelector((state) => state.resetpasswordreducer);

  const dispatch = useDispatch();
  useEffect(() => {
    if (loginResponse === "success") {
      Toast("Login successful!", "success");
      dispatch({ type: ACTION_TYPE.CLEAR_LOGIN_RESPONSE });
    } else if (loginResponse === "failure") {
      Toast(`${error ? error : "Something Went to Wrong"}`, "error");
      dispatch({ type: ACTION_TYPE.CLEAR_LOGIN_RESPONSE });
    }
    if (loginResponse === "signup_success") {
      Toast("Registration successful!", "success");
      dispatch({ type: ACTION_TYPE.CLEAR_LOGIN_RESPONSE });
    } else if (loginResponse === "signup_failure") {
      // dispatch()
      Toast(`${error ? error : "Something Went to Wrong"}`, "error");
      dispatch({ type: ACTION_TYPE.CLEAR_LOGIN_RESPONSE });
    }
  }, [loginResponse]);
  useEffect(() => {
    if (forgotpassword_responseSuccess) {
      Toast(forgotpassword_responseSuccess, "success");
      dispatch({ type: ACTION_TYPE.CLEAR_FORGOTPASSWORD_RESPONSE });
    } else if (forgotpassword_responseError) {
      Toast(forgotpassword_responseError, "error");
      dispatch({ type: ACTION_TYPE.CLEAR_FORGOTPASSWORD_RESPONSE });
    } else if (resetpassword_responseSuccess) {
      // Toast(resetpassword_responseSuccess, "success");
      dispatch({ type: ACTION_TYPE.CLEAR_RESETPASSWORD_RESPONSE });
    } else if (resetpassword_responseError) {
      // Toast(resetpassword_responseError, "error");
      dispatch({ type: ACTION_TYPE.CLEAR_RESETPASSWORD_RESPONSE });
    }
  }, [
    forgotpassword_responseSuccess,
    forgotpassword_responseError,
    resetpassword_responseSuccess,
    resetpassword_responseError,
  ]);
  useEffect(() => {
    if (voucherSuccessmessage) {
      if (voucherSuccessmessage === 'Please Enter Valid Coupon') { Toast(voucherSuccessmessage, "error"); }
      else {
        Toast(voucherSuccessmessage, "success");
      }
      dispatch({ type: ACTION_TYPE.CLEAR_VOUCHER_SUCCESS });
    } else if (voucherResponseError) {
      Toast(voucherResponseError, "error");
      dispatch({ type: ACTION_TYPE.CLEAR_VOUCHER_SUCCESS });
    }
  }, [
    voucherSuccessmessage, voucherResponseError
  ]);
  // console.log("loginResponse_data", loginResponse);

  return (
    <>
      <Router>
        <Header />

        <div>
          <ToastContainer
            limit={1}
            toastClassName="w-[335px]  "
          // position="top-right"
          // autoClose={3000}
          // hideProgressBar
          // transition={Bounce} // 🔄 Use Slide animation
          // closeOnClick
          // pauseOnHover
          // draggable={false}
          // theme="colored"
          />

          <Routes>
            <Route
              path="/"
              element={<Navigate replace to={constants.ADDITIONAL_PATH} />}
            />
            <Route path={constants.ADDITIONAL_PATH} replace element={<Home />} />
            <Route
              path={constants.ADDITIONAL_PATH + "/contactus"}
              element={<ContactUsForm />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/cart"}
              element={<Cart />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/checkout"}
              element={<Checkout />}
            />
            {/* /account-wishlist */}
            <Route
              path={constants.ADDITIONAL_PATH + "/account/:title?"}
              element={<Account />}
            />
            {/* /paymentsuccess/be383350-9a9a-11ef-b160-8b3d5475222d */}

            <Route
              path={constants.ADDITIONAL_PATH + "/paymentsuccess/:orderId"}
              element={<Thanks />}
            />
            {/* product description */}
            <Route
              path={constants.ADDITIONAL_PATH + "/jewellery/:category/:product"}
              element={<ProductDetails />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/careers"}
              element={<Careers />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/account-profile"}
              element={<Account />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/resetpassword/:token"}
              element={<Account />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/account-wishlist"}
              element={<Account />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/account-addresses"}
              element={<Account />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/account-allorders"}
              element={<Account />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/account-vieworders"}
              element={<Account />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/account-redeempoints"}
              element={<Account />}
            />

            {/* product list */}
            <Route
              path={constants.ADDITIONAL_PATH + "/:category"}
              element={<ProductList />}
            />
            {/* Login */}
            <Route
              path={constants.ADDITIONAL_PATH + "/login"}
              element={<Login />}
            />
            {/* register */}
            <Route
              path={constants.ADDITIONAL_PATH + "/registers"}
              element={<Register />}
            />
            {/* /paymentfail/ff46ada0-9d19-11ef-9238-3b9903186ac9       */}
            <Route
              path={constants.ADDITIONAL_PATH + "/paymentfail/:orderid"}
              element={<PaymentFail />}
            />

            {/* static pages */}
            <Route
              path={constants.ADDITIONAL_PATH + "/privacyPolicy"}
              element={<PrivacyPolicy />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/termsconditions"}
              element={<TermsofUse />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/aboutus"}
              element={<AboutStylori />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/sitemap"}
              element={<SiteMap />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/deliveryreturns"}
              element={<ShippingReturns />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/faqs"}
              element={<Faqs />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/productcare"}
              element={<ProductCare />}
            />
            <Route path="" element={<Page404 />} />
            <Route path="*" element={<Page404 />} />
            <Route element={<Page404 />} />
            {/* styloriBlog */}
            <Route
              path={constants.ADDITIONAL_PATH + "/styloriBlog/:title?"}
              element={<StyloriBlog />}
            />
            <Route
              path={constants.ADDITIONAL_PATH + "/Blog/:title?"}
              element={<StyloriBlog />}
            />
          </Routes>
        </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;
