import React from "react";

import { accountMenu } from "../utilities/helper";
import constants from "../utilities/constants";
import { useDispatch, useSelector } from "react-redux";

function Accountpagesidemenu({ handleClick, activeSidebar, isLoggedIn }) {
  const {
    //persionalinformation
    persionalInformationDataLoading,
    persionalInformationDataSuccess,
    persionalInformationDataError,
    //address
    persionalAddressDataLoading,
    persionalAddressDataSuccess,
    persionalAddressDataError,
    //wishlist
    wishListDataLoading,
    wishListDataSuccess,
    wishListDataError,
    //all orders
    allOrdersDataLoading,
    allOrdersDataSuccess,
    allOrdersDataError,
    //single order
    singleOrderDataLoading,
    singleOrderDataSuccess,
    singleOrderDataError,
  } = useSelector((state) => state.myAccountReducer);
  console.log(wishListDataSuccess,"wishListDataSuccess in nav bar")
  return (
    <main className="lg:w-[230px] w-full">
      {/* <section> */}
        {accountMenu?.map((item) => (
          <ul key={item.key}>
            {item.title === 'Logout' ? (
              isLoggedIn ? (
                <li
                  className={`font-medium p-2 mb-1 text-[#F44444] text-lg py-4 cursor-pointer hover:text-customGreen hover:bg-[#EEF9F8]`}
                  onClick={() => handleClick(item)}
                >
                  <span>{item.title}</span>
                </li>
              ) : null
            ) : (
              <li
                className={`font-medium p-2 mb-1 ${
                  item.title === activeSidebar.title ||
                  item?.subtitle === activeSidebar.title
                    ? `text-customGreen bg-[#EEF9F8]`
                    : "text-[#262626]"
                } text-lg py-2 px-3 cursor-pointer hover:text-customGreen hover:bg-[#EEF9F8]`}
                onClick={() => handleClick(item)}
              >
                <span>{item.title}</span>
                {item.title === constants.Wishlist && wishListDataSuccess?.length > 0 && (
                  <span className="ps-2">({wishListDataSuccess?.length})</span>
                )}
              </li>
            )}
          </ul>
        ))}
      {/* </section> */}
    </main>
  );
}

export default Accountpagesidemenu;
