import React, { useState } from "react";

const Switch = ({ label, value, onToggle, name }) => {
  return (
    <section className="flex flex-row justify-start items-center mb-8">
      <span className="text-base font-medium text-[#353535]">
        <label>{label}</label>
      </span>
      <button
        type="button"
        className={`relative inline-flex items-center h-5 w-10 rounded-full transition-colors mx-2 ${
          value ? "bg-blue-500" : "bg-gray-300"
        }`}
        onClick={onToggle}
        name={name}
      >
        <span
          className={`transform transition-transform inline-block h-4 w-4 rounded-full bg-white ${
            value ? "translate-x-5" : "translate-x-1"
          }`}
        />
      </button>
    </section>
  );
};
export default Switch;
