import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import constants from "../../utilities/constants";

const Datepic = ({
  label,
  placeholder = "test",
  value,
  onChange,
  keyvalue,
  name,
  error,
  errorMsg,
}) => {
  const datepickerRef = useRef(null);

  return (
    <section key={keyvalue}>
      <div className="text-base flex flex-row items-center justify-between font-medium text-[#353535]">
        <label>{label}</label>
        
      </div>

      <div
        className={`relative mt-4 mb-1 border ${
          error ? "border-[#E43138]" : "border-[#CACACA]"
        }`}
      >
        <DatePicker
          selected={value}
          onChange={onChange}
          className="block w-full px-4 py-2 focus:outline-none"
          placeholderText="Select a date"
          dateFormat="dd MMM yyyy"
          shouldCloseOnSelect={true}
          name={name}
          ref={datepickerRef} // Attach ref to DatePicker
        />
        <img
          src={constants.DEFAULT_IMAGE + "/date.png"} // Ensure this path is correct
          alt="Calendar Icon"
          className="absolute top-1/2 right-4 w-5 h-5 transform -translate-y-1/2 cursor-pointer"
          onClick={() => {
            if (datepickerRef.current) {
              datepickerRef.current.setOpen(true); // This will open the date picker
            }
          }}
        />
      </div>
      {error && <p className="text-[#E43138] text-sm">{errorMsg}</p>}
    </section>
  );
};

export default Datepic;
