import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  contactus_form_submission,
  resetContactUsStatus,
} from "../redux/action/homeAction";
import config from "../utilities/config";
import { Toast } from "../../src/components/Atoms/Toast";
import constants from "../utilities/constants";

function ContactUsForm() {
  const dispatch = useDispatch();
  const { contactusLoding, contactusSuccess, contactusFail } = useSelector(
    (state) => state.homeReducer
  );
  config.logGeneral && console.log(contactusSuccess, "contactusSuccess");
  config.logGeneral && console.log(contactusFail, "contactusFail");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  // State for validation error messages
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
    formError: "",
  });

  // State for showing success or failure message
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(""); // 'success' or 'error'

  // Handle input change

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value;

    if (trimmedValue !== "") {
      setFormData({ ...formData, [name]: trimmedValue });
    } else {
      setFormData({ ...formData, [name]: "" });
    }
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;
    let newErrors = {
      name: "",
      email: "",
      message: "",
      formError: "",
      phone: "",
    };

    // Validate Name
    if (formData.name.length > 100) {
      newErrors.name = "Name should not exceed 100 characters.";
      isValid = false;
    }
    if (parseInt(formData.phone) === 0) {
      newErrors.phone = "Phone Number should be valid";
      isValid = false;
    }

    // Validate Email
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
      isValid = false;
    }

    // Validate Message
    if (formData.message.length > 255) {
      newErrors.message = "Message should not exceed 255 characters.";
      isValid = false;
    }

    // Update the error state immediately
    setErrors(newErrors);

    if (isValid) {
      setIsSubmitting(true);

      try {
        await dispatch(contactus_form_submission(formData));

        // No need to set message here since it will be handled by the useEffect based on Redux state
      } catch (error) {
        // Handle network or any other errors that occur during the API call
        setErrors({
          ...newErrors,
          formError: "An error occurred. Please try again later.",
        });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      // Set the overall form error message if validation fails
      setErrors({ ...newErrors, formError: "Please fix the errors above." });
    }
  };

  // Effect to handle success or failure messages
  useEffect(() => {
    if (contactusSuccess) {
      setMessage("Thanks for contacting us. Our team will reach out to you.");
      setMessageType("success");
      Toast(
        "Thanks for contacting us. Our team will reach out to you",
        "success"
      );
    }

    if (contactusFail) {
      setMessage(
        "There was an issue with your submission. Please try again later."
      );
      setMessageType("error");
    }

    // Reset message and state after 5 seconds
    if (contactusSuccess || contactusFail) {
      const timer = setTimeout(() => {
        setMessage(""); // Clear the message
        setMessageType(""); // Clear message type
        dispatch(resetContactUsStatus()); // Reset the status in Redux
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      }, 5000);

      // Cleanup timer on unmount or status change
      return () => clearTimeout(timer);
    }
  }, [contactusSuccess, contactusFail, dispatch]);

  return (
    <>
      <div>
        <img
          className="w-full h-[446px] object-cover"
          src={constants.DEFAULT_IMAGE + "/contact.jpg"}
          alt="Contact Banner"
        />
      </div>
      <div className="bg-[#877359] text-white flex flex-col items-center p-2 py-3">
        <h3 className="font-Cormorant font-bold text-[44px]">
          We’d Love to Hear from You!
        </h3>
        <p className="font-century font-medium text-lg">
          At Stylori, your satisfaction means the world to us. Whether you have
          a question about our products, need assistance with your order, or
          want to share your feedback, we’re here to help.
        </p>
      </div>
      <div className="w-full max-w-4xl container bg-white p-8 rounded-lg mx-auto">
        <h2 className="text-[40px] font-bold text-[#262626] lg:text-left text-center font-Cormorant mb-6">
          Contact us
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-5">
            {/* Name Field */}
            <div className="mb-4 col-span-1">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-[#262626] uppercase "
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your Name"
                onBlur={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value.trim(),
                  })
                }
                className="mt-1 block w-full border border-gray-300 p-2"
                maxLength="100"
                required
                pattern=".{1,100}"
                title="Name should not exceed 100 characters."
              />
              {/* {errors.name && <div className="text-red-500 text-sm">{errors.name}</div>} */}
            </div>

            {/* Email Field */}
            <div className="mb-4 col-span-1">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-[#262626] uppercase"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your Email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 p-2"
                required
                pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$"
                title="Please enter a valid email address."
              />
              {/* {errors.email && <div className="text-red-500 text-sm">{errors.email}</div>} */}
            </div>
          </div>
          <div className="grid grid-cols-2">
            {/* Phone Number Field */}
            <div className="mb-4 col-span-2">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-[#262626] uppercase"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Enter your Number"
                value={formData.phone}
                onChange={handleChange}
                onBlur={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value.trim(),
                  })
                }
                className="mt-1 block w-full border border-gray-300 p-2"
                pattern="^(?!0+$).+$"
                required
                title="Phone Number should be valid "
              />
              {/* {errors.phone && <div className="text-red-500 text-sm">{errors.phone}</div>} */}
            </div>
          </div>
          <div className="grid grid-cols-2">
            {/* Message Field */}
            <div className="mb-4 col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-[#262626] uppercase "
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Enter your Message"
                value={formData.message}
                onChange={handleChange}
                onBlur={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value.trim(),
                  })
                }
                className="mt-1 block w-full border border-gray-300 p-2"
                maxLength="255"
                rows="4"
                required                
                title="Message should not exceed 1000 characters."
              />
              {/* {errors.message && <div className="text-red-500 text-sm">{errors.message}</div>} */}
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3 items-center">
            {/* Success or Failure Message */}
            {message && (
              <div
                className={`text-sm p-3 rounded-md col-span-2 ${
                  messageType === "success"
                    ? "bg-green-200 text-green-800"
                    : "bg-[#FFF6F6] text-[#7E0000]"
                }`}
              >
                {message}
              </div>
            )}
            
            {/* Empty div to maintain grid alignment when there's no message */}
            {!message && <div className="col-span-2"></div>}

            {/* Submit Button */}
            <div className="col-span-1 flex justify-end">
              <button
                type="submit"
                className="bg-customGreen text-white py-2 px-10 w-full"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Send"}
              </button>
            </div>
          </div>

          {/* Form Error (General) */}
          {/* {errors.formError && <div className="text-red-500 text-sm">{errors.formError}</div>} */}
        </form>

        
      </div>

      <div className="container mx-auto lg:pb-14 lg:p-0 p-5">
        <div className="lg:grid lg:grid-cols-3 gap-5">
          <div className="col-span-1 bg-gradient-to-b from-[#CEFFFB] via-[#D8FFEB] to-[#FFFFFF] shadow-md p-5 mb-5 rounded-lg">
            <h3 className="font-semibold text-2xl text-[#262626] tracking-tight pb-4">
              Office Address
            </h3>
            <p className="text-[#262626] font-medium text-lg">
              Old No.26/New No.45, KB Dasan Road,Teynampet, <br />
              Chennai-600018.
            </p>
          </div>
          <div className="col-span-1 bg-gradient-to-b from-[#CEFFFB] via-[#D8FFEB] to-[#FFFFFF] shadow-md p-5 mb-5 rounded-lg">
            <h3 className="font-semibold text-2xl text-[#262626] tracking-tight pb-4">
              We're Here To Help
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-1 flex items-center gap-1">
                <img src={constants.DEFAULT_IMAGE + "/call.svg"} />
                <span
                  className="text-[#262626] font-medioum text-lg cursor-pointer"
                  onClick={() => (window.location.href = "tel:18001020330")}
                  target="_blank"
                >
                  1800 102 0330
                </span>
              </div>
              <div className="col-span-1 flex items-center gap-1">
                <img src={constants.DEFAULT_IMAGE + "/email.svg"} />
                <span
                  className="text-[#262626] font-medioum text-lg cursor-pointer"
                  onClick={() =>
                    (window.location.href = "mailto: hello@stylori.com")
                  }
                  target="_blank"
                >
                  hello@stylori.com
                </span>
              </div>
              <div className="col-span-1 flex items-center gap-1">
                <img src={constants.DEFAULT_IMAGE + "/whatsapp.svg"} />
                <span
                  className="text-[#262626] font-medioum text-lg cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      "https://wa.me/919952625252?text=Hi - ${window.location.href}")
                  }
                  target="_blank"
                >
                  +91 99526 25252
                </span>
              </div>
              <div className="col-span-1 flex items-center gap-1">
                <img src={constants.DEFAULT_IMAGE + "/message.svg"} />
                <span className="text-[#262626] font-medioum text-lg cursor-pointer">
                  Start live chat
                </span>
              </div>
            </div>
          </div>
          <div className="col-span-1 bg-gradient-to-b from-[#CEFFFB] via-[#D8FFEB] to-[#FFFFFF] shadow-md p-5 mb-5 rounded-lg">
            <h3 className="font-semibold text-2xl text-[#262626] tracking-tight pb-4">
              Customer Care
            </h3>
            <p className="text-[#262626] font-medium text-lg">
              Monday to Saturday &nbsp;{" "}
              <span className="bg-customGreen rounded-full text-white px-3 py-2">
                10 AM
              </span>{" "}
              -{" "}
              <span className="bg-customGreen rounded-full text-white px-3 py-2">
                07 PM
              </span>
              &nbsp; IST
            </p>
          </div>
        </div>

        <p className="text-center text-lg font-medium text-[#262626] pt-10">
          Chat and Email support is available Monday through Saturday 10am to
          7pm IST and we make a serious effort to respond in less than an hour.
          We do offer phone support, but if you really want to get us on the
          phone on different time zones, just submit the form and we'll schedule
          a call.
        </p>
      </div>
    </>
  );
}

export default ContactUsForm;
