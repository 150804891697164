import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  product_list_filter,
  product_list_item,
} from "../redux/action/productAction";
//searchquerymenu
import { searchquerymenu } from "../redux/action/homeAction";
import config from "../utilities/config";
import constants from "../utilities/constants";
import { formatCurrency, getValue } from "../utilities/helper";
import Config from "../utilities/config";
import { Link, useNavigate, useParams } from "react-router-dom";

const Header = ({ wishlist, cart }) => {
  const dispatch = useDispatch();
  const {
    searchMenuResponseLoading,
    searchMenuResponseSuccess,
    searchMenuResponseError,
  } = useSelector((state) => state.homeReducer);
  const {
      //persionalinformation
      persionalInformationDataLoading,
      persionalInformationDataSuccess,
      persionalInformationDataError,
      //address
      persionalAddressDataLoading,
      persionalAddressDataSuccess,
      persionalAddressDataError,
      //wishlist
      wishListDataLoading,
      wishListDataSuccess,
      wishListDataError,
      //all orders
      allOrdersDataLoading,
      allOrdersDataSuccess,
      allOrdersDataError,
      //single order
      singleOrderDataLoading,
      singleOrderDataSuccess,
      singleOrderDataError,
    } = useSelector((state) => state.myAccountReducer);
    console.log(wishListDataSuccess,"wishListDataSuccessbbbbbsearch")
    const {
      cart_items_numberResponesLoading,
      cart_items_numberResponseSuccess,
      cart_items_numberResponseSuccess_info,
      cart_items_numberResponseError,
    } = useSelector((state) => state.cartNumberReducer);
    console.log(cart_items_numberResponesLoading,"cart_items_numberResponesLoadingsearch",cart_items_numberResponseSuccess,"cart_items_numberResponseSuccess",cart_items_numberResponseSuccess_info,"cart_items_numberResponseSuccess_info")
  const navigation = useNavigate();
  const { productListResponseSuccess, productListResponseError } = useSelector(
    (state) => state.productFilterListReducer
  );
  console.log(productListResponseSuccess, "productListResponseSuccess");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // Handle search query change
  const handleSearchChange = (e) => {
    const query = e.target.value;
    if (query !== "") {
      setSearchQuery(`${query}`);
    } else {
      setSearchQuery("");
    }
  };
  const getImage = (image, size = "300X300") => {
    let productId = image.split("/");
    return `${constants.image_url}${productId?.[0]}/${productId?.[1]}/${size}/${productId?.[2]}`;
  };
  console.log(searchMenuResponseSuccess, "searchMenuResponseSuccess");
  // useEffect(() => {
  //   if (searchMenuResponseSuccess) {
  //     setSearchResults(searchMenuResponseSuccess);
  //   }
  // }, [searchMenuResponseSuccess]); // Only run this effect when searchMenuResponseSuccess changes

  useEffect(() => {
    if (searchQuery) {
      dispatch(searchquerymenu(searchQuery)); // Dispatch the action only when searchQuery changes
      dispatch(product_list_filter());
    }
  }, [searchQuery, dispatch]);

  const [hovered, setHovered] = useState(false);

  return (
    <div className="flex text-[#1C1C1C] items-center space-x-4">
      {/* Search Section */}
      <div className=" flex items-center cursor-pointer">
        {/* Search Icon */}
        {!isSearchOpen && (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition-transform duration-300"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => setIsSearchOpen(true)}
            style={{ transition: "fill 0.3s ease-in-out" }}
          >
            <mask
              id="mask0_3107_3075"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="6"
              width="21"
              height="21"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.9971 25.0144C25.9684 24.5833 25.754 24.1832 25.4092 23.9202L20.5064 20.1712C20.2897 20.0055 20.2455 19.7004 20.4047 19.4794C21.4149 18.0801 22.0117 16.3626 22.0117 14.5058C22.0117 9.81083 18.2009 6 13.5058 6C8.81083 6 5 9.81083 5 14.5058C5 19.2009 8.81083 23.0117 13.5058 23.0117C15.3626 23.0117 17.0802 22.4149 18.4794 21.4047C18.7004 21.2455 19.0055 21.2897 19.1712 21.5063L22.9202 26.4092C23.1832 26.754 23.5833 26.9684 24.0144 26.9971C24.4454 27.0237 24.8698 26.8645 25.1749 26.5595L25.5595 26.1749C25.8645 25.8698 26.0237 25.4454 25.9971 25.0144ZM13.5058 21.0378C9.90058 21.0378 6.97394 18.1111 6.97394 14.5058C6.97394 10.9006 9.90058 7.97392 13.5058 7.97392C17.1111 7.97392 20.0378 10.9006 20.0378 14.5058C20.0378 18.1111 17.1111 21.0378 13.5058 21.0378Z"
                fill={hovered ? "#06ab9f" : "black"} // Changes color on hover
                style={{ transition: "fill 0.3s ease-in-out" }}
              />
            </mask>
            <g mask="url(#mask0_3107_3075)">
              <circle cx="14.5" cy="38.5" r="14.5" fill="#15AB9E" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.9971 25.0144C25.9684 24.5833 25.754 24.1832 25.4092 23.9202L20.5064 20.1712C20.2897 20.0055 20.2455 19.7004 20.4047 19.4794C21.4149 18.0801 22.0117 16.3626 22.0117 14.5058C22.0117 9.81083 18.2009 6 13.5058 6C8.81083 6 5 9.81083 5 14.5058C5 19.2009 8.81083 23.0117 13.5058 23.0117C15.3626 23.0117 17.0802 22.4149 18.4794 21.4047C18.7004 21.2455 19.0055 21.2897 19.1712 21.5063L22.9202 26.4092C23.1832 26.754 23.5833 26.9684 24.0144 26.9971C24.4454 27.0237 24.8698 26.8645 25.1749 26.5595L25.5595 26.1749C25.8645 25.8698 26.0237 25.4454 25.9971 25.0144ZM13.5058 21.0378C9.90058 21.0378 6.97394 18.1111 6.97394 14.5058C6.97394 10.9006 9.90058 7.97392 13.5058 7.97392C17.1111 7.97392 20.0378 10.9006 20.0378 14.5058C20.0378 18.1111 17.1111 21.0378 13.5058 21.0378Z"
                fill={hovered ? "#06ab9f" : "black"} // Changes color on hover
                style={{ transition: "fill 0.3s ease-in-out" }}
              />
            </g>
          </svg>
        )}

        {/* Search Bar */}
        {isSearchOpen && (
          <div className="fixed inset-0 flex items-start justify-center z-50 bg-black bg-opacity-50">
            <div className="absolute top-20 bottom-0 left-1/4 right-1/2 m-auto w-1/2">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                onBlur={(e) => setSearchQuery(`${e.target.value.trim()}`)}
                placeholder="Search..."
                className="w-full pl-12 pr-12 py-3 rounded-md focus:outline-none bg-[#F5F5F5] text-[#242424]"
                autoFocus
              />
              {/* Search Icon Inside the Input Field */}
              <img
                src={constants.DEFAULT_IMAGE + "/searchicon.svg"}
                alt="Search"
                className="absolute left-3 top-6 transform -translate-y-1/2"
              />
              {/* Close Button */}
              <button
                onClick={() => {
                  setIsSearchOpen(false);
                  setSearchQuery("");
                }}
                className="absolute top-6 right-3 transform -translate-y-1/2"
              >
                <img src={constants.DEFAULT_IMAGE + "/close.svg"} alt="Close" />
              </button>

              {/* Search Results Box */}
              {searchQuery && (
                <div className="">
                  <div className="absolute left-0 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
                    {
                      <div className="bg-white w-11/12 md:w-full p-6 rounded-md shadow-lg">
                        <h2 className="text-base font-semibold mb-3 uppercase text-[#242424]">
                          Suggestions
                        </h2>
                        <hr className="border-[#06ab9f] border"></hr>
                        <div className="flex flex-wrap gap-3 pt-5 pb-3">
                          {productListResponseSuccess &&
                            productListResponseSuccess["Product Type"]?.map(
                              (list, i) => (
                                <div
                                  key={i}
                                  className="flex border w-auto rounded-full py-1 px-2"
                                  onClick={() => {
                                    if (list.name === "necklace") {
                                      window.location.href =
                                        constants.ADDITIONAL_PATH +
                                        `/silver-necklaces-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0`;
                                    } else if (list.name === "nosepins") {
                                      window.location.href =
                                        constants.ADDITIONAL_PATH +
                                        `/nose+pin+online-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0`;
                                    } else {
                                      let productlistname =
                                        list.name.split(" ").length > 1
                                          ? list.name
                                              .toLowerCase()
                                              .split(" ")
                                              .join("+")
                                          : list.name.toLowerCase();
                                      window.location.href =
                                        constants.ADDITIONAL_PATH +
                                        `/${productlistname}-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0`;
                                    }
                                  }}
                                >
                                  <img
                                    src={
                                      constants.DEFAULT_IMAGE + "/sparkling.svg"
                                    }
                                    className="pr-1"
                                  />
                                  <p className="text-base font-medium text-[#262626]">
                                    {list.name}
                                  </p>
                                </div>
                              )
                            )}
                        </div>
                        <h2 className="text-base text-[#242424] font-semibold mb-3 uppercase pt-5">
                          Products
                        </h2>
                        <hr className="border-[#06ab9f] border"></hr>
                        {searchMenuResponseSuccess?.length > 0 ? (
                          <div className="grid grid-cols-4 gap-3 pt-5">
                            {searchMenuResponseSuccess.map((item) => (
                              <div
                                key={item.skuId}
                                className="flex col-span-1 items-center gap-2"
                                onClick={() => {
                                  window.location.href = item?.skuUrl || "/";
                                }}
                              >
                                <img
                                  src={getImage(
                                    item.productListByProductId
                                      .productImagesByProductId.nodes[0]
                                      .imageUrl
                                  )}
                                  alt={item.productListByProductId.productName}
                                  className="size-16"
                                />
                                <div>
                                  <h3 className="text-[#242424] text-sm font-semibold pb-1">
                                    {item.productListByProductId.productName}
                                  </h3>
                                  <p className="text-[#6A6A6A] text-xs font-medium">
                                    {formatCurrency(item?.markupPrice)}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          "No products found"
                        )}
                      </div>
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Other Header Elements */}
      <Link to={constants.ADDITIONAL_PATH + "/cart"} className="pr-3 relative">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="group transition-colors duration-300 ease-in-out"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2597 10.75H9.93115C9.01315 10.75 8.25115 11.4595 8.18565 12.3755L7.32865 24.3755C7.29415 24.86 7.46214 25.337 7.79314 25.6925C8.12464 26.048 8.58815 26.25 9.07415 26.25H22.9261C23.4121 26.25 23.8756 26.048 24.2071 25.6925C24.5381 25.337 24.7061 24.86 24.6716 24.3755L23.8146 12.3755C23.7491 11.4595 22.9871 10.75 22.0691 10.75H20.7501V10.5C20.7501 7.8765 18.6236 5.75 16.0001 5.75C13.4726 5.75 11.1366 7.763 11.2501 10.5C11.2536 10.583 11.2567 10.6665 11.2597 10.75ZM20.7501 12.25V16C20.7501 16.414 20.4141 16.75 20.0001 16.75C19.5861 16.75 19.2501 16.414 19.2501 16V12.25H12.7501V16C12.7501 16.414 12.4141 16.75 12.0001 16.75C11.5861 16.75 11.2501 16.414 11.2501 16C11.2501 16 11.3151 14.2475 11.2936 12.25H9.93115C9.80015 12.25 9.69115 12.3515 9.68215 12.482L8.82464 24.482C8.81964 24.5515 8.84365 24.6195 8.89115 24.6705C8.93865 24.721 9.00465 24.75 9.07415 24.75H22.9261C22.9956 24.75 23.0616 24.721 23.1091 24.6705C23.1566 24.6195 23.1807 24.5515 23.1757 24.482L22.3181 12.482C22.3091 12.3515 22.2001 12.25 22.0691 12.25H20.7501ZM19.2501 10.75V10.5C19.2501 8.705 17.7951 7.25 16.0001 7.25C14.2051 7.25 12.7501 8.705 12.7501 10.5V10.75H19.2501Z"
            className="fill-black group-hover:fill-[#06ab9f] transition-colors duration-300 ease-in-out"
          />
        </svg>
        {parseInt(cart) > 0 ? (
          <p className="bg-customGreen rounded-full text-white absolute px-[6px] py-0 h-5 top-0 right-2 text-sm font-medium">
            {cart}
          </p>
        ) : (
          <></>
        )}
      </Link>

      <Link
        to={constants.ADDITIONAL_PATH + "/account"}
        className=" w-[32px] hidden lg:block transition-colors duration-200"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="group transition-colors duration-300 ease-in-out"
        >
          <path
            d="M6.83672 6.71203C6.83672 3.95575 9.09247 1.7 11.8487 1.7C14.605 1.7 16.8608 3.95575 16.8608 6.71203C16.8608 9.46831 14.605 11.7241 11.8487 11.7241C9.09247 11.7241 6.83672 9.46831 6.83672 6.71203Z"
            className="stroke-black stroke-[1.4] group-hover:fill-[#06ab9f] group-hover:stroke-[#06ab9f] transition-colors duration-300 ease-in-out"
          />
          <path
            d="M20.9546 17.1387C20.7782 17.4445 20.5773 17.7348 20.3475 18.0336L20.3431 18.0393L20.3388 18.0451C20.0208 18.4767 19.6508 18.8716 19.26 19.2624C18.9328 19.5895 18.5587 19.9167 18.1878 20.1949C16.3402 21.5744 14.1183 22.3 11.823 22.3C9.53191 22.3 7.31403 21.5772 5.46852 20.2027C5.06826 19.8775 4.70213 19.5786 4.38595 19.2624L4.37844 19.2549L4.37071 19.2476C3.97833 18.8783 3.63206 18.4861 3.30712 18.0451L3.30714 18.0451L3.30355 18.0403C3.10145 17.7709 2.8918 17.4771 2.72124 17.1897C2.85075 16.8996 3.01562 16.5977 3.1944 16.3395L3.19857 16.3334L3.20262 16.3273C4.21371 14.7994 5.83145 13.7885 7.62802 13.5415L7.64911 13.5386L7.66998 13.5344C7.70266 13.5279 7.77742 13.5387 7.83603 13.5826L7.83601 13.5827L7.84033 13.5858C9.01142 14.4502 10.4051 14.895 11.8479 14.895C13.2907 14.895 14.6843 14.4502 15.8554 13.5858L15.8554 13.5859L15.8597 13.5826C15.8804 13.5671 15.9602 13.5337 16.0828 13.5436C17.8689 13.7936 19.4604 14.8007 20.4971 16.3332L20.4971 16.3333L20.5014 16.3395C20.6794 16.5967 20.8341 16.863 20.9546 17.1387Z"
            className="stroke-black stroke-[1.4] group-hover:fill-[#06ab9f] group-hover:stroke-[#06ab9f] transition-colors duration-300 ease-in-out"
          />
        </svg>
      </Link>

      {/* wishlist */}
      <Link
        to={constants.ADDITIONAL_PATH + "/account-wishlist"}
        className="hover:text-yellow-400 pr-3 hidden lg:block transition-colors duration-200 relative"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="group transition-colors duration-300 ease-in-out"
        >
          <mask
            id="mask0_3107_3092"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="2"
            y="3"
            width="21"
            height="18"
          >
            <path
              d="M12.0162 5.79219L12.3698 6.14575L12.7233 5.79219L13.5231 4.99239C13.5232 4.99227 13.5233 4.99215 13.5235 4.99204C14.4827 4.03651 15.7815 3.5 17.1354 3.5C18.4892 3.5 19.7878 4.03636 20.747 4.99165C21.7029 5.95212 22.2395 7.25205 22.2395 8.60712C22.2395 9.96224 21.7029 11.2622 20.747 12.2227C20.7468 12.2229 20.7465 12.2231 20.7463 12.2233L12.5424 20.4272L12.541 20.4287C12.5186 20.4513 12.4919 20.4692 12.4625 20.4815C12.4331 20.4937 12.4016 20.5 12.3698 20.5C12.3379 20.5 12.3064 20.4937 12.277 20.4815C12.2476 20.4692 12.2209 20.4513 12.1985 20.4287L12.1971 20.4272L3.99316 12.2233C3.99302 12.2232 3.99288 12.223 3.99275 12.2229C3.03672 11.2624 2.5 9.96233 2.5 8.60712C2.5 7.25206 3.03661 5.95215 3.99244 4.99167C4.95164 4.03637 6.25027 3.5 7.60406 3.5C8.95799 3.5 10.2568 4.03649 11.216 4.99198C11.2161 4.99211 11.2163 4.99225 11.2164 4.99239L12.0162 5.79219Z"
              fill="white"
              stroke="#1C1C1C"
            />
          </mask>
          <g mask="url(#mask0_3107_3092)">
            <path
              d="M4.4 29.7111C0.733334 30.6619 2.2 24.4816 -2.2 28.7603L-11 53H33L30.8 35.8914C27.8667 32.0881 26.4 23.5307 21.2667 25.9078C19.3796 26.7816 16.8667 36.3668 13.9333 29.7111C8.06667 17.3504 8.06667 28.7603 4.4 29.7111Z"
              className="group-hover:fill-[#06ab9f] group-hover:stroke-[#06ab9f]  transition-colors duration-300 ease-in-out"
            />
            <path
              d="M11.8041 6.00433L12.3698 6.57001L12.9354 6.00433L13.7349 5.20491C13.735 5.20476 13.7351 5.20462 13.7353 5.20447C14.6383 4.30502 15.8609 3.8 17.1354 3.8C18.4096 3.8 19.6319 4.30473 20.5348 5.20369C21.4345 6.10791 21.9395 7.33156 21.9395 8.60712C21.9395 9.883 21.4342 11.1069 20.5342 12.0112C20.534 12.0114 20.5339 12.0115 20.5337 12.0116L12.3698 20.1756L4.20577 12.0116C4.20554 12.0114 4.20532 12.0112 4.2051 12.011C3.3052 11.1067 2.8 9.88288 2.8 8.60712C2.8 7.3316 3.30502 6.10798 4.20461 5.20377C5.10754 4.30476 6.32985 3.8 7.60406 3.8C8.87857 3.8 10.1012 4.305 11.0042 5.20441C11.0043 5.20458 11.0045 5.20474 11.0047 5.20491L11.8041 6.00433Z"
              className="fill-white stroke-[#1C1C1C] stroke-[1.6] group-hover:fill-[#06ab9f] group-hover:stroke-[#06ab9f]  transition-colors duration-300 ease-in-out"
            />
          </g>
        </svg>
        {wishlist && parseInt(wishlist) > 0 ? (
          <p className="bg-customGreen rounded-full text-white absolute px-[6px] py-0 h-5 top-0 right-2 mt-[-6px] mr-[-4px] text-sm font-medium">
            {wishlist}
          </p>
        ) : (
          <></>
        )}
      </Link>
    </div>
  );
};

export default Header;
