import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { careerPageData } from "../redux/action/siteMapAction";
import { careerFooter } from "../utilities/helper";
import { useNavigate } from "react-router-dom";
import { isEmptyArray } from "formik";
import constants from "../utilities/constants";

function Careers() {
  const { careerPageDataLoading, careerPageDataSuccess, careerPageDataError } =
    useSelector((state) => state.siteMapReducer);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [careersDataBanners, setCareersDataBanners] = useState(null);
  const [careersDataHeader, setCareersDataHeader] = useState(null);
  const [careersDataComponent, setCareersDataComponent] = useState(null);

  useEffect(() => {
    dispatch(careerPageData());
  }, []);
  console.log("careerPageDataSuccess_career", careerPageDataSuccess);

  useEffect(() => {
    if (Array.isArray(careerPageDataSuccess)) {
      if (careerPageDataSuccess[0].isActive === true) {
        const data = JSON.parse(careerPageDataSuccess[0]?.data);
        console.log("datacareerPageDataSuccess", data);
        if (data[0].component === "bannerComponent") {
          setCareersDataBanners(data[0]?.props?.banners);
        }
        if (data[1].component === "careerHeader") {
          // console.log("insidedatacomponent", data[1].props);
          if (!isEmptyArray(data[1]?.props)) {
            setCareersDataHeader(data[1]?.props || []);
            // console.log(
            //   "isEmptyArraycareersDataHeader",
            //   careersDataHeader?.map((item) => {
            //     return item.description;
            //   })
            // );
          }
        }
        if (data[2].component === "careersComponent") {
          setCareersDataComponent(data[2]?.props);
        }
      } else {
        Navigate("/404");
      }

      //   console.log("siteMapDataSuccess", data[0].props);
      //   if (data[0]?.props) {
      //     const sortedData = [...data[0].props].sort(
      //       (a, b) => a.categoryList.length - b.categoryList.length
      //     );
      //     setCareersData(sortedData);
      //   }
      // setSiteMapDatas(data[0]?.props);
      // ,
      // siteMapDataSuccess[0]?.data
    }
  }, [careerPageDataSuccess]);
  const stripHtmlTags = (html) => {
    if (!html) return "";
    return html.replace(/<[^>]*>/g, "");
  };
  // console.log(
  //   "careersDataHeader",
  //   careersDataComponent !== null && careersDataComponent
  // );

  // console.log(
  //   "testcareersDataHeader",
  //   !isEmptyArray(careersDataHeader) && careersDataHeader.map((item) =>lo item)
  // );

  const Accordion = ({ children }) => {
    return <div className="">{children}</div>;
  };

  const AccordionItem = ({ header, children }) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
      <div className="">
        <button
          className=" flex flex-row justify-center items-center lg:justify-between w-full"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div>{header}</div>
          <span className="text-xl">
            {isOpen ? (
              <img
                src={constants.DEFAULT_IMAGE + "/accortian_close.png"}
                alt="close icon"
                className="w-7 h-7 max-lg:ms-2"
              />
            ) : (
              <img alt="plus icon" className="w-5 h-5 max-lg:ms-2" src={constants.DEFAULT_IMAGE + "/accortian_open.png"} />
            )}
          </span>
        </button>
        {isOpen && (
          <div className="px-4 font-century font-medium text-sm">
            {children}
          </div>
        )}
      </div>
    );
  };

  return (
    <main>
      <section>
        {careersDataBanners !== null && (
          <div>
            <img
              className="w-full md:block hidden"
              src={careersDataBanners[0]?.web}
              alt="career img"
            />
            <img
              className="w-full block md:hidden"
              src={careersDataBanners[0]?.mobile}
              alt="career img"
            />
          </div>
        )}
        {careersDataHeader !== null && (
          <div className="bg-[#877359] text-white py-2">
            <span className="font-Cormorant font-bold text-[44px] flex flex-col justify-center items-center ">
              {careersDataHeader[0]?.title}
            </span>
            <div className="text-base pb-2 max-lg:p-2  max-md:text-center font-medium font-century container mx-auto text-center">
              {stripHtmlTags(careersDataHeader[0]?.description)}
            </div>
          </div>
        )}
      </section>
      {/* <section className=" container mx-auto py-7">
        {careersDataHeader !== null &&
          Array.isArray(careersDataHeader) &&
          careersDataHeader.slice(1)?.map((item) => (
            <div
              className="custom-content  max-xl:px-3"
              dangerouslySetInnerHTML={{
                __html: item?.description,
              }}
            />
          ))}
      </section> */}

      <section className=" container mx-auto py-7">
        {/* {careersDataHeader !== null && (
          <div
            className="custom-content  max-xl:px-3"
            dangerouslySetInnerHTML={{
              __html: careersDataHeader[0]?.description,
            }}
          />
        )} */}
        {careersDataHeader !== null &&
          Array.isArray(careersDataHeader) &&
          careersDataHeader.slice(1)?.map((item) => (
            <div
              className="custom-content p-career-title max-xl:px-3 "
              dangerouslySetInnerHTML={{
                __html: item?.description,
              }}
            />
          ))}

        <div className="mt-2">
          {careersDataComponent !== null &&
            Array.isArray(careersDataComponent) &&
            careersDataComponent?.map((item) => (
              <div className="max-xl:px-3">
                <Accordion>
                  <AccordionItem header={item?.role}>
                    <div
                      className="custom-content  max-xl:px-3"
                      dangerouslySetInnerHTML={{
                        __html: item?.JobDescription,
                      }}
                    />
                  </AccordionItem>
                </Accordion>

                {/* <span className="text-[#383640] text-[20px] font-bold">
              {careersDataComponent[0]?.role}
            </span> */}

                {/* <div>{stripHtmlTags(careersDataComponent[0]?.JobDescription)}</div> */}
              </div>
            ))}
        </div>
      </section>

      <section className=" container mx-auto py-7">
        <div className=" flex flex-col justify-center  items-center max-md:p-2">
          <span className="font-Cormorant font-bold text-[32px] text-[#000000]">
            {careerFooter.title}
          </span>
          <span className="pt-2 text-[#262626] font-century font-medium text-[16px]">
            {careerFooter.subtitle}
          </span>
        </div>
        <div className="flex flex-row pt-5 flex-wrap justify-center">
          {careerFooter.content.map((item) => (
            <div className=" flex flex-col  m-2 p-8 rounded-lg bg-gradient-to-bl w-[350px] from-[#CEFFFB] via-[#D8FFEB] to-[#FFFFFF]  ">
              <span className="pb-2 font-Cormorant font-bold text-[24px] text-[#000000]">
                {item.content_title}
              </span>
              <span className="font-century font-medium text-[18px] text-[#262626]">
                {item.content_description}
              </span>
            </div>
          ))}
        </div>
        <div></div>
      </section>
    </main>
  );
}

export default Careers;
