import React from "react";

const Button = ({
  btnfrom = null,
  label,
  onClick,
  type = "button",
  variant = "primary",
  modelbtn = true,
  width = null,
  icon = null,
}) => {
  const baseStyles =
    "px-4 py-2 rounded-lg font-medium w-full mx-2";
  const variantStyles =
    variant === "primary"
      ? "bg-[#06AB9F] text-white tracking-wider"
      : "border border-[#C6C6C6] text-[#262626] text-lg font-medium tracking-wide";

  return (
    <>
      {btnfrom == null ? (
        <button
          type={type}
          onClick={() => onClick(label)}
          className={`${
            modelbtn
              ? baseStyles
              : `text-[16px] py-2 ${
                  width === "full"
                    ? "px-14 me-2"
                    : "px-2 border border-[#C6C6C6] "
                }`
          } ${variantStyles}`}
        >
          {label}
        </button>
      ) : btnfrom === "login" ? (
        <button
          type={variant === "primary" ? "button" : "button"}
          onClick={() => onClick(label)}
          className={`w-[378px] md:w-[384px] rounded-[4px]  py-2 ${variantStyles}`}
        >
          {label}
        </button>
      ) : (
        <button
          type={variant === "primary" ? "button" : "button"}
          onClick={() => onClick(label)}
          className={`w-[384px] max-md:mt-2 md:w-[180px] rounded-[4px] px-1 py-2 mx-2 text-xs ${variantStyles}`}
        >
          <div className="flex flex-row items-center justify-center   md:justify-evenly">
            <img src={icon} className="w-8 h-8 " alt="icon img" />
            <span className="max-md:ps-2"> {label}</span>
          </div>
        </button>
      )}
    </>
  );
};

export default Button;
