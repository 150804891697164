import { isEmptyArray } from "formik";
import constants from "./constants";

// http://localhost:3000/styloriSilver/silver-jewellery?Sort=Best%20Seller&startprice=0&endprice=0
// silver-necklaces-jewellery-cubiczircon+swarovski?sort=Best%20Seller&startprice=0&endprice=0

//to get amount in currency format...
export function formatCurrency(amount, currency = "INR", locale = "en-IN") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  }).format(Math.round(parseFloat(amount)));
}

export function urlchange(inputURL) {
  // Check if the input URL contains any of the DOMAIN_URLS
  const SITEPATHS = [
    "https://stylori.com",
    "https://staging-new.stylori.com",
    "https://www.stylori.com",
  ];
  const currentDomain = window.location.hostname;
  const proTocol = window.location.protocol;
  const currentPort = window.location.port;
  const containsDomainURL = SITEPATHS.some((domain) =>
    inputURL.includes(domain)
  );

  if (containsDomainURL) {
    // Extract the path and query parameters from the input URL
    const urlObject = new URL(inputURL);
    const path = urlObject.pathname;
    const queryParams = urlObject.search;

    // Construct the new URL using constants
    if (currentDomain === "localhost") {
      var newURL = `${proTocol}//${currentDomain}:${currentPort}${path}${queryParams}`;
      window.location.href = newURL;
    } else {
      var newURL = `${proTocol}//${currentDomain}${path}${queryParams}`;
      window.location.href = newURL;
    }

    // return newURL;
  } else {
    // If the input URL does not contain any of the DOMAIN_URLS, return the original URL
    return inputURL;
  }
}

export function formatCurrencywithamount(
  amount,
  targetCurrencyAlias = "INR",
  locale = "en-IN",
  currencyList = [
    {
      currency: "India Rupee",
      currencyAlias: "INR",
      currencySymbol: "₹",
      fxConversionRate: 1,
      id: 1,
      isActive: true,
      iso: "IN",
      iso3: "IND",
      name: "INDIA",
      nicename: "India",
      numcode: "356",
      phonecode: "91",
    },
    {
      currency: "Australian Dollar",
      currencyAlias: "AUD",
      currencySymbol: "$",
      fxConversionRate: 53.48,
      id: 13,
      isActive: true,
      iso: "AU",
      iso3: "AUS",
      name: "AUSTRALIA",
      nicename: "Australia",
      numcode: "36",
      phonecode: "61",
    },
    {
      currency: "Malaysian Ringgit",
      currencyAlias: "MYR",
      currencySymbol: "RM",
      fxConversionRate: 17.67,
      id: 129,
      isActive: true,
      iso: "MY",
      iso3: "MYS",
      name: "MALAYSIA",
      nicename: "Malaysia",
      numcode: "458",
      phonecode: "60",
    },
    {
      currency: "Singapore Dollar",
      currencyAlias: "SGD",
      currencySymbol: "$",
      fxConversionRate: 57.18,
      id: 192,
      isActive: true,
      iso: "SG",
      iso3: "SGP",
      name: "SINGAPORE",
      nicename: "Singapore",
      numcode: "702",
      phonecode: "65",
    },
    {
      currency: "United Arab Emirates Dirham",
      currencyAlias: "AED",
      currencySymbol: "د.إ",
      fxConversionRate: 22.11,
      id: 224,
      isActive: true,
      iso: "AE",
      iso3: "ARE",
      name: "UNITED ARAB EMIRATES",
      nicename: "United Arab Emirates",
      numcode: "784",
      phonecode: "971",
    },
    {
      currency: "Pound Sterling",
      currencyAlias: "GBP",
      currencySymbol: "£",
      fxConversionRate: 91.74,
      id: 225,
      isActive: true,
      iso: "GB",
      iso3: "GBR",
      name: "UNITED KINGDOM",
      nicename: "United Kingdom",
      numcode: "826",
      phonecode: "44",
    },
    {
      currency: "United States Dollar",
      currencyAlias: "USD",
      currencySymbol: "$",
      fxConversionRate: 82.22,
      id: 226,
      isActive: true,
      iso: "US",
      iso3: "USA",
      name: "UNITED STATES",
      nicename: "United States",
      numcode: "840",
      phonecode: "1",
    },
  ]
) {
  // console.log(currencyList, "currencyListrrr");
  const targetCurrency = currencyList?.find(
    (currency) => currency.currencyAlias === targetCurrencyAlias
  );

  if (!targetCurrency) {
    throw new Error(`Currency with alias ${targetCurrencyAlias} not found.`);
  }

  // Convert amount from INR to target currency
  let amountInTargetCurrency = amount / targetCurrency.fxConversionRate;
  if (isNaN(amountInTargetCurrency)) amountInTargetCurrency = 0;
  amountInTargetCurrency = Math.round(amountInTargetCurrency);

  // Format the amount in the target currency
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: targetCurrencyAlias,
    minimumFractionDigits: 0,
  }).format(amountInTargetCurrency);
}

// to get product name and generatedsku
export function combineValues(items, propertyPath, separator) {
  return items
    .map((item) => getValue(item, propertyPath))
    .filter(Boolean) // Filters out undefined or null values
    .join(separator);
}
// Helper function to access nested properties using a path
export function getValue(obj, path) {
  return path.split(".").reduce((acc, key) => acc && acc[key], obj);
}
export function formatDatewithspecialcahracters(dateString, format) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  const year = date.getFullYear();

  // Replace placeholders in the format string
  return format.replace("DD", day).replace("MM", month).replace("YYYY", year);
}

// to format Date
//format DD-MM-YYYY hh:mm a to get 15-11-2024 03:22 PM
//to get 15-Nov-2024 03:22 PM  format should be DD-MMM-YYYY hh:mm a
// to get DDth Month YYYY at HH:mm AM/PM format should be 15th November 2024 at 03:22 PM
//to get Friday, November 15, 2024 format should be dddd, MMMM DD, YYYY
export function formatDate(dateString, format, daysToAdd = 0) {
  const date = new Date(dateString);

  // Add or subtract days from the current date if daysToAdd is provided (or default to 0)
  date.setDate(date.getDate() + daysToAdd);

  // Define arrays for day and month names
  const day = date.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Helper function to add day suffix (st, nd, rd, th)
  const daySuffix = (day) => {
    if (day >= 11 && day <= 13) return `${day}th`; // Special case for 11, 12, 13
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  // Get the day of the week (e.g., "Monday", "Tuesday", "Wednesday")
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayOfWeek = weekDays[date.getDay()];

  // Format the time in 12-hour format with AM/PM
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format

  // Determine if format includes 'DDth'
  const formattedDay = format.includes("DDth") ? daySuffix(day) : day;

  // Replace placeholders in the format string
  let formattedDate = format
    .replace("DDth", formattedDay) // Day with suffix (if applicable)
    .replace("DD", formattedDay) // Day without suffix (if no 'DDth' found)
    .replace("Month", month) // Full month name
    .replace("MM", month.toString().padStart(2, "0"))
    .replace("YYYY", year) // Full year
    .replace("HH", hours.toString().padStart(2, "0")) // Hour in 12-hour format
    .replace("mm", minutes) // Minutes with 2 digits
    .replace("AM/PM", ampm) // AM/PM notation
    .replace("DayOfWeek", dayOfWeek); // Day of the week (e.g., "Monday")

  return formattedDate;
}
export const getImage = (image, size = "1000X1000") => {
  let productId = image.split("/");
  return `${constants.image_url}${productId?.[0]}/${productId?.[1]}/${size}/${productId?.[2]}`;
};
export const createHomeDataResponseObject = (data, component, title_Text) => {
  if (title_Text !== null) {
    const filterdData = data?.findIndex(
      (obj) =>
        obj?.component === component && obj?.props?.title_Text === title_Text
    );

    const filterdDataArray = data[filterdData + 1]?.props;
    const filterdDataHeader = data[filterdData]?.props?.title_Text;
    return {
      component: filterdDataHeader,
      props: filterdDataArray,
    };
  } else if (title_Text == null) {
    const filterdData = data?.find((obj) => obj?.component === component);
    return filterdData;
  }
};
export const createHomeProductCountObject = (
  homePageProductCountCard,
  data
) => {
  return homePageProductCountCard.map((item) => {
    if (data?.data?.data[item.subtitle]) {
      return {
        ...item,
        product_count: data?.data?.data[item.subtitle]?.totalCount,
      };
    } else {
      return item;
    }
  });
};
export const accountMenu = [
  {
    title: "Personal Information",
    key: 1,
    actionTitle: "Edit Details",
  },
  {
    title: "Manage Address",
    key: 2,
    actionTitle: "Add New Address",
  },
  {
    title: "Shopping Bag",
    key: 3,
    actionTitle: null,
  },
  {
    title: "Wishlist",
    key: 4,
    actionTitle: "Add More",
  },
  {
    title: "All Orders",
    key: 5,
    actionTitle: null,

    subtitle: "View Orders",
  },
  {
    title: "Redeem Points",
    key: 6,
    actionTitle: null,
  },
  {
    title: "Logout",
    key: 7,
    actionTitle: null,
  },
];
export const allCdnApiData = (data, pagetype) => {
  const filteredData = data?.filter((item) => item.page === pagetype);
  return filteredData;
};
export const careerFooter = {
  title: "Why join Stylori?",
  subtitle:
    "At Stylori, we don't just craft jewelry, we craft dreams. If you want to find yourself on the transformation journey, you are at the right place!",
  content: [
    {
      content_title: "Great Work place",
      content_description:
        "We provide a friendly and challenging atmosphere that will help you achieve great heights with the help of your mates.",
    },
    {
      content_title: "Opportunities for Growth",
      content_description:
        "We provide a bundle of aspects for you to bloom in our cultivating growth garden- where creativity meets camaraderie!",
    },
    {
      content_title: "Exciting Projects",
      content_description:
        "You will see yourself taking the lead, actually doing something and making a bang on success!",
    },
    {
      content_title: "Work-Life Balance",
      content_description:
        "We strongly believe that balance is crucial for personal and professional development and most importantly, your life!",
    },
  ],
};
export const changeUrl = (url) => {
  // Condition 1: Replace path if the URL matches the list of domains
  const domainList = constants.SITE_PATHS;
  const matchingDomain = domainList.some((domain) => url.includes(domain));

  let updatedUrl = url; // Initialize updatedUrl as the original URL.

  if (matchingDomain) {
    // Create a URL object from the original URL
    const urlObject = new URL(url);

    // Remove the domain from the URL (set hostname to empty)
    urlObject.hostname = ""; // This removes the domain/hostname part of the URL

    // Update the updatedUrl with the modified URL, leaving path and query intact
    updatedUrl = urlObject.href.replace(urlObject.origin, ""); // Removing the domain from the full URL
  }

  // Condition 2: Replace or append the path if the URL starts with constants.ADDITIONAL_PATH
  if (url.startsWith(constants.ADDITIONAL_PATH)) {
    const urlObject = new URL(url);
    urlObject.pathname = ""; // Replace with desired path

    updatedUrl = urlObject.href; // Update the URL with the new path
  }

  return constants.ADDITIONAL_PATH + updatedUrl; // Return the final updated URL
};

export const findCategoryByValue = (searchValue, data, othersData = null) => {
  let matchedCategories = [];
  if (
    filterSortByArray().includes(searchValue.toLowerCase()) &&
    othersData !== null
  ) {
    othersData.map((item, index) => {
      if (item.attributeValue == searchValue) {
        matchedCategories.push({
          attributeName: item.attributeName,
          attributeValue: item.attributeValue,
        });
      }
    });
  } else {
    for (const category in data) {
      if (!isEmptyArray(data[category]) && Array.isArray(data[category])) {
        // if (filterSortByArray().includes(searchValue)) {
        //   matchedCategories = [...othersData];
        //   break;
        // }
        if (
          data[category]?.some(
            (item) => item !== null && item?.name === searchValue
          )
        ) {
          matchedCategories.push({
            attributeName: category,
            attributeValue: searchValue,
          });
        }
      }
    }
  }

  // Flatten any nested arrays

  return matchedCategories;

  // return null; // Return null if not found
};

export const filterSortFromURl = (
  url = "?sort=New%20to%20Stylori&startprice=0&endprice=0"
) => {
  const cleanUrl = url.startsWith("?") ? url.substring(1) : url;

  const params = cleanUrl.split("&");

  const parsedParams = params.reduce((acc, param) => {
    const [key, value] = param.split("=");
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});

  return parsedParams;
};

export const filterSortByArray = () => {
  return ["others", "other"];
};
export const filterSortByArrayBasedOnUrl = () => {
  return [
    "new to stylori",
    "featured",
    "price low to high",
    "price high to low",
    "ready to ship",
    "best seller",
  ];
};

export const Productcare = [
  {
    title: "Gold",
    subtitle: "Keep Your Gold Glittering Forever",
    img: constants.DEFAULT_IMAGE + "/product_care_accortian_img.png",
    img2: constants.DEFAULT_IMAGE + "/sliver-make-img.png",
    description:
      "<ul><li>To clean gold jewellery, mix a few drops of mild dish detergent with warm water and scrub it with a soft cloth. Rinse with warm water and allow the jewellery to dry.</li><li>Or keep your gold ornaments in warm water for two minutes, apply toothpaste and then gently clean them with a soft cloth. Rinse with warm water and allow them to dry</li><li>Ammonia can also be used to clean gold jewellery but because it is a strong cleaning agent, do not clean jewellery too often with it. Mix four parts warm water and one part household ammonia, submerge your jewellery for not more than ten minutes and then carefully clean with a soft cloth. Rinse it with warm water and allow it to dry.</li><li>Gold jewellery with gemstones should not be submerged in warm water as this may loosen the gemstones. Clean it with a soft cloth dipped in a solution of mild dish detergent and warm water instead.</li><li>Moisture speeds the tarnishing process so it's important to make sure that your jewellery is dry before putting it away</li><li>Always use warm water to clean gold jewellery..</li></ul>",
  },
  {
    title: "Silver",
    subtitle: "Bring Back The Shine In Your Silver Jewellery",
    img: constants.DEFAULT_IMAGE + "/product_care_accortian_img.png",
    img2: constants.DEFAULT_IMAGE + "/sliver-make-img.png",
    description:
      "<ul><li>Clean silver ornaments that are starting to discolor with a soft cloth dipped in a mix of mild dish detergent and warm water. Rinse with cool water and buff with a cloth until dry.</li><li>For heavier tarnish, mix a paste of three parts baking soda to one part water. Wet the silver and apply the paste with a soft cloth. Rinse with cool water and buff with a cloth until dry. Do not soak silver as this speeds up the tarnishing process.</li><li>Prompt cleaning is important when your silver jewellery has been exposed to certain foods containing sulfur or those which are acidic or salty. Food such as salt, eggs, onions, mayonnaise, and vinegar are harmful to silver.</li></ul>",
  },
  {
    title: "Platinum",
    subtitle: "How To Clean Platinum Jewellery",
    img: constants.DEFAULT_IMAGE + "/product_care_accortian_img.png",
    img2: constants.DEFAULT_IMAGE + "/platinum-product-care.jpg",

    description:
      "<ul><li>The best way to clean platinum is to use a mild solution of soap and warm water and gently rub your platinum jewellery with a soft cloth. Rinse with warm water and allow it to dry.</li></ul>",
  },
  {
    title: "Diamond",
    subtitle: "Cleaning Diamonds and other precious stones",
    img: constants.DEFAULT_IMAGE + "/product_care_accortian_img.png",
    img2: constants.DEFAULT_IMAGE + "/diamond-product-care.jpg",
    description:
      "<ul><li>For cleaning diamonds and other precious stones, mix a solution of warm, soapy water. Use a soft cloth to gently clean the stone with the water. Rinse off using warm water and pat dry with a soft, clean cloth.</li></ul>",
  },
];
export const ShippingAndReturns = [
  {
    title: "Gold",
    description:
      "<ul><li>At the core of our service offering is “Delight the customer” motto. We have a unique hassle-free product return and refund policy. We would love to hear from you on “What didn't go well with your purchase” to process your return request.</li><li>Return to Refund takes 25 days - Our product and service standard will seldom make you return the product, but in case of a return, you should do it within 25 days of receiving the product. You will receive 100% refund within 25 days.</li><li>To be eligible for return, the product shouldn't have any custom engraving, should be in its original condition without damage and have the original certificates and packaging.</li><li>For all eligible refunds / returns, contact customer care to arrange for reverse pick up. There will be No shipping charges. Please pack as per instructions given above and the courier service will pick it up from your doorstep</li></ul>",
  },
  {
    title: "Style upgrade program",
    description:
      "<ul><li>Stylori believes in ‘latest' fashion and style. Hence our unique style-upgrade program. You can exchange or upgrade your jewellery anytime, contact customer care to book an exchange and we will gladly ship your ‘Story of love – product' free of cost.</li><li>The transparent value card will give you an estimate based on the purchase price of your jewellery, at actual weight after reducing ‘wear and tear' charge. The amount will be credited to your customer loyalty account and can be used for any future purchase.</li><li>There will be no ‘wear and tear' charge if you exchange within 25 days.</li></ul>",
  },
  {
    title: "Silver",
    description:
      "<ul><li>Once the order has been shipped, it cannot be cancelled.</li><li>No returns or exchange allowed for Silver jewellery including CSK merchandise</li><li>We do not accept return or exchange unless the product is damaged during transit. If there is any damage or defect, you can inform customer care on the same day of receiving the order with a photo of the product and the parcel.</li><li>All shipments undergo quality check before they are packed. In case of damage, after confirmation our support team will arrange for return pickup.</li><li>Customised orders cannot be cancelled, the amount cannot be refunded or exchanged with another product.</li></ul>",
  },
  {
    title: "Customer Care",

    description:
      "<ul><li ><a  href='mailto:hello@stylori.com'  target='_blank'>hello@stylori.com</a></li><li><a href='tel:9952625252' target='_blank'>9952625252</a></li><li>Timings: 10 am – 7 pm</li></ul>",
  },
];
export const parseData = (data) => {
  return JSON.parse(data);
};

export const getshippingDate = () => {
  const currentDate = new Date();
  const nextNextDate = new Date(currentDate);
  nextNextDate.setDate(currentDate.getDate() + 2);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formatDate = (date) => {
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  return formatDate(nextNextDate);
};

export const capitalizeFirstLetter = (str) => {
  return str
    .trim() // Remove leading and trailing whitespace
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
