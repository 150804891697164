import React, { useState } from "react";

const Contact = ({
  label,
  label2,
  options,
  name,
  name2,
  value,
  value2,
  onChange,
  error,
  errorMsg,
}) => {
  const [selectedCode, setSelectedCode] = useState("EN");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleCodeChange = (event) => {
    setSelectedCode(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <section>
      <div className="text-base pb-4 flex flex-row items-center justify-between font-medium text-[#353535]">
        <label>{label}</label>
      </div>
      <div
        className={`flex items-center gap-3 border ${
          error ? `border-[#E43138]` : `border-gray-300 `
        } py-2 mb-1 w-full`}
      >
        
        {/* Dropdown for country code */}
        <select
          value={value2}
          onChange={onChange}
          className="border-none bg-transparent focus:outline-none text-[#262626] text-sm"
          name={name2}
        >
          {options?.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>

        {/* Divider */}
        {/* <span className="h-full w-px bg-gray-300 mx-2"></span> */}

        {/* Input for phone number */}
        <input
          type="text"
          value={value}
          onChange={onChange}
          placeholder="Enter phone number"
          className="focus:outline-none text-[#262626] Placeholder:text-[#989692]"
          name={name}
        />
        
      </div>
      {error && <p className="text-[#E43138] text-sm">{errorMsg}</p>}
    </section>
  );
};

export default Contact;
