import React, { useEffect, useRef, useState } from "react";
import Accountpagesidemenu from "../components/Accountpagesidemenu";
import Accountpagecontent from "../components/Accountpagecontent";
import { getalluseraddressess } from "../redux/action/orderAction";
import { useDispatch, useSelector } from "react-redux";

import {
  getPersionalData,
  getAllOrders,
  getPersionalAddress,
  getRedeemPointsDetails,
  getShoppingDetails,
  getSingleOrder,
  getWishList,
  logoutWebsite,
  removeaddress,
} from "../redux/action/myAccountAction";
import { logout } from "../redux/action/authAction";
import { accountMenu } from "../utilities/helper";
import constants from "../utilities/constants";
import PopupModal from "../components/PopupModal";
import Accountpagemodal from "./Modalscreens/Accountpagemodal";
import { getAuthModalType, loginModalAction } from "../redux/action/authAction";
import { useNavigate } from "react-router-dom";
import { Toast } from "../components/Atoms/Toast";
import { clearvoucherdata } from "../redux/action/cartAction";
export default function Account() {
  const dispatch = useDispatch();
  const { accessToken, userProfile_id, userProfile_email, isLoggedIn } =
    useSelector((state) => state.authReducer);
  const {
    userAddressess_success,
    USER_ADDRESSES_FAILURE,
    USER_ADDRESSES_REQUEST,
  } = useSelector((state) => state.useraddressreducer);
  const [myAccountPageHeader, setMyAccountPageHeader] = useState(
    accountMenu[0]
  );

  const [isOpen, setIsOpen] = useState(false);
  const [modelTitle, setModeltitle] = useState(null);
  const [modelSubTitle, setModelSubTitle] = useState(null);
  const [showModelData, setShowModelData] = useState(null);
  const [headerEditModal, setHeaderEditModal] = useState(false);
  const [value, setvalue] = useState(null);
  const [editManageAddressData, setEditManageAddressData] = useState(null);
  const [editaddressid, seteditaddressid] = useState(null);
  const navigation = useNavigate();
  const ref = useRef(null);
  const {
    //persionalinformation
    persionalInformationDataLoading,
    persionalInformationDataSuccess,
    persionalInformationDataError,
    //address
    persionalAddressDataLoading,
    persionalAddressDataSuccess,
    persionalAddressDataError,
    //wishlist
    wishListDataLoading,
    wishListDataSuccess,
    wishListDataError,
    //all orders
    allOrdersDataLoading,
    allOrdersDataSuccess,
    allOrdersDataError,
    //single order
    singleOrderDataLoading,
    singleOrderDataSuccess,
    singleOrderDataError,
  } = useSelector((state) => state.myAccountReducer);

  const handleNavigation = (title) => {
    if (title === "addresses") {
      dispatch(getalluseraddressess(userProfile_id));
    }
    const formattedTitle = title.toLowerCase().replace(/\s+/g, "");
    navigation(constants.ADDITIONAL_PATH + `/account-${formattedTitle}`);
  };
  useEffect(() => {
    console.log(
      "getAccountpath",
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ]
    );

    // if (!ref.current) {
    // Account_page_Ref.current = true;
    // ref.current = true;
    if (
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ] === constants.Account_Wishlist
    ) {
      // console.log("windows1");

      // console.log("constantlowercase", constants.Wishlist.toLowerCase());
      handleNavigation("wishlist");

      setMyAccountPageHeader(accountMenu[3]);
    } else if (
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ] === constants.Account_Profile
    ) {
      // console.log("windows2");
      setMyAccountPageHeader(accountMenu[0]);
      handleNavigation("profile");
    } else if (
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ] === constants.Account_Addresses
    ) {
      setMyAccountPageHeader(accountMenu[1]);
      handleNavigation("addresses");
    } else if (
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ] === constants.Account_Allorders
    ) {
      // console.log("windows2");
      setMyAccountPageHeader(accountMenu[4]);
      handleNavigation(constants.AllOrders);
    } else if (
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ] === constants.Account_Allorders
    ) {
      // console.log("windows2");
      setMyAccountPageHeader(accountMenu[4]);
      handleNavigation(constants.AllOrders);
    } else if (
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ] === constants.Account_Redeempoints
    ) {
      // console.log("windows2");
      setMyAccountPageHeader(accountMenu[5]);
      handleNavigation(constants.RedeemPoints);
    }
    // else if (
    //   window.location.pathname.split("/")[2] === constants.Resetpassword
    // ) {
    //   alert("resetpasswor")
    //   console.log("resetpassword called!")
    //   setTimeout(() => {
    //     dispatch(getAuthModalType(constants.Reset))
    //   }, 1000);

    // }
    // }
    console.log(
      window.location.pathname.split("/")[1],
      "resetpassword called!"
    );
  }, [window.location.pathname]);
  console.log(
    "myAccountPageHeader",
    myAccountPageHeader.title === constants.PersonalInformation
  );
  console.log("persionalAddressDataSuccess12", persionalAddressDataSuccess);

  // useEffect(() => {
  //   if (
  //     window.location.pathname.split("/")[
  //       window.location.pathname.split("/").length - 1
  //     ] === constants.Account_Profile
  //   ) {
  //     ref.current = false;
  //   }
  // }, [window.location.pathname]);

  // Signin: "signin",
  // SigninOtp: "signinotp",
  // Register: "register",
  // Login: "login",
  // Reset: "reset",
  // Subscribe: "subscribe",
  useEffect(() => {
    console.log(userProfile_id, "userProfile_id in account ");
    console.log(
      window.location.pathname.split("/")[2],
      "window.location.pathname.split(" / ")[2]"
    );
    console.log(constants.Resetpassword, "constants.Resetpassword");

    if (
      window.location.pathname.split("/")[2] === constants.Resetpassword &&
      (userProfile_id === null || !userProfile_id)
    ) {
      dispatch(getAuthModalType(constants.Reset));
      return;
    } else if (userProfile_id === null || !userProfile_id) {
      dispatch(getAuthModalType(constants.Login));
      // dispatch(loginModalAction({ isOpenModal: true, modalType: 'cart' }));
    } else {
      dispatch(getPersionalData(userProfile_id));
      if (myAccountPageHeader.title === constants.PersonalInformation) {
        // handleNavigation("profile");
        // ref.current = false;
      } else if (myAccountPageHeader.title === constants.ManageAddress) {
        handleNavigation("addresses");
      } else if (myAccountPageHeader.title === constants.Wishlist) {
        handleNavigation("wishlist");
      } else if (myAccountPageHeader.title === constants.AllOrders) {
        handleNavigation(constants.AllOrders);
      } else if (myAccountPageHeader.title === constants.SingleOrder) {
        handleNavigation(constants.SingleOrder);
      } else if (myAccountPageHeader.title === constants.RedeemPoints) {
        handleNavigation(constants.RedeemPoints);
      }
    }
  }, [myAccountPageHeader, userProfile_id]);
  // handle the model values
  const handleModelChange = (value, handleModal, handlevalue = null) => {
    setHeaderEditModal(handleModal); // model headers are handle by this and also model datas are displayed by this condition
    if (value === constants.Wishlist) {
      setIsOpen(false);
      navigation(
        constants.ADDITIONAL_PATH +
          "/silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0"
      );
    } else {
      setIsOpen(true);
    }
    setShowModelData(value);
    if (value === constants.MobileNumber) {
      setModeltitle("Change Mobile Number");
      setModelSubTitle(null);
    }
    if (value === constants.EmailAddress) {
      setModeltitle("Change Email Address");
      setModelSubTitle(null);
    }
    if (value === constants.Password) {
      setModeltitle("Reset your Password");
      setModelSubTitle("Create a new password to secure your account.");
    }
    if (value === constants.PersonalInformation) {
      setModeltitle(value); //edit personal information
      setModelSubTitle(null);
    }
    if (value === constants.AddressDelete) {
      seteditaddressid(handlevalue);
      setModeltitle("Confirmation");
      setModelSubTitle(null);
    }
    if (value === constants.AddressEdit) {
      setModeltitle("Edit Address");
      setModelSubTitle(null);
      setEditManageAddressData(handlevalue);
    }
    if (value === constants.ManageAddress) {
      setModeltitle("Add Address");
      setModelSubTitle(null);
    }
    if (value === constants.SingleOrder) {
      setModeltitle("View Orders");
      setModelSubTitle(null);
    }

    // if (value === constants.Logout) {
    //   setModeltitle(null);
    //   setModelSubTitle(null);
    // }
  };
  // handle model input changes
  const handleInputChange = (label, value) => {
    setvalue(value);
    console.log("handleChangedata", label, value);
    // if (label === "Make this as a Primary Address") {
    //   setvalue(!value);
    // } else {
    //   setvalue(value);
    // }
  };
  // handle model button changes
  const handleButtonChange = (label) => {
    console.log(label, "labelnnn");
    if (label === "Cancel") {
      setIsOpen(false);
    }
    if (label === "Remove") {
      console.log("Removeclicked");
      setShowModelData(constants.RemoveWishlist);
      setModeltitle("Confirmation");
      setModelSubTitle(null);
      setIsOpen(true);
      setHeaderEditModal(constants.Confirmation); //using this handle the conformation models and close icon
    }
    if (label === "log out confirm") {
      dispatch(logout());
      dispatch(logoutWebsite());
      dispatch(clearvoucherdata());
      setIsOpen(false);
    }
    if (label === "Confirm") {
      dispatch(removeaddress(editaddressid));
      dispatch(getPersionalData(userProfile_id));
    }
  };
  //handle side menu click and show content
  const handleClick = (item) => {
    console.log("handleClickitem", item);
    userProfile_id ?? dispatch(getPersionalData(userProfile_id));
    setMyAccountPageHeader(item);
    // Toast("Mobile number is invalid.", "success");

    if (item.title === constants.PersonalInformation) {
      //   dispatch(getPersionalData());
      handleNavigation("profile");
    }
    // if (item.title === constants.ManageAddress) {
    //   dispatch(getPersionalAddress());
    // }
    if (item.title === constants.ShoppingBag) {
      navigation(constants.ADDITIONAL_PATH + "/cart");
    }

    // if (item.title === constants.Wishlist) {
    //   dispatch(getWishList());
    // }
    // if (item.title === constants.AllOrders) {
    //   dispatch(getAllOrders());
    // }
    if (item.title === constants.RedeemPoints) {
      dispatch(getRedeemPointsDetails());
    }

    if (item.title === constants.Logout) {
      // dispatch(logoutWebsite());
      setModeltitle("Confirmation");
      setModelSubTitle(null);
      setIsOpen(true);
      setShowModelData(constants.Logout);
      setHeaderEditModal(constants.Confirmation);
      setMyAccountPageHeader(accountMenu[0]);
      handleNavigation("profile");
    }
    if (item.title === constants.SingleOrder) {
      dispatch(getSingleOrder(item.orderId));
    }
  };

  return (
    <main className="mx-auto container mt-2 mb-10 h-full lg:p-0">
      <h1 className="font-bold text-[34px] font-Cormorant md:ps-2">
        My Account
      </h1>

      <section className="grid grid-flow-row md:grid-flow-col md:gap-4 mt-5 mx-auto container">
        <section className="">
          <Accountpagesidemenu
            handleClick={handleClick}
            activeSidebar={myAccountPageHeader}
            isLoggedIn={isLoggedIn}
          />
        </section>
        <section
          className={`border border-[#D9D9D9] rounded-tl-[16px] lg:w-full  rounded-tr-[16px]  0 `}
        >
          {myAccountPageHeader?.title !== constants.Logout && (
            <Accountpagecontent
              header={myAccountPageHeader}
              handleModel={handleModelChange}
              handleButtonChange={handleButtonChange}
              handleClick={handleClick}
            />
          )}
          <PopupModal isOpen={isOpen} page="Accountpagemodal">
            <Accountpagemodal
              modelTitle={modelTitle}
              modelSubTitle={modelSubTitle}
              handleInputChange={handleInputChange}
              handleButtonChange={handleButtonChange}
              showModelData={showModelData}
              headerEditModal={headerEditModal}
              value={value}
              setIsOpen={setIsOpen}
              personalval={
                userProfile_id && persionalInformationDataSuccess
                  ? persionalInformationDataSuccess
                  : null
              }
              editManageAddressData={editManageAddressData}
            />
          </PopupModal>
        </section>
      </section>
    </main>
  );
}
