import React, { useEffect } from "react";

const PopupModal = ({ isOpen, children, page }) => {
  useEffect(() => {
    if (isOpen) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  return (
    <div className="flex justify-center items-center ">
      {/* Trigger Button */}

      {/* Modal */}
      {isOpen && (
        <div className="z-10 absolute w-full inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div
            className={`bg-white  shadow-lg  ${
              page === "loginmodal"
                ? "p-0 rounded-2xl max-md:w-[90%] max-lg:w-[70%]  lg:w-[85%] 2xl:w-[50%]"
                : "p-6 rounded-lg w-[90%] lg:w-[50%] xl:w-[35%]"
            } `}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupModal;
