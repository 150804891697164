// src/store/actions/authActions.js

import { authenticateUser, signupusingemail } from "../../utilities/api";
import constants from "../../utilities/constants";
import { getCartpagedata_count } from "./cartAction";
import { ACTION_TYPE } from "./type";

export const loginRequest = () => ({
  type: ACTION_TYPE.LOGIN_REQUEST,
});

export const loginSuccess = (userData) => ({
  type: ACTION_TYPE.LOGIN_SUCCESS,
  payload: userData,
});

export const loginFailure = (error) => ({
  type: ACTION_TYPE.LOGIN_FAILURE,
  payload: error,
});

// Async action to handle user login
export const login = (email, password) => {
  // console.log("loginResponreducer", email, "password", password);
  return (dispatch) => {
    dispatch(loginRequest());
    authenticateUser(email, password)
      .then((data) => {
        console.log("loginResponseSuccess", data);
        dispatch(loginSuccess(data)); // Dispatch success action with user data
        dispatch(getCartpagedata_count(data?.userprofile?.id));
      })
      .catch((error) => {
        console.log(error, " error in login....");
        dispatch(loginFailure(error)); // Dispatch failure action with error message
      });
  };
};
export const logout = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.LOGOUT });
};
export const loginModalAction = (payload) => ({
  type: ACTION_TYPE.LOGIN_MODAL,
  payload,
});
export const loginModalType = (payload) => ({
  type: ACTION_TYPE.LOGIN_MODAL_TYPE,
  payload,
});
export const getAuthModalType =
  (type = null) =>
  async (dispatch) => {
    dispatch({
      type: ACTION_TYPE.AUTH_MODAL_TYPE_CLEAR,
    });
    dispatch({
      type: ACTION_TYPE.AUTH_MODAL_TYPE_SUCCESS,
      payload: {
        authModalType: type == null ? "login" : type,
      },
    });
  };
export const clearAuthModalType = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.AUTH_MODAL_TYPE_CLEAR,
  });
};

export const signup = (signupdata) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.SIGNUP_REQUEST,
  });
  try {
    const data = await signupusingemail(signupdata);
    console.log("==>signupusingemail", data);
    if (data.accessToken) {
      dispatch({
        type: ACTION_TYPE.SIGNUP_SUCCESS,
        payload: data,
      });
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    console.log("signupusingemail_error1", JSON.stringify(error));
    console.log("signupusingemail_error2", error?.response);
    console.log("signupusingemail_error3", error);

    dispatch({
      type: ACTION_TYPE.SIGNUP_ERROR,
      errorMessage: error?.response?.data?.message
        ? error?.response?.data?.message
        : error,
    });
    dispatch({
      type: ACTION_TYPE.AUTH_MODAL_TYPE_SUCCESS,
      payload: {
        authModalType: constants.Register,
      },
    });
  }
};
