import React from "react";
import constants from "../utilities/constants";

function AboutStylori() {
  return(
    <>
    <div className="lg:mb-0 mb-5">
      <img src={constants.DEFAULT_IMAGE + "/about-banner.jpg"} alt="About Banner" />
    </div>
    <div className="container mx-auto lg:p-0 p-5">
      <div className="lg:grid lg:grid-cols-2 lg:my-20 gap-10">
        <div className="col-span-1">
        <img src={constants.DEFAULT_IMAGE + "/about-side-image.jpg"} alt="About Side Banner" />
        </div>
        <div className="col-span-1 mb-3">
          <h3 className="text-[#262626] text-[28px] font-bold font-Cormorant">About Us</h3>
          <p className="mb-2 text-[#262626] font-medium text-base">About Us Stylori is one of the pioneers of everyday jewellery in India. Featuring jewellery that is contemporary and chic, Stylori is an exclusively online store for the modern Indian woman.</p>
          <p className="text-[#262626] font-medium text-base">Our jewellery is crafted from certified metals, gemstones and diamonds. With craftmanship honed over decades, Stylori's designs draw upon diverse influences from across India and the world. At our online store you can shop for jewellery from the comfort of your home worry-free with easy payment options and a reliable return policy.</p>

          <h3 className="text-[#262626] text-[28px] font-bold font-Cormorant mt-5">It's your story</h3>
          <p className="mb-2 text-[#262626] font-medium text-base">Going beyond jewellery, Stylori celebrates the art of the story. Jewellery and the people who wear them always have stories to tell. From the young woman remembering her grandmother's jewellery to the kings and queens and their exquisite and priceless jewellery, we love to hear and tell these stories.</p>
          <h3 className="text-[#262626] text-[28px] font-bold font-Cormorant mt-5">Our story</h3>
          <p className="mb-2 text-[#262626] font-medium text-base">In the 21st century, N. Anandha Ramanujam, the current generation of the NAC family, founded the exclusively online store Stylori. A qualified gemologist from the Gemological Institute of America (GIA), N. Anandha Ramanujam conceptualised and launched Stylori along with co-founder and entrepreneur S. Mahadevan.</p>
        </div>
      </div>
    </div>
    </>
  );
  
}

export default AboutStylori;
