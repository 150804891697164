import React from "react";
import constants from "../../utilities/constants";

const Textinput = ({
  label = null,
  placeholder = "test",
  value,
  onChange,
  type = "text",
  keyvalue,
  name,
  error,
  errorMsg,
  disabled
}) => {
  return (
    <>
      {label !== null ? (
        <section>
          <div className="text-base justify-between items-center font-medium text-[#353535]">
            <label>{label}</label>
          </div>
          <div key={keyvalue} className="py-4 relative">
            <input
              type={type}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              className={`w-full px-4 mb-1 py-2 border Placeholder:text-[#989692] ${
                error ? "border-[#E43138]" : "border-[#CACACA]"
              }  focus:outline-none focus:ring-2 focus:ring-blue-500`}
              key={label}
              name={name}
              disabled={disabled}
            />
            {error && <p className="text-[#E43138] text-sm">{errorMsg}</p>}
            {(label === "New Mobile number" ||
              label === "New Email Address") && (
              <img
                src={constants.DEFAULT_IMAGE + "/success_tick.png"}
                alt="tick_img"
                className="absolute top-1/2 right-4 w-5 h-5 transform -translate-y-1/2 pointer-events-none"
              />
            )}
          </div>
        </section>
      ) : (
        <section
          className={` ${
            name === "register_First_Name" || name === "register_Last_Name"
              ? "col-span-8 pb-2"
              : "col-span-8"
          }`}
        >
          <div
            key={keyvalue}
            className={` relative  ${
              name === "register_First_Name" || name === "register_Last_Name"
                ? "col-span-8 pb-2"
                : "col-span-8"
            }  `}
          >
            <input
              type={type}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              className={`w-full px-4 mb-1 py-2 border Placeholder:text-[#989692] ${
                error ? "border-[#E43138]" : "border-[#CACACA]"
              }  focus:outline-none `}
              key={label}
              name={name}
              maxLength={name === "register_Mobile_number" ? 10 : null}
            />
            {(label === "New Mobile number" ||
              label === "New Email Address") && (
              <img
                src={constants.DEFAULT_IMAGE + "/success_tick.png"}
                alt="tick_img"
                className="absolute top-1/2 right-4 w-5 h-5 transform -translate-y-1/2 pointer-events-none"
              />
            )}
          </div>
          <div className={`${error ? "p-0 m-0" : "pt-2"}`}>
            {error && (
              <span className="text-[#E43138]  text-sm ">{errorMsg}</span>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default Textinput;
