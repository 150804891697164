import { ACTION_TYPE } from "../action/type";

const initialState = {
  siteMapDataLoading: false,
  siteMapDataSuccess: null,
  siteMapDataError: null,
  careerPageDataLoading: false,
  careerPageDataSuccess: null,
  careerPageDataError: null,
};

const siteMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.SITE_MAP_DATA_REQUEST:
      return {
        ...state,
        siteMapDataLoading: true,
        siteMapDataSuccess: null,
        siteMapDataError: null,
      };
    case ACTION_TYPE.SITE_MAP_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        siteMapDataLoading: false,
        siteMapDataSuccess: action.payload.siteMapDataSuccess,
        siteMapDataError: null,
      };
    case ACTION_TYPE.SITE_MAP_DATA_REQUEST_FAILURE:
      return {
        ...state,
        siteMapDataLoading: false,
        siteMapDataSuccess: null,
        siteMapDataError: action.errorMessage,
      };
    case ACTION_TYPE.CARREER_PAGE_DATA_REQUEST:
      return {
        ...state,
        careerPageDataLoading: true,
        careerPageDataSuccess: null,
        careerPageDataError: null,
      };
    case ACTION_TYPE.CARREER_PAGE_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        careerPageDataLoading: false,
        careerPageDataSuccess: action.payload.careerPageDataSuccess,
        careerPageDataError: null,
      };
    case ACTION_TYPE.CARREER_PAGE_DATA_REQUEST_FAILURE:
      return {
        ...state,
        careerPageDataLoading: false,
        careerPageDataSuccess: null,
        careerPageDataError: action.errorMessage,
      };
    default:
      return state;
  }
};
export default siteMapReducer;
