import React from "react";

const Textarea = ({
  label = "test",
  placeholder = "test",
  value,
  onChange,
  type = "textarea",
  keyvalue,
  name,
  error,
  errorMsg,
}) => {
  return (
    <section className="col-span-2 pb-2">
      <div className="text-base justify-between items-center font-medium text-[#353535]">
        <label>{label}</label>
      </div>
      <div key={keyvalue} className="pt-2 relative">
        <textarea
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`w-full px-4 resize-none Placeholder:text-[#989692] h-[86px] py-2 border ${
            error ? "border-[#E43138]" : "border-[#CACACA]"
          }   focus:outline-none focus:ring-2 focus:ring-blue-500`}
          key={label}
          name={name}
        />
      </div>
      {error && <p className="text-[#E43138] text-sm">{errorMsg}</p>}
    </section>
  );
};

export default Textarea;
