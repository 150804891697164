import React, { useState } from "react";
import { ShippingAndReturns } from "../utilities/helper";
import constants from "../utilities/constants";

function ShippingReturns() {
  const Accordion = ({ children }) => {
    return <div className="md:w-1/2 w-full">{children}</div>;
  };

  const AccordionItem = ({ header, children }) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
      <div className="border-b w-full pb-5 pt-5">
        <button
          className=" flex flex-row justify-between  lg:items-center max-lg:px-4  w-full"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className=" text-black ">{header}</div>
          <span className="text-xl">
            {isOpen ? (
              <img
                src={constants.DEFAULT_IMAGE + "/accortian_close.png"}
                alt="close icon"
                className="w-7 h-7 max-lg:ms-2"
              />
            ) : (
              <img alt="plus icon" className="w-5 h-5 max-lg:ms-2" src={constants.DEFAULT_IMAGE + "/accortian_open.png"} />
            )}
          </span>
        </button>
        {isOpen && (
          <div className="px-4  font-century font-medium text-sm">
            {children}
          </div>
        )}
      </div>
    );
  };
  return (
    <main>
      <section>
        <img className="w-full " src={constants.DEFAULT_IMAGE + "/shipping_banner.png"} alt="ProductCareBanner" />
      </section>
      <section className="h-40  flex flex-col justify-center items-center">
        <span className="text-black font-Cormorant font-bold text-2xl">
          Shipping & Returns
        </span>
      </section>
      <section className="container mx-auto">
        {ShippingAndReturns.map((item, index) => (
          <div
            key={index}
            className="flex flex-col justify-center items-center"
          >
            <Accordion>
              <AccordionItem header={item.title}>
                <div
                  className="custom-content  text-[#64636B] "
                  dangerouslySetInnerHTML={{
                    __html: item?.description,
                  }}
                />
                
              </AccordionItem>
            </Accordion>
          </div>
        ))}
      </section>
    </main>
  );
}

export default ShippingReturns;
