import React, { useState } from "react";
import { Productcare } from "../utilities/helper";
import constants from "../utilities/constants";

const ProductCare = () => {
  const Accordion = ({ children }) => {
    return <div className="w-full">{children}</div>;
  };

  const AccordionItem = ({ header, children }) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
      <div className="lg:w-full">
        <button
          className=" flex flex-row justify-center  items-center lg:justify-between w-full"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="bg-[#F2F2F2] text-xl flex lg:w-full w-[400px] md:w-[700px] flex-row justify-between text-[#262626] p-4">
            {header}
            <span className="text-xl">
              {isOpen ? (
                <img
                  src={constants.DEFAULT_IMAGE + "/product_care_accortian_up.png"}
                  alt="close icon"
                  className="w-7 h-7 max-lg:ms-2"
                />
              ) : (
                <img
                  alt="plus icon"
                  className="w-7 h-7 max-lg:ms-2"
                  src={constants.DEFAULT_IMAGE + "/product_care_accortian_down.png"}
                />
              )}
            </span>
          </div>
        </button>
        <div
          className={`${
            isOpen ? "h-full" : "h-auto"
          } flex flex-col flex-wrap`}
        >
          {isOpen && (
            <div className="font-century font-medium text-sm">
              {children}
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <main className="pb-20">
      <section>
        <img
          className="w-full "
          src={constants.DEFAULT_IMAGE + "/product_care_banner.png"}
          alt="ProductCareBanner"
        />
      </section>
      <section className="max-md:text-center">
        <div className="bg-[#877359] text-white pb-3">
          <span className="font-Cormorant font-bold text-[44px] flex flex-col justify-center items-center ">
            Preserve the Beauty of Your Jewelry!
          </span>
          <div className="text-lg max-lg:p-2  text-center font-medium font-century ">
            At Stylori, we believe that every piece of jewelry tells a story.
            With proper care, your cherished silver, gemstone, and diamond
            jewelry can shine for generations.
          </div>
        </div>
      </section>
      <section className="text-center container mx-auto">
        <div className="pt-20">
          <p className="text-[#262626] [font-family:Cormorant] text-[40px] font-bold leading-[normal] tracking-[-0.4px]">
            Product care
          </p>
          <p className="text-[#262626] font-century pt-2 text-xl font-medium leading-[normal] tracking-[-0.4px]">
            Basic Jewellery Care Guide - With a little bit of care, your
            jewellery will retain its shine for a lifetime.
          </p>
          <div className="flex justify-center items-center text-center ">
            <img
              className="pt-4 w-[450px]"
              src={constants.DEFAULT_IMAGE + "/product_care_middle_img.svg"}
              alt="Product care img"
            />
          </div>
          <div className="text-center pt-4 pb-12">
            <p className="block text-[#262626] text-center text-2xl font-semibold leading-[normal] tracking-wide pb-3">
              General Instructions For Wearing Jewellery
            </p>
            <p className="text-[#262626] text-center pt-2 font-light text-xl">
              Remove your jewellery before showering or while doing household
              chores to prevent physical damage or exposure to chemicals or
              cleaning fluids. Soaps and lotions create a film over the
              jewellery dulling it over time. Wear your jewellery only after
              applying makeup because cosmetics, hairspray and perfumes contain
              chemicals that can damage it. Don't wear jewellery in swimming
              pools and spas as chlorinated water can react with metals found in
              jewellery causing color changes and even structural damage. Remove
              jewellery while playing sports as this may damage it. Keep your
              jewellery out of direct sunlight as the heat can cause it to fade.
            </p>
            <p className="text-[#262626] text-center pt-6 text-xl font-light">
              Polish your ornaments only with a jewellery polishing cloth or a
              very soft cotton cloth. Do not use tissues or paper towels because
              the fibers in these products may result in scratches. If you stain
              your jewellery with make-up, perfume, food or drinks, rinse
              immediately with water and soap. Clean the jewellery that you
              regularly wear at least once a month. Don not use a toothbrush,
              even a soft bristled one, to clean as it may result in scratches.
              Avoid bleach as it can permanently damage or discolor your
              jewellery.
            </p>
          </div>
        </div>
      </section>
      <section className="container mx-auto grid grid-cols-2 gap-4">
        {Productcare.map((item, index) => (
          <div
            key={index}
            className="col-span-1"
          >
            <Accordion>
              <AccordionItem header={item.title}>
                <div className="flex flex-row justify-start py-5">
                  <img
                    src={item.img}
                    className="w-1/2 object-contain "
                    alt={item.title + "img"}
                  />
                  {item.img2 && (
                    <img
                      src={item.img2}
                      className="w-[160px]  object-contain "
                      alt={item.title + "img2"}
                    />
                  )}
                </div>
                <h3 className="text-[#262626] font-century font-semibold text-2xl pb-3">
                  {item.subtitle}
                </h3>
                <div
                  className=" max-xl:px-3 productcareparagraph font-light text-base text-[#262626]"
                  dangerouslySetInnerHTML={{
                    __html: item?.description,
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        ))}
      </section>
    </main>
  );
};

export default ProductCare;
