import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import constants from "../utilities/constants";
import { Link, useNavigate } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "../styles/styles.css";
import { formatCurrencywithamount,urlchange} from "../utilities/helper";
// import required modules
import { Grid, Pagination } from "swiper/modules";


export default function App({ youMayLike_responseSuccess, bestseller }) {
  const dispatch = useDispatch();
  const { selectedcountrySuccess, dailyMetalPriceSuccess } = useSelector(
    (state) => state.homeReducer
  );
  const bestseller_data = bestseller?.props?.cardContent;

  const youMayLike =
    youMayLike_responseSuccess?.data?.data?.youMayalsolike2?.nodes ||
    bestseller_data ||
    [];
  const getImage = (image, size = "300X300") => {
    if (image.includes("banners")) {
      return image;
    } else {
      let productId = image.split("/");
      return `${constants.image_url}${productId?.[0]}/${productId?.[1]}/${size}/${productId?.[2]}`;
    }
  };
  return (
    <>
      <div className="lg:block hidden">
        <Swiper
          slidesPerView={4}
          grid={{
            rows: 1,
          }}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          modules={[Grid, Pagination]}
          className="mySwiper"
        >
          {youMayLike.map((product, index) => {
            const productImage =
              product?.productImagesByProductId?.nodes[0]?.imageUrl ||
              product?.image;
            const productName = product?.productName || product?.bottomText;
            const productPrice =
              product?.transSkuListsByProductId?.nodes[0]?.discountPrice ||
              product?.price;
            var productLink =
              product?.transSkuListsByProductId?.nodes[0]?.skuUrl ||
              product?.url;
            return (
              <SwiperSlide key={index} className="flex-col">
                {/* <Link to={productLink}>
                  <img src={getImage(productImage)} alt={productName} />
                </Link> */}
                <div onClick={() => urlchange(productLink)} className="cursor-pointer">
                <img src={getImage(productImage)} alt={productName} />                  
                </div>
                <h3 className="font-century font-medium lg:text-lg text-[8px] text-black mt-5">
                  {productName}
                </h3>
                <span className="text-[#676767] font-medium lg:text-sm text-[8px]">
                  {formatCurrencywithamount(
                    productPrice,
                    selectedcountrySuccess ? selectedcountrySuccess?.currencyAlias : 'INR',
                    `en-${selectedcountrySuccess?.iso || 'IN'}`,
                    dailyMetalPriceSuccess?.data?.allMasterCountries?.nodes
                  )}
                </span>
                {/* <a href={productLink} className="text-blue-600 text-sm mt-2">View Details</a> */}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="lg:hidden block">
        <Swiper
          slidesPerView={2.5}
          grid={{
            rows: 1,
          }}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          modules={[Grid, Pagination]}
          className="mySwiper"
        >
          {youMayLike.map((product, index) => {
            const productImage =
              product?.productImagesByProductId?.nodes[0]?.imageUrl ||
              product?.image;
            const productName = product?.productName || product?.bottomText;
            const productPrice =
              product?.transSkuListsByProductId?.nodes[0]?.discountPrice ||
              product?.price;            
              var productLink =
              product?.transSkuListsByProductId?.nodes[0]?.skuUrl ||
              product?.url;

            return (
              <SwiperSlide key={index} className="flex-col">
                {/* <Link to={productLink}>
                  <img src={getImage(productImage)} alt={productName} />
                </Link> */}
                <div onClick={() => urlchange(productLink)} className="cursor-pointer">
                <img src={getImage(productImage)} alt={productName} />                  
                </div>
                <h3 className="font-century font-medium lg:text-lg text-[8px] text-black mt-5">
                  {productName}
                </h3>
                <span className="text-[#676767] font-medium lg:text-sm text-[8px]">
                  {formatCurrencywithamount(
                    productPrice,
                    selectedcountrySuccess ? selectedcountrySuccess?.currencyAlias : 'INR',
                    `en-${selectedcountrySuccess?.iso || 'IN'}`,
                    dailyMetalPriceSuccess?.data?.allMasterCountries?.nodes
                  )}
                </span>
                {/* <a href={productLink} className="text-blue-600 text-sm mt-2">View Details</a> */}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}
