import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Accountpageindividualcontent from "./Accountpageindividualcontent";
import { getPersionalData } from "../redux/action/myAccountAction";
import constants from "../utilities/constants";
import { accountMenu } from "../utilities/helper";

function Accountpagecontent({
  header = {},
  handleModel,
  handleButtonChange,
  handleClick,
}) {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getPersionalData());
  // }, []);
  return (
    <main className="w-full">
      <section className="border-b border-b-[#D9D9D9] p-4 flex flex-row justify-between items-center">
        {header?.title !== constants.SingleOrder ? (
          <span className="font-Cormorant font-bold text-2xl text-[#262626]">
            {header?.title}
          </span>
        ) : (
          <div className="flex flex-row items-center ">
            <img
              src={constants.DEFAULT_IMAGE + "/view_orders_back_img.png"}
              onClick={() => handleClick(accountMenu[4])}
              className="w-4 h-5 cursor-pointer"
              alt="orderbackimage"
            />
            <span className="font-Cormorant font-bold text-xl ps-2">
              {header?.title}
            </span>
          </div>
        )}
        {header?.actionTitle !== null && (
          <button
            onClick={() => {
              handleModel(header?.title, true);
            }}
            className={`border  text-[16px] p-2 font-medium ${
              header?.title === constants.ManageAddress ||
              header?.title === constants.Wishlist
                ? "bg-customGreen text-white"
                : "bg-inherit border-[#262626]"
            }`}
          >
            {header?.title === constants.ManageAddress ? (
              <span className="flex flex-row justify-center items-center">
                <img
                  src={constants.DEFAULT_IMAGE + "/AddAddress.svg"}
                  className="mx-2 w-5 h-5"
                  alt="add address"
                />
                <span>{header?.actionTitle}</span>
              </span>
            ) : (
              <span>{header?.actionTitle}</span>
            )}
          </button>
        )}
      </section>
      <section>
        <Accountpageindividualcontent
          showContent={header?.title}
          handleModel={handleModel}
          onClick={handleButtonChange}
          handleClick={handleClick}
        />
      </section>
    </main>
  );
}

export default Accountpagecontent;
