import { ACTION_TYPE } from "./type";
import { getImage, formatDate } from "../../utilities/helper";
import {
  fetchOrders,
  useraddressess,
  payment_order_failed,
  resendorderemail,
  updateUserProfile,
  editpassword,
  removewishlistproducts,
  removeUserAddress,
} from "../../utilities/api";
import Config from "../../utilities/config";
import {
  myaccount_address_wishlist,
  addUserAddress,
} from "../../utilities/api";

import constants from "../../utilities/constants";
import config from "../../utilities/config";
import { Toast } from "../../components/Atoms/Toast";
// constants.DEFAULT_IMAGE + "/video_img1.png"
export const updatePersonalInformation = (
  userData,
  token,
  personalInfo,
  userProfile_id
) => {
  return async (dispatch) => {
    if (!token) {
      console.error("No token provided");
      return;
    }
    try {
      // Dispatch a loading action to show the user is waiting
      dispatch({ type: ACTION_TYPE.EDIT_PERSIONAL_INFORMATION_REQUEST });

      // Call the updateUserProfile function and wait for it to finish
      const response = await updateUserProfile(userData, token, personalInfo);

      // Dispatch success action with the response
      dispatch({
        type: ACTION_TYPE.EDIT_PERSIONAL_INFORMATION_REQUEST_SUCCESS,
        payload: { persionalInformationeditSuccess: response.data },
      });
      dispatch(getPersionalData(userProfile_id));
      Toast("Personal Information Updated Successfully", "success");
      // You can also handle other actions here, like navigating to another page, etc.
    } catch (error) {
      console.error("Error updating profile:", error);
      Toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something Went to Wrong Try Again Later",
        "error"
      );

      // Dispatch error action if the API call fails
      dispatch({
        type: ACTION_TYPE.EDIT_PERSIONAL_INFORMATION_REQUEST_FAILURE,
        errorMessage: error?.response?.data?.message,
      });
    }
  };
};
//change user password
export const updateuserpassword = (userpassword, token, personalInfo) => {
  return async (dispatch) => {
    if (!token) {
      console.error("No token provided");
      return;
    }
    try {
      // Dispatch a loading action to show the user is waiting
      dispatch({ type: ACTION_TYPE.EDIT_USER_PASSWORD_REQUEST });

      // Call the updateUserProfile function and wait for it to finish
      const response = await editpassword(userpassword, token, personalInfo);

      // Dispatch success action with the response
      dispatch({
        type: ACTION_TYPE.EDIT_USER_PASSWORD_REQUEST_SUCCESS,
        payload: { persionalInformationeditSuccess: response.data },
      });
      Toast("Password Updated Successfully", "success");
      // You can also handle other actions here, like navigating to another page, etc.
    } catch (error) {
      console.error("Error updating password:", error);
      Toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something Went to Wrong Try Again Later",
        "error"
      );
      // Dispatch error action if the API call fails
      dispatch({
        type: ACTION_TYPE.EDIT_USER_PASSWORD_REQUEST_FAILURE,
        errorMessage:
          error?.response?.data?.message || "Failed to update password",
      });
    }
  };
};
//remove adddress
export const removeaddress = (id) => {
  return async (dispatch) => {
    // if (!token) {
    //   console.error("No token provided");
    //   return;
    // }
    try {
      // Dispatch a loading action to show the user is waiting
      dispatch({ type: ACTION_TYPE.REMOVE_ADDRESS_REQUEST });

      // Call the updateUserProfile function and wait for it to finish
      const response = await removeUserAddress(id);

      // Dispatch success action with the response
      dispatch({
        type: ACTION_TYPE.REMOVE_ADDRESS_SUCCESS,
        payload: { removeaddressmessage: response.data.message },
      });
      Toast("Address Deleted Successfully", "success");

      // You can also handle other actions here, like navigating to another page, etc.
    } catch (error) {
      console.error("Error updating password:", error);
      Toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something Went to Wrong Try Again Later",
        "error"
      );
      // Dispatch error action if the API call fails
      dispatch({
        type: ACTION_TYPE.REMOVE_ADDRESS_FAILURE,
        errorMessage:
          error?.response?.data?.message || "Failed to remove address",
      });
    }
  };
};
export const getPersionalData = (userprofileid) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST }); //personal information setted to initial value
  dispatch({ type: ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST }); //personal address setted to initial value
  dispatch({ type: ACTION_TYPE.GET_WISH_LIST_DATA_REQUEST }); //wishlist information setted to initial value
  dispatch({ type: ACTION_TYPE.GET_ALL_ORDERS_DATA_REQUEST }); //all orders information setted to initial value
  // dispatch({ type: ACTION_TYPE.GET_SINGLE_ORDER_DATA_REQUEST });//single order information setted to initial value
  try {
    const { data: api_data } = await myaccount_address_wishlist(userprofileid);
    const { allUserAddresses, userProfileById } = api_data?.data || [];
    const {
      userWhislistsByUserprofileId: userwishlist,
      ordersByUserProfileId: orders,
      pincode,
      countryCode,
      email,
      salutation,
      mobile,
      firstName,
      country,
      lastName,
      dateofbirth,
      anniversarydate,
    } = userProfileById || [];
    const wishList_data = userwishlist?.nodes?.map(
      (wishlistproducts, index) => {
        return {
          action_btn1: "Move to Cart",
          action_btn2: "Remove",
          image:
            getImage(
              wishlistproducts?.productListByProductId?.productImagesByProductId
                ?.nodes[0]?.imageUrl,
              "200X200"
            ) || "",
          title: wishlistproducts?.productListByProductId?.productName || "",
          price: wishlistproducts?.transSkuListBySkuId?.markupPrice || "",
          url: wishlistproducts?.transSkuListBySkuId?.skuUrl || "",
          generatedsku: wishlistproducts?.skuId || "",
          productid: wishlistproducts?.productId || "",
        };
      }
    );
    const data = [
      {
        label: "Title",
        value: salutation || "",
        key: 1,
      },
      {
        label: "First Name",
        value: firstName || "-",
        key: 2,
      },
      {
        label: "Last Name",
        value: lastName || "-",
        key: 3,
      },
      // {
      //   label: "Country",
      //   value: country || "-",
      //   key: 4,
      // },
      {
        label: "Date of Birth", //dateofbirth,anniversarydate
        value: dateofbirth ? formatDate(dateofbirth, "DDth Month YYYY") : "",
        key: 5,
        formatval: dateofbirth,
      },
      {
        label: "Anniversary Date",
        value: anniversarydate
          ? formatDate(anniversarydate, "DDth Month YYYY")
          : "",
        formatval: anniversarydate,
        key: 6,
      },
      {
        label: "Mobile Number",
        value: mobile || "-",
        key: 7,
      },
      {
        label: "Email Address",
        value: email || "-",
        key: 8,
      },
      {
        label: "Password",
        value: "****",
        key: 9,
      },
      {
        label: "Zip code",
        value: pincode || "-",
        key: "101",
      },
      {
        label: "country_code",
        value: countryCode || "",
        key: "102",
      },
    ];
    const addressdata = allUserAddresses?.nodes?.map((addressdatum, index) => {
      return {
        id: addressdatum?.id,
        cilentName:
          addressdatum?.firstname + " " + addressdatum?.lastname ||
          "gowrishankar",
        primary: addressdatum?.isprimary || false,
        address1:
          addressdatum?.addressline1 ||
          "No 14 tholkpiar street, katangulathur chennai",
        // address2:addressdatum?.contactno || "maraimalainagar1",
        address3:
          addressdatum?.city ||
          "NA" + ", " + addressdatum?.state ||
          "NA" + ", " + addressdatum?.pincode ||
          "chengalpattu, Tamil Nadu, 603209",
        address4: addressdatum?.country || "NA",
        mobile: addressdatum?.contactno || "NA",
        pincode: addressdatum?.pincode || "NA",
        state: addressdatum?.state || "NA",
        city: addressdatum?.city || "NA",
      };
    });
    const orderdata = [];
    // orders.nodes.forEach(order => {
    //   const { createdAt, orderStatus, shoppingCartByCartId, id } = order;
    //   const orderDate = formatDate(createdAt, 'DD Month YYYY');

    //   const items = shoppingCartByCartId.shoppingCartItemsByShoppingCartId.nodes;

    //   // Create a new object for each order
    //   const orderObject = {
    //     orderId: id,
    //     orderdate: orderDate,
    //     status: [orderStatus]
    //   };

    //   items.forEach((item, index) => {
    //     const { productSku, qty, transSkuListByProductSku } = item;
    //     const { markupPrice, productListByProductId } = transSkuListByProductSku;
    //     const { productName, productImagesByProductId } = productListByProductId;
    //     const image = productImagesByProductId.nodes.length > 0 ? getImage(productImagesByProductId.nodes[0].imageUrl, "200X200") : constants.DEFAULT_NO_PRODUCT_IMAGE;

    //     const orderItem = {
    //       image: image,
    //       title: productName,
    //       amount: `₹${markupPrice.toLocaleString()}`,
    //       ordernumber: productSku
    //     };

    //     // If there's only one product, add it directly to the order object
    //     if (items.length === 1) {
    //       Object.assign(orderObject, orderItem);
    //     } else {
    //       // If there's more than one product, add a "Sliders" property
    //       if (!orderObject.Sliders) {
    //         orderObject.Sliders = [];
    //       }
    //       orderObject.Sliders.push(orderItem);
    //     }
    //   });

    //   // Push the order object to the orderdata array
    //   orderdata.push(orderObject);
    // });
    function convertToTargetFormat(jsonData) {
      const result = [];

      jsonData.nodes.forEach((node) => {
        const shoppingCartItems =
          node.shoppingCartByCartId.shoppingCartItemsByShoppingCartId.nodes;

        // Create the main object
        const mainObject = {
          image: shoppingCartItems[0].transSkuListByProductSku
            .productListByProductId.productImagesByProductId.nodes[0]?.imageUrl
            ? getImage(
                shoppingCartItems[0].transSkuListByProductSku
                  .productListByProductId.productImagesByProductId.nodes[0]
                  ?.imageUrl
              )
            : constants.DEFAULT_NO_PRODUCT_IMAGE,
          status: [node.paymentStatus],
          title:
            shoppingCartItems[0].transSkuListByProductSku.productListByProductId
              .productName,
          amount: `${shoppingCartItems[0].transSkuListByProductSku.markupPrice}`,
          ordernumber: node.id,
          orderId: node.id,
          orderdate: new Date(node.createdAt).toLocaleDateString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
          }),
        };

        // Check if there are multiple items to create a Sliders object
        if (shoppingCartItems.length > 1) {
          mainObject.Sliders = shoppingCartItems.map((item) => ({
            image: item.transSkuListByProductSku.productListByProductId
              .productImagesByProductId.nodes[0]?.imageUrl
              ? getImage(
                  item.transSkuListByProductSku.productListByProductId
                    .productImagesByProductId.nodes[0]?.imageUrl
                )
              : constants.DEFAULT_NO_PRODUCT_IMAGE,
            status: [node.paymentStatus],
            title:
              item.transSkuListByProductSku.productListByProductId.productName,
            amount: `${item.transSkuListByProductSku.markupPrice}`,
            ordernumber: node.id,
            orderdate: new Date(node.createdAt).toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            }),
          }));
        }

        result.push(mainObject);
      });

      return result;
    }

    const convertedData = convertToTargetFormat(orders);
    console.log(convertedData, "converteddata");
    //personal information
    dispatch({
      type: ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST_SUCCESS,
      payload: { persionalInformationDataSuccess: data },
    });
    //wishlist
    dispatch({
      type: ACTION_TYPE.GET_WISH_LIST_DATA_SUCCESS,
      payload: { wishListDataSuccess: wishList_data },
    });
    //address
    dispatch({
      type: ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST_SUCCESS,
      payload: { persionalAddressDataSuccess: addressdata },
    });
    //all orders
    dispatch({
      type: ACTION_TYPE.GET_ALL_ORDERS_DATA_SUCCESS,
      payload: { allOrdersDataSuccess: convertedData },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
    //address
    dispatch({
      type: ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
    //wishlist
    dispatch({
      type: ACTION_TYPE.GET_WISH_LIST_DATA_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
    //all orders
    dispatch({
      type: ACTION_TYPE.GET_ALL_ORDERS_DATA_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
//ADD OR EDIT USER ADDRESS
export const addoredit_useraddress =
  (address, user_id, id = null) =>
  async (dispatch) => {
    dispatch({ type: ACTION_TYPE.ADD_USER_ADDRESS_REQUEST });

    try {
      // Call the addUserAddress function and await its result
      const result = await addUserAddress(address, user_id, id);
      console.log(result, "result for add address..");
      // If the promise resolves, dispatch the success action
      dispatch({
        type: ACTION_TYPE.ADD_USER_ADDRESS_SUCCESS,
        payload: result,
      });
      dispatch(getPersionalData(user_id));
      if (id) {
        Toast("Address Updated Successfully", "success");
      } else {
        Toast("Address Added Successfully", "success");
      }
    } catch (error) {
      // If the promise rejects, dispatch the failure action
      Toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something Went to Wrong Try Again Later",
        "error"
      );
      console.log(error, "errorinaddaddressoreditaddress");
      dispatch({
        type: ACTION_TYPE.ADD_USER_ADDRESS_FAIL,
        errorMessage: error,
      });
    }
  };

//remove wishlist products
export const removewishlistfromstore =
  (userprofileid = null, product_id, product_sku, wishlistdata) =>
  async (dispatch) => {
    config.logGeneral && console.log("callledapitoremove");
    let wishlistdata_removed = wishlistdata?.filter(
      (a) => a.productid !== product_id
    );

    dispatch({ type: ACTION_TYPE.REMOVE_WISHLIST_REQUEST }); //personal information setted to initial value
    dispatch({
      type: ACTION_TYPE.GET_WISH_LIST_DATA_SUCCESS,
      payload: { wishListDataSuccess: wishlistdata_removed },
    });

    try {
      if (userprofileid) {
        const { data } = await removewishlistproducts(
          userprofileid,
          product_id,
          product_sku
        );
        dispatch({
          type: ACTION_TYPE.REMOVE_WISHLIST_SUCCESS,
          payload: { removewishlistdata: data },
        });
      } else {
        dispatch({
          type: ACTION_TYPE.REMOVE_WISHLIST_SUCCESS,
          payload: {
            removewishlistdata: `Successfully product(${product_sku}) is removed from wishlist!!!`,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.REMOVE_WISHLIST_FAILURE,
        errorMessage: error?.response?.data?.message,
      });
    }
  };

export const getPersionalAddress = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST });
  try {
    const data = [
      {
        cilentName: "gowrishankar",
        primary: true,
        address1: "No 14 tholkpiar street, katangulathur chennai",

        address2: "maraimalainagar1",
        address3: "chengalpattu, Tamil Nadu, 603209",
        address4: "India1",
        mobile: "8056157840",
      },
      {
        cilentName: "viki",
        primary: false,
        address1: "No 14 tholkpiar street, katangulathur chennai",

        address2: "maraimalainagar2",
        address3: "chengalpattu, Tamil Nadu, 603209",
        address4: "India2",
        mobile: "8056157841",
      },
      {
        cilentName: "krithi",
        primary: false,

        address1: "No 14 tholkpiar street, katangulathur chennai",

        address2: "maraimalainagar3",
        address3: "chengalpattu, Tamil Nadu, 603209",
        address4: "India3",
        mobile: "8056157842",
      },
      {
        cilentName: "krithika",
        primary: false,

        address1: "No 14 tholkpiar street, katangulathur chennai",

        address2: "maraimalainagar4",
        address3: "chengalpattu, Tamil Nadu, 603209",
        address4: "India4",
        mobile: "8056157843",
      },
    ];

    dispatch({
      type: ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST_SUCCESS,
      payload: { persionalAddressDataSuccess: data },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getWishList = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_WISH_LIST_DATA_REQUEST });
  try {
    const data = [
      {
        image: constants.DEFAULT_IMAGE + "/wishlist_img1.png",
        title: "Sherina Avikam Short Necklace Set",
        price: "₹ 46,700",
        action_btn1: "Move to Cart",
        action_btn2: "Remove",
      },
      {
        image: constants.DEFAULT_IMAGE + "/wishlist_img2.png",
        title: "Sherina Avikam Short Necklace Set",
        price: "₹ 46,700",
        action_btn1: "Move to Cart",
        action_btn2: "Remove",
      },
      {
        image: constants.DEFAULT_IMAGE + "/wishlist_img3.png",
        title: "Sherina Avikam Short Necklace Set",
        price: "₹ 46,700",
        action_btn1: "Move to Cart",
        action_btn2: "Remove",
      },
      {
        image: constants.DEFAULT_IMAGE + "/wishlist_img4.png",
        title: "Sherina Avikam Short Necklace Set",
        price: "₹ 46,700",
        action_btn1: "Move to Cart",
        action_btn2: "Remove",
      },
    ];

    dispatch({
      type: ACTION_TYPE.GET_WISH_LIST_DATA_SUCCESS,
      payload: { wishListDataSuccess: data },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.GET_WISH_LIST_DATA_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getAllOrders = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_ALL_ORDERS_DATA_REQUEST });
  try {
    const data = [
      {
        image: constants.DEFAULT_IMAGE + "/order_img1.png",
        status: ["Shipped"],
        title: "CHIC TWEETY GOLD EARRINGS",
        amount: "₹4,096",
        ordernumber: "SBA12009-92570000",
        orderdate: "06 March 2024",
        Sliders: [
          {
            image: constants.DEFAULT_IMAGE + "/order_img1.png",
            status: ["Shipped"],
            title: "CHIC TWEETY GOLD EARRINGS",
            amount: "₹4,096",
            ordernumber: "SBA12009-92570000",
            orderdate: "06 March 2024",
          },
          {
            image: constants.DEFAULT_IMAGE + "/order_img2.png",
            status: ["Cancelled"],
            title: "IMANI GOLD MEN RING",
            amount: "₹4,096",
            ordernumber: "SBA12009-92570000",
            orderdate: "06 March 2024",
          },
          {
            image: constants.DEFAULT_IMAGE + "/order_img2.png",
            status: ["Delivered"],
            title: "NAP & SONS",
            amount: "₹4,096",
            ordernumber: "SBA12009-92570000",
            orderdate: "06 March 2024",
          },
          {
            image: constants.DEFAULT_IMAGE + "/order_img2.png",
            status: ["Cancelled", "Refunded"],
            title: "IMANI GOLD MEN RING",
            amount: "₹4,096",
            ordernumber: "SBA12009-92570000",
            orderdate: "06 March 2024",
          },
        ],
      },
      {
        image: constants.DEFAULT_IMAGE + "/order_img2.png",
        status: ["Cancelled"],
        title: "IMANI GOLD MEN RING",
        amount: "₹4,096",
        ordernumber: "SBA12009-92570000",
        orderdate: "06 March 2024",
      },
      {
        image: constants.DEFAULT_IMAGE + "/order_img2.png",
        status: ["Delivered"],
        title: "NAP & SONS",
        amount: "₹4,096",
        ordernumber: "SBA12009-92570000",
        orderdate: "06 March 2024",
      },
      {
        image: constants.DEFAULT_IMAGE + "/order_img2.png",
        status: ["Cancelled", "Refunded"],
        title: "IMANI GOLD MEN RING",
        amount: "₹4,096",
        ordernumber: "SBA12009-92570000",
        orderdate: "06 March 2024",
      },
    ];

    dispatch({
      type: ACTION_TYPE.GET_ALL_ORDERS_DATA_SUCCESS,
      payload: { allOrdersDataSuccess: data },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.GET_ALL_ORDERS_DATA_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getSingleOrder = (orderId) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_SINGLE_ORDER_DATA_REQUEST });
  try {
    const response = await fetchOrders(orderId);
    const allOrders = response.data.data.allOrders.nodes[0];
    const data = {
      OrderNumber: allOrders.id,
      DeliveryDate: formatDate(allOrders.createdAt, "DDth Month YYYY"),
      PaymentMode:
        allOrders.paymentDetailsByOrderId.nodes[0] &&
        allOrders.paymentDetailsByOrderId.nodes[0].paymentResponse
          ? JSON.parse(
              allOrders.paymentDetailsByOrderId.nodes[0].paymentResponse
            ).CHMOD
          : allOrders.paymentMode,
      ShippingAddress: [
        {
          name:
            allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
              .firstname +
            " " +
            allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
              .lastname,
          address1:
            allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
              .addressline1,
          address2:
            allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0].city,
          address3: allOrders.shoppingCartByCartId.cartAddressesByCartId
            .nodes[0].state
            ? allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
                .state +
              ", " +
              allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
                .pincode
            : allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
                .pincode,
          address4:
            allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
              .country,
          mobile:
            allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
              .contactNumber,
        },
      ],
      BillingAddress: [
        {
          name:
            allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
              .firstname +
            " " +
            allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
              .lastname,
          address1:
            allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
              .addressline1,
          address2:
            allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0].city,
          address3: allOrders.shoppingCartByCartId.cartAddressesByCartId
            .nodes[0].state
            ? allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
                .state +
              ", " +
              allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
                .pincode
            : allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
                .pincode,
          address4:
            allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
              .country,
          mobile:
            allOrders.shoppingCartByCartId.cartAddressesByCartId.nodes[0]
              .contactNumber,
        },
      ],
      OrderItems:
        allOrders.shoppingCartByCartId.shoppingCartItemsByShoppingCartId.nodes.map(
          (item) => {
            return {
              //item.transSkuListByProductSku.productListByProductId.productImagesByProductId.nodes[0].imageUrl
              image:
                item.transSkuListByProductSku.productListByProductId
                  ?.productImagesByProductId?.nodes?.length > 0
                  ? getImage(
                      item.transSkuListByProductSku.productListByProductId
                        .productImagesByProductId.nodes[0].imageUrl,
                      "200X200"
                    )
                  : constants.DEFAULT_NO_PRODUCT_IMAGE, //productImagesByProductId.nodes.length > 0 ? getImage(productImagesByProductId.nodes[0].imageUrl, "200X200") : constants.DEFAULT_NO_PRODUCT_IMAGE;
              title:
                item.transSkuListByProductSku.productListByProductId
                  .productName,
              price: "" + item.transSkuListByProductSku.discountPrice,
              quantity: item.qty.toString(),
              productcode: item.transSkuListByProductSku.generatedSku,
              goldweight:
                item.transSkuListByProductSku.skuWeight.toString() + " GM",
              Metal: item.transSkuListByProductSku.metalColor,
            };
          }
        ),
      Subtotal: "" + allOrders.shoppingCartByCartId.discountedPrice,
      CGST: "0.00",
      SGST: "0.00",
      Shipping:
        allOrders.shoppingCartByCartId.shippingCharge === 0
          ? "FREE"
          : "" + allOrders.shoppingCartByCartId.shippingCharge,
      ShippingInsurance: "FREE",
      CoupeApplied: "0.00",
      GrantTotal: "" + allOrders.shoppingCartByCartId.discountedPrice,
    };

    dispatch({
      type: ACTION_TYPE.GET_SINGLE_ORDER_DATA_SUCCESS,
      payload: { singleOrderDataSuccess: [data] },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.GET_SINGLE_ORDER_DATA_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getShoppingDetails = () => async (dispatch) => {
  try {
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_ALL_ORDERS_DATA_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_SINGLE_ORDER_DATA_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_WISH_LIST_DATA_SUCCESS,
    // });
  } catch (error) {}
};
export const getRedeemPointsDetails = () => async (dispatch) => {
  try {
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_ALL_ORDERS_DATA_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_SINGLE_ORDER_DATA_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_WISH_LIST_DATA_SUCCESS,
    // });
  } catch (error) {}
};
export const logoutWebsite = () => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_TYPE.CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS,
    });
    dispatch({
      type: ACTION_TYPE.CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS,
    });
    dispatch({
      type: ACTION_TYPE.CLEAR_ALL_ORDERS_DATA_SUCCESS,
    });
    dispatch({
      type: ACTION_TYPE.CLEAR_SINGLE_ORDER_DATA_SUCCESS,
    });
    dispatch({
      type: ACTION_TYPE.CLEAR_WISH_LIST_DATA_SUCCESS,
    });
    dispatch({
      type: ACTION_TYPE.CART_DATA_RESPONSE_CLEAR,
    });
    dispatch({
      type: ACTION_TYPE.CLEAR_CART_COUNT,
    });
    //   dispatch({ type: ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST });//personal information setted to initial value
    // dispatch({ type: ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST });//personal address setted to initial value
    // dispatch({ type: ACTION_TYPE.GET_WISH_LIST_DATA_REQUEST });//wishlist information setted to initial value
    // dispatch({ type: ACTION_TYPE.GET_ALL_ORDERS_DATA_REQUEST });
  } catch (error) {}
};
