import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import constant from "../utilities/constants";
import { Link, useParams } from "react-router-dom";
import {
  getOrderSuccessPage,
  resend_order_mail,
} from "../redux/action/orderAction";
import {
  formatCurrency,
  combineValues,
  formatDate,
  getValue,
} from "../utilities/helper";
import Modal from "react-modal";

import constants from "../utilities/constants";
// import { resendorderemail,shipping_charge} from "../utilities/api"

//function to change date format...
// function formatDate(dateString) {
//   const date = new Date(dateString);

//   // Define arrays for day and month names
//   const day = date.getDate();
//   const monthNames = [
//     "January", "February", "March", "April", "May", "June",
//     "July", "August", "September", "October", "November", "December"
//   ];
//   const month = monthNames[date.getMonth()];
//   const year = date.getFullYear();

//   // Format the day with suffix
//   const daySuffix = (day) => {
//     if (day >= 11 && day <= 13) return `${day}th`;
//     switch (day % 10) {
//       case 1: return `${day}st`;
//       case 2: return `${day}nd`;
//       case 3: return `${day}rd`;
//       default: return `${day}th`;
//     }
//   };

//   // Format the time in 12-hour format with AM/PM
//   let hours = date.getHours();
//   const minutes = date.getMinutes().toString().padStart(2, '0');
//   const ampm = hours >= 12 ? 'PM' : 'AM';
//   hours = hours % 12 || 12; // Convert to 12-hour format

//   return `${daySuffix(day)} ${month} ${year}`;
// }
//function to get time alone for order id...
// function formatTime(dateString) {
//   const date = new Date(dateString);

//   // Extract hours and minutes
//   let hours = date.getHours();
//   const minutes = date.getMinutes().toString().padStart(2, '0');
//   const ampm = hours >= 12 ? 'PM' : 'AM';

//   // Convert to 12-hour format
//   hours = (hours % 12 || 12).toString().padStart(2, '0');;

//   return `${hours}:${minutes}${ampm}`;
// }

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Thanks() {
  // Modal

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const dispatch = useDispatch();
  const { orderId } = useParams();
  const {
    orderResponsesLoading,
    orderResponseSuccess,
    orderResponseError,
    reSendOrderMailLoading,
    reSendOrderMailSuccess,
    reSendOrderMailFail,
  } = useSelector((state) => state.orderReducer);

  useEffect(() => {
    dispatch(getOrderSuccessPage(orderId));
  }, [dispatch, orderId]);

  if (orderResponsesLoading) {
    return <div className="bg-blue-500 text-white p-6">Loading data...</div>;
  }

  if (orderResponseError) {
    return (
      <div className="bg-red-500 text-white p-6">
        Error: {orderResponseError}
      </div>
    );
  }

  // Check if we have the data structure to avoid errors
  if (
    orderResponseSuccess &&
    orderResponseSuccess.data?.data?.allOrders?.nodes[0]
  ) {
    const orderData = orderResponseSuccess.data.data.allOrders.nodes[0];
    const shoppingCart = orderData.shoppingCartByCartId;
    const subtotal = shoppingCart?.discountedPrice || "NA";
    const formattedAmount = subtotal !== "NA" ? formatCurrency(subtotal) : "₹0";
    //get the product info... from shoppingCartItemsByShoppingCartId
    const productInfo =
      orderData?.shoppingCartByCartId?.shoppingCartItemsByShoppingCartId?.nodes;
    const productName = combineValues(
      productInfo,
      "transSkuListByProductSku.productListByProductId.productName",
      " & "
    );
    const productskuIds = combineValues(
      productInfo,
      "transSkuListByProductSku.generatedSku",
      " & "
    );
    const discountPrice = combineValues(
      productInfo,
      "transSkuListByProductSku.discountPrice",
      ","
    )
      .split(",")
      .reduce((acc, curr) => acc + parseFloat(curr), 0);
    const MarkPrice = combineValues(
      productInfo,
      "transSkuListByProductSku.sellingPrice",
      ","
    )
      .split(",")
      .reduce((acc, curr) => acc + parseFloat(curr), 0);
    const discountshow = Math.floor(discountPrice - MarkPrice).toLocaleString(
      "en-IN"
    );
    const orderdate = orderData?.createdAt;
    const cartId = orderData?.cartId;
    const shippingCharge =
      formatCurrency(shoppingCart?.shippingCharge) || "Free";

    //DDth Month YYYY at HH:mm AM/PM
    const orderDate =
      orderdate && formatDate(orderdate, "DDth Month YYYY  HH:mm AM/PM");
    //ship by date
    const shipDate =
      orderdate &&
      formatDate(orderdate, "DDth DayOfWeek", constant.shipping_days);
    //delivery date
    const deliveryDate =
      orderdate && formatDate(orderdate, "DD-MM-YYYY", constant.shipping_days);
    // const orderDateTime=orderdate && formatTime(orderdate);
    const cartAddress = shoppingCart?.cartAddressesByCartId?.nodes[0];
    return (
      // <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-lg">
      //   <h3 className="text-3xl font-semibold text-center mb-6">Order Summary!!!</h3>
      //   <div className="space-y-4">
      //     <p className="text-lg">
      //       <strong>Order ID:</strong> {orderData.id}
      //     </p>
      //     <p className="text-lg">
      //       <strong>Payment Mode:</strong> {orderData.paymentMode}
      //     </p>
      //     <p className="text-lg">
      //       <strong>Product Name:</strong> {product?.productListByProductId?.productName || 'N/A'}
      //     </p>
      //     <p className="text-lg">
      //       <strong>Product Type:</strong> {product?.productListByProductId?.productType || 'N/A'}
      //     </p>
      //     <p className="text-lg">
      //       <strong>Price:</strong> ₹{product?.discountPrice || 'N/A'}
      //     </p>
      //     <p className="text-lg">
      //       <strong>Quantity:</strong> {shoppingCart?.shoppingCartItemsByShoppingCartId?.nodes[0]?.qty || 'N/A'}
      //     </p>
      //     <p className="text-lg">
      //       <strong>Delivery Address:</strong> {cartAddress?.addressline1}, {cartAddress?.city} - {cartAddress?.pincode}
      //     </p>
      //     <p className="text-lg">
      //       <strong>Contact Number:</strong> {cartAddress?.contactNumber || 'N/A'}
      //     </p>
      //     <p className="text-lg">
      //       <strong>Image URL:</strong> {productImage?.imageUrl || 'N/A'}
      //     </p>
      //   </div>
      //   <p className="mt-4 text-center text-gray-600">{fulldata}</p>
      // </div>
      <>
        <div class="bg-custom-gradient">
          <div class="container mx-auto px-4">
            <div class="grid grid-cols-12">
              <div class="col-span-12">
                <div className="pt-9 pb-14">
                  <svg
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mx-auto w-1/2"
                  >
                    <g clip-path="url(#clip0_1224_59)">
                      <path
                        d="M46.5527 46.5588C56.8024 36.309 56.8024 19.691 46.5527 9.44125C36.303 -0.808465 19.6849 -0.808464 9.43521 9.44125C-0.8145 19.691 -0.8145 36.309 9.43521 46.5588C19.6849 56.8085 36.303 56.8085 46.5527 46.5588Z"
                        fill="#46CC6B"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M53.8721 32.4533C52.0043 43.382 43.3815 52.0044 32.4531 53.8723L18.3217 39.741C14.9401 36.9503 12.7849 32.727 12.7849 28.0002C12.7849 19.597 19.5968 12.7852 28 12.7852C32.7267 12.7852 36.95 14.9405 39.7407 18.3219L53.8721 32.4533Z"
                        fill="#179C5F"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20.8781 28.9036L25.2113 32.8829C25.8434 33.4648 26.8218 33.4338 27.4162 32.8209L35.1467 25.4194C35.7755 24.8148 35.7946 23.8144 35.1897 23.1853C34.5849 22.5572 33.5851 22.5375 32.9563 23.1421L26.2601 29.5533L23.0188 26.5769C22.3767 25.9861 21.3762 26.0275 20.785 26.6703C20.194 27.3125 20.2356 28.3123 20.8781 28.9036ZM28 12.7852C36.4031 12.7852 43.215 19.597 43.215 28.0002C43.215 36.4034 36.4031 43.2153 28 43.2153C19.5968 43.2153 12.7849 36.4034 12.7849 28.0002C12.7849 19.597 19.5968 12.7852 28 12.7852Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1224_59">
                        <rect width="56" height="56" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p className="font-roboto text-center font-medium text-[#222222] my-2 text-[26px]">
                    Thank you for your purchase!
                  </p>
                  <p className="font-roboto text-center font-normal text-[#222222] text-base">
                    Your order{" "}
                    <span className="text-[#20B4A9] text-base font-bold">
                      #{orderData.id}
                    </span>{" "}
                    has been successfully placed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white">
          <div class="container mx-auto lg:px-28 px-0 2xl:mb-16">
            <div class="lg:grid lg:grid-flow-col xs:grid-cols-12 gap-4">
              <div class="lg:col-span-2 sm:col-span-1 lg:mb-0 sm:mb-4">
                <div className="border-solid border border-slate-300 lg:px-7 lg:py-11 sm:px-7 sm:py-8">
                  <div className="flex mb-3 items-center gap-2">
                    <p className="font-roboto flex items-center gap-2 font-medium text-xl text-[#222222]">
                      Your order is confirmed
                    </p>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1124_143)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8 16C3.58243 16 0 12.4176 0 8C0 3.58243 3.58243 0 8 0C12.4176 0 16 3.58243 16 8C16 12.4176 12.4176 16 8 16ZM6.89934 9.40762L11.7001 4.60693C11.8662 4.44076 12.139 4.44246 12.3034 4.60693L12.8478 5.15131C13.0123 5.31578 13.0123 5.59024 12.8478 5.75465L7.20945 11.3931C7.1086 11.4939 6.96693 11.5336 6.8342 11.5108C6.74337 11.4994 6.65575 11.4595 6.58734 11.3912L3.15269 7.95654C2.98854 7.79238 2.98658 7.51931 3.15269 7.35313L3.69707 6.80876C3.86324 6.64265 4.1343 6.64265 4.30047 6.80876L6.89934 9.40762Z"
                          fill="#222222"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1124_143">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  <div className="">
                    <h3 className="font-roboto font-normal text-[#222222] text-sm mb-3">
                      We appreciate you selecting{" "}
                      <span className="font-roboto text-[#20B4A9] text-sm font-bold">
                        Stylori!
                      </span>{" "}
                      We eagerly anticipate your enjoyment of your new item.
                    </h3>
                    <h3 className="font-roboto font-normal text-[#222222] text-sm mb-3">
                      We’ve sent you an email confirmation &nbsp;
                      {/* <span className='text-blue-700 font-bold underline'>Resend email</span> */}
                      {/* <button onClick={openModal}>Open Modal</button> */}
                      <a
                        href="#"
                        className="text-[#0B35CB] font-bold underline underline-offset-2"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(resend_order_mail(orderId));
                          openModal();
                        }}
                      >
                        Resend email
                      </a>
                    </h3>
                    <Modal
                      isOpen={modalIsOpen}
                      onAfterOpen={afterOpenModal}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div className="">
                        <div className="flex justify-center mb-5">
                          <img
                            src={constants.DEFAULT_IMAGE + "/email.svg"}
                            className="w-1/4"
                          />
                        </div>
                        <div className="flex justify-center mb-5">
                          <h2
                            ref={(_subtitle) => (subtitle = _subtitle)}
                            className="text-[#222222]"
                          >
                            Your Mail Sent Successfully!
                          </h2>
                          {/* <button onClick={closeModal}><img src={close} /></button> */}
                        </div>
                        <div className="text-center">
                          <Link to="/">
                            <button className="bg-[#06ab9f] text-white py-2 px-4 rounded-lg">
                              Go Home
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
              <div class="lg:col-span-2 sm:col-span-1 lg:mb-0 sm:mb-4">
                <div className="border-solid border border-slate-300 px-7 py-10 sm:px-7 sm:py-10">
                  <p className="font-roboto font-medium text-xl text-[#222222] mb-6">
                    Customer Details
                  </p>
                  <div className=" mb-6">
                    <h4 className="font-roboto font-medium text-base text-[#222222]">
                      Email Address
                    </h4>
                    <p className="font-roboto text-sm font-normal text-[#222222]">
                      georgevinoth09@gmail.com
                    </p>
                  </div>

                  <div className="grid grid-cols-12">
                    <div className="col-span-12 lg:col-span-6 lg:px-4 2xl:pr-10 2xl:pl-0 mb-4">
                      <h5 className="font-roboto text-base font-medium text-[#222222] mb-2">
                        Billing Address
                      </h5>
                      <p className="font-roboto text-sm font-normal text-[#222222] mb-1">
                        {cartAddress?.firstname} {cartAddress?.lastname}
                      </p>
                      <p className="font-roboto text-sm font-normal text-[#222222] mb-1">
                        {cartAddress?.addressline1 || "NA"}
                      </p>
                      <p className="font-roboto text-sm font-normal text-[#222222] mb-1">
                        {cartAddress?.city || "NA"} -{" "}
                        {cartAddress?.pincode || "NA"}
                      </p>
                      <p className="font-roboto text-sm font-normal text-[#222222]">
                        {cartAddress?.userProfileByUserprofileId?.email || "NA"}
                      </p>
                    </div>
                    <div className="col-span-12 lg:col-span-6 lg:px-4 2xl:pr-10 2xl:pl-0 mt-[20px] sm:mt-0 mb-4">
                      <h5 className="font-roboto text-base font-medium text-[#222222] mb-2">
                        Shipping address
                      </h5>
                      <p className="font-roboto text-sm font-normal text-[#222222] mb-1">
                        {cartAddress?.firstname} {cartAddress?.lastname}
                      </p>
                      <p className="font-roboto text-sm font-normal text-[#222222] mb-1">
                        {cartAddress?.addressline1 || "NA"}
                      </p>
                      <p className="font-roboto text-sm font-normal text-[#222222] mb-1">
                        {cartAddress?.city || "NA"} -{" "}
                        {cartAddress?.pincode || "NA"}
                      </p>
                      <p className="font-roboto text-sm font-normal text-[#222222]">
                        {cartAddress?.userProfileByUserprofileId?.email || "NA"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lg:row-span-3 sm:row-span-1 lg:mb-0 sm:mb-4">
                <div className="border-solid border border-slate-300 p-7 lg:pt-10">
                  <div className="flex items-center pb-4">
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1127_161)">
                        <path
                          d="M5.41634 15.8333H3.74967C3.51967 15.8333 3.33301 15.6467 3.33301 15.4167C3.33301 15.1867 3.51967 15 3.74967 15H5.41634C5.64634 15 5.83301 15.1867 5.83301 15.4167C5.83301 15.6467 5.64634 15.8333 5.41634 15.8333Z"
                          fill="black"
                        />
                        <path
                          d="M18.9588 15.834H17.9171C17.6871 15.834 17.5005 15.6473 17.5005 15.4173C17.5005 15.1873 17.6871 15.0007 17.9171 15.0007H18.613L19.1746 12.0073C19.1671 10.4757 17.8588 9.16732 16.2505 9.16732H13.5146L12.1888 15.0007H14.5838C14.8138 15.0007 15.0005 15.1873 15.0005 15.4173C15.0005 15.6473 14.8138 15.834 14.5838 15.834H11.6671C11.5405 15.834 11.4205 15.7765 11.3413 15.6773C11.2621 15.579 11.2321 15.449 11.2605 15.3257L12.7755 8.65898C12.8188 8.46815 12.9871 8.33398 13.1821 8.33398H16.2505C18.318 8.33398 20.0005 10.0165 20.0005 12.084L19.368 15.494C19.3313 15.6915 19.1596 15.834 18.9588 15.834Z"
                          fill="black"
                        />
                        <path
                          d="M16.2503 17.5007C15.102 17.5007 14.167 16.5665 14.167 15.4173C14.167 14.2682 15.102 13.334 16.2503 13.334C17.3987 13.334 18.3337 14.2682 18.3337 15.4173C18.3337 16.5665 17.3987 17.5007 16.2503 17.5007ZM16.2503 14.1673C15.5612 14.1673 15.0003 14.7282 15.0003 15.4173C15.0003 16.1065 15.5612 16.6673 16.2503 16.6673C16.9395 16.6673 17.5003 16.1065 17.5003 15.4173C17.5003 14.7282 16.9395 14.1673 16.2503 14.1673Z"
                          fill="black"
                        />
                        <path
                          d="M7.08333 17.5007C5.935 17.5007 5 16.5665 5 15.4173C5 14.2682 5.935 13.334 7.08333 13.334C8.23167 13.334 9.16667 14.2682 9.16667 15.4173C9.16667 16.5665 8.23167 17.5007 7.08333 17.5007ZM7.08333 14.1673C6.39417 14.1673 5.83333 14.7282 5.83333 15.4173C5.83333 16.1065 6.39417 16.6673 7.08333 16.6673C7.7725 16.6673 8.33333 16.1065 8.33333 15.4173C8.33333 14.7282 7.7725 14.1673 7.08333 14.1673Z"
                          fill="black"
                        />
                        <path
                          d="M5.41699 8.33333H2.08366C1.85366 8.33333 1.66699 8.14667 1.66699 7.91667C1.66699 7.68667 1.85366 7.5 2.08366 7.5H5.41699C5.64699 7.5 5.83366 7.68667 5.83366 7.91667C5.83366 8.14667 5.64699 8.33333 5.41699 8.33333Z"
                          fill="black"
                        />
                        <path
                          d="M5.41634 10.8333H1.24967C1.01967 10.8333 0.833008 10.6467 0.833008 10.4167C0.833008 10.1867 1.01967 10 1.24967 10H5.41634C5.64634 10 5.83301 10.1867 5.83301 10.4167C5.83301 10.6467 5.64634 10.8333 5.41634 10.8333Z"
                          fill="black"
                        />
                        <path
                          d="M5.41667 13.3333H0.416667C0.186667 13.3333 0 13.1467 0 12.9167C0 12.6867 0.186667 12.5 0.416667 12.5H5.41667C5.64667 12.5 5.83333 12.6867 5.83333 12.9167C5.83333 13.1467 5.64667 13.3333 5.41667 13.3333Z"
                          fill="black"
                        />
                        <path
                          d="M11.6663 15.834H8.74967C8.51967 15.834 8.33301 15.6473 8.33301 15.4173C8.33301 15.1873 8.51967 15.0007 8.74967 15.0007H11.3338L13.2272 6.66732H3.74967C3.51967 6.66732 3.33301 6.48065 3.33301 6.25065C3.33301 6.02065 3.51967 5.83398 3.74967 5.83398H13.7497C13.8763 5.83398 13.9963 5.89148 14.0755 5.99065C14.1547 6.08898 14.1847 6.21898 14.1563 6.34232L12.073 15.509C12.0297 15.6998 11.8605 15.834 11.6663 15.834Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1127_161">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <p className="pl-3 font-roboto font-medium text-[#222222] text-sm underline underline-offset-2">
                      Ships By {shipDate}
                    </p>
                  </div>
                  <p className="font-roboto font-normal text-[#222222] text-sm pb-4">
                    Shipping Method: [Standard / Express Shipping]
                  </p>
                  <p className="font-roboto font-normal text-[#222222] text-sm">
                    Estimated Delivery Date: {deliveryDate}
                  </p>
                  <hr className=" border-[#CFD4E5] my-6 rounded-lg" />
                  <p className="font-roboto font-medium text-[#222222] text-xl pb-3">
                    {productName}
                  </p>
                  <p className="font-roboto font-normal text-[#222222] text-sm pb-4">
                    {productskuIds}
                  </p>
                  <p className="font-roboto font-normal text-[#222222] text-sm">
                    Date: {orderDate}
                    {/* <span>{orderDateTime}</span> */}
                  </p>
                  <hr className="border-[#CFD4E5] my-6 rounded-lg" />
                  <div className="flex justify-between pb-4">
                    <div>
                      <p className="font-roboto font-normal text-[#222222] text-sm pb-4">
                        Subtotal
                      </p>
                      <p className="font-roboto font-normal text-[#222222] text-sm pb-4">
                        Discount
                      </p>
                      <p className="font-roboto font-normal text-[#222222] text-sm pb-4">
                        Shipping Charges
                      </p>
                      <p className="font-roboto font-normal text-[#222222] text-sm pb-4">
                        Payment Mode
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="mb-2 text-[15px] text-gray-700">
                        {formattedAmount}
                      </p>
                      <p className="mb-2 text-[15px] text-gray-700">
                        ₹{discountshow}
                      </p>
                      <p className="font-roboto font-bold text-[#32B205] text-sm pb-4">
                        {shippingCharge}
                      </p>
                      <p className="mb-2 text-[15px] text-gray-700">
                        {orderData.paymentMode}
                      </p>
                    </div>
                  </div>
                  <div className="border border-[#CFD4E5] rounded-md cursor-pointer duration-100 p-3 text-center">
                    <Link className="text-center text-[#222222] text-base font-normal font-roboto">
                      Your Order Savings{" "}
                      <span className="text-base font-roboto font-bold text-[#32B205]">
                        ₹{discountshow}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-7">
            <h5 className="text-[#20B4A9] text-xs font-normal pb-3">
              Thank you for shopping with us!
            </h5>
            <p className="text-[#8F8F8F] text-xs font-normal">
              We look forward to serving you again.
            </p>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="bg-yellow-500 text-white p-6">
        No data available for this order.
      </div>
    );
  }
}

export default Thanks;
