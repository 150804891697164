import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../src/components/Atoms/Toast";
import { accountMenu } from "../utilities/helper";
import constants from "../utilities/constants";
import {
  getPersionalData,
  getAllOrders,
  getPersionalAddress,
  getRedeemPointsDetails,
  removewishlistfromstore,
  getShoppingDetails,
  getSingleOrder,
  getWishList,
  logoutWebsite,
} from "../redux/action/myAccountAction";
import {
  Update_Cart_Latest_Price,
  Add_To_Cart,
  getCartpagedata_count,
} from "../redux/action/cartAction";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Autoplay, Pagination } from "swiper/modules";
import { formatCurrencywithamount } from "../utilities/helper";
import "../styles/styles.css";
import Button from "./Atoms/Button";
import Slider from "./Slider";

function Accountpageindividualcontent({
  showContent,
  handleModel,
  onClick,
  handleClick,
}) {
  const dispatch = useDispatch();
  // const [skuId,setskuId]=(null)
  const { accessToken, userProfile_id, userProfile_email, cartID } =
    useSelector((state) => state.authReducer);
  const {
    //persionalinformation
    persionalInformationDataLoading,
    persionalInformationDataSuccess,
    persionalInformationDataError,
    //address
    persionalAddressDataLoading,
    persionalAddressDataSuccess,
    persionalAddressDataError,
    //wishlist
    wishListDataLoading,
    wishListDataSuccess,
    wishListDataError,
    //all orders
    allOrdersDataLoading,
    allOrdersDataSuccess,
    allOrdersDataError,
    //single order
    singleOrderDataLoading,
    singleOrderDataSuccess,
    singleOrderDataError,
  } = useSelector((state) => state.myAccountReducer);
  const { selectedcountrySuccess, dailyMetalPriceSuccess } = useSelector(
    (state) => state.homeReducer
  );

  const [visibleItems, setVisibleItems] = useState(4);
  const [loading, setLoading] = useState(false);
  const lastItemRef = useRef(null);
  const observer = useRef(null);
  console.log("allOrdersDataSuccess123", allOrdersDataSuccess);

  useEffect(() => {
    if (!lastItemRef?.current) return;

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting) {
          loadMoreItems();
        }
      },
      { threshold: 1 }
    );

    observer.current.observe(lastItemRef.current);

    return () => observer.current?.disconnect();
  }, [visibleItems, wishListDataSuccess]);
  // useEffect(() => {
  //   // Fetch the latest personal data when the component mounts
  //   if (userProfile_id) {
  //     dispatch(getPersionalData(userProfile_id));
  //   }
  // }, [dispatch, userProfile_id, persionalInformationDataSuccess]);
  const removewishlsit = (generatedsku, price, productid) => {
    console.log(
      generatedsku,
      "generatedsku",
      price,
      "price",
      productid,
      "productid"
    );
    if (userProfile_id) {
      // call removewishlistfromstore
      dispatch(
        removewishlistfromstore(
          userProfile_id,
          productid,
          generatedsku,
          wishListDataSuccess
        )
      );

      // dispatch(getPersionalData(userProfile_id));
    }
  };
  let cartAdd = (generatedSku, markupPrice, productid) => {
    // Check if generatedSku and markupPrice are provided
    if (!generatedSku || !markupPrice) {
      Toast("Product data is missing", "error");
      return;
    }

    // Initialize cartDetails object
    let cartDetails = {
      cart_id: cartID,
      user_id: userProfile_id,
      products: [
        {
          sku_id: generatedSku,
          qty: 1,
          price: markupPrice,
        },
      ],
    };

    // Call the APIs to update the cart and its price
    cartID && dispatch(Update_Cart_Latest_Price(userProfile_id));
    dispatch(Add_To_Cart(userProfile_id, cartDetails.products, cartID));
    dispatch(
      removewishlistfromstore(
        userProfile_id,
        productid,
        generatedSku,
        wishListDataSuccess
      )
    );
    dispatch(getPersionalData(userProfile_id));

    // Alert the user that the cart has been updated
    Toast("Item added to cart successfully", "success");
  };

  const loadMoreItems = () => {
    // setVisibleItems((prev) => Math.min(prev + 5, wishListDataSuccess.length));

    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      setVisibleItems((prev) => Math.min(prev + 4, wishListDataSuccess.length));
      setLoading(false);
    }, 500);
  };

  return (
    <main className=" max-h-[700px] overflow-y-auto">
      {persionalInformationDataLoading ? (
        <div className="text-center py-6 flex flex-col items-center">
          <img
            src={constants.DEFAULT_IMAGE + "/loaderimagesilver.gif"}
            alt="loading logo"
            className="w-120 h-120"
          />
        </div>
      ) : (
        persionalInformationDataSuccess !== null &&
        showContent === constants.PersonalInformation && (
          <section className="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 py-6 pb-10">
            <div>
              {persionalInformationDataSuccess
                .filter((item) => item.key <= 6)
                .map((item) => (
                  <div
                    key={item.key}
                    className="text-[#224A47] font-medium text-lg p-2 lg:p-3 grid grid-cols-2 gap-1 lg:grid-cols-3 lg:gap-2"
                  >
                    <label className="lg:ps-5">{item.label}</label>

                    <span>
                      <span className="lg:pr-5">:</span>
                      <span className="text-lg text-[#262626] font-semibold">
                        {" "}
                        {item.value}
                      </span>
                    </span>
                  </div>
                ))}
            </div>
            <div>
              {persionalInformationDataSuccess
                .filter((item) => item.key > 6 && item.key < 100)
                .map((item) => (
                  <div
                    key={item.key}
                    className="text-[#224A47] font-medium text-lg py-2 lg:p-2 grid grid-cols-2 gap-1 lg:grid-cols-3 lg:gap-2"
                  >
                    <label className="lg:ps-5">{item.label}</label>
                    <span className="">
                      <span className="lg:pr-5">:</span>
                      <span className="text-lg text-[#262626] font-semibold">
                        {" "}
                        {item.value}
                      </span>
                      {/* {item.value !== "-" && ( */}
                      {item.label === "Password" && (
                        <span
                          onClick={() => handleModel(item.label, false)}
                          className="ps-3 underline text-customGreen cursor-pointer"
                        >
                          Change
                        </span>
                      )}
                    </span>
                  </div>
                ))}
            </div>
          </section>
        )
      )}
      {persionalAddressDataSuccess !== null &&
        showContent === constants.ManageAddress &&
        (Array.isArray(persionalAddressDataSuccess) &&
        persionalAddressDataSuccess.length > 0 ? (
          <>
            {persionalAddressDataSuccess.map((item) => (
              <div className="grid grid-cols-2 w-full flex-wrap justify-start items-center">
                <div className="col-span-1 border border-[#d9d9d970] rounded-tl-[16px] rounded-tr-[16px] m-2 h-[222px]">
                  <div className="border-b  border-b-[#D9D9D9] p-4 flex flex-row justify-between">
                    <div className="flex flex-row  justify-start items-center  ">
                      <label className="text-customGreen text-xl font-semibold">
                        {item?.cilentName}
                      </label>
                      {item.primary && (
                        <img
                          src={constants.DEFAULT_IMAGE + "/primary_img.png"}
                          alt="primary-img"
                          className="w-[100px] h-[30px] ps-1 "
                        />
                      )}
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() =>
                          handleModel(constants.AddressEdit, true, item)
                        }
                        className="m-1"
                      >
                        <img
                          src={constants.DEFAULT_IMAGE + "/Edit-icon.svg"}
                          alt="edit-icon"
                        />
                      </button>
                      <button
                        onClick={() =>
                          handleModel(
                            constants.AddressDelete,
                            constants.Confirmation,
                            item?.id
                          )
                        }
                        className="m-1"
                      >
                        <img
                          src={constants.DEFAULT_IMAGE + "/Delete-icon.svg"}
                          alt="delete-icon"
                        />
                      </button>
                    </div>
                  </div>
                  <span></span>
                  <section className="p-3 ">
                    <h6 className="pt-2 font-medium text-base text-[#262626]">
                      {item?.address1}
                    </h6>
                    <h6 className="pt-2 font-medium text-base text-[#262626]">
                      {item?.address2}
                    </h6>
                    <h6 className="pt-2 font-medium text-base text-[#262626]">
                      {item?.address3}
                    </h6>
                    <h6 className="pt-2 font-medium text-base text-[#262626]">
                      {item?.address4}
                    </h6>
                  </section>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="text-center py-6 flex flex-col items-center">
            {/* SVG Image from Path */}
            <img
              src={constants.DEFAULT_IMAGE + "/no-product.svg"}
              alt="Sorry, No Wishlist Found"
              className="w-120 h-120"
            />
            <p className="text-xl font-semibold text-[#262626]">
              Sorry, No Address Found
            </p>
          </div>
        ))}
      {wishListDataSuccess !== null &&
        showContent === constants.Wishlist &&
        (Array.isArray(wishListDataSuccess) &&
        wishListDataSuccess.length > 0 ? (
          <section className="flex flex-row flex-wrap gap-4 mt-4 justify-center items-center">
            {wishListDataSuccess.slice(0, visibleItems).map((item, index) => (
              <>
                <div
                  className=" flex flex-col border border-transparent hover:border-[#BBBBBB] p-2"
                  ref={index === visibleItems - 1 ? lastItemRef : null}
                >
                  <img
                    src={item?.image}
                    className="w-72 h-72"
                    alt="wishlist-img"
                  />

                  <span className=" text-black text-[16px] pt-2 font-medium">
                    {item?.title}
                  </span>
                  <span className="text-[#676767] text-[14px]  font-medium">
                    {formatCurrencywithamount(
                      item?.price,
                      selectedcountrySuccess.currencyAlias,
                      `en-${selectedcountrySuccess.iso}`,
                      dailyMetalPriceSuccess?.data?.allMasterCountries?.nodes
                    )}
                  </span>
                  <div className="flex flex-row pt-3 ">
                    <Button
                      label={"Move to Cart"}
                      modelbtn={false}
                      variant="primary"
                      width="full"
                      // onClick={onClick}
                      onClick={() =>
                        cartAdd(
                          item?.generatedsku,
                          item?.price,
                          item?.productid
                        )
                      }
                    />
                    <Button
                      label={"Remove"}
                      modelbtn={false}
                      variant="secondary"
                      className=""
                      onClick={() =>
                        removewishlsit(
                          item?.generatedsku,
                          item?.price,
                          item?.productid
                        )
                      }
                    />
                  </div>
                </div>
              </>
            ))}
            {loading && (
              <div className="flex flex-nowrap w-full justify-center items-center text-center py-4">
                <span className="text-[#06AB9F]">Loading Data...</span>
              </div>
            )}
          </section>
        ) : (
          <div className="text-center py-6 flex flex-col items-center">
            {/* SVG Image from Path */}
            <img
              src={constants.DEFAULT_IMAGE + "/no-product.svg"}
              alt="Sorry, No Wishlist Found"
              className="w-120 h-120"
            />
            <p className="text-xl font-semibold text-[#262626]">
              Sorry, No Wishlist Found
            </p>
          </div>
        ))}
      {allOrdersDataSuccess !== null &&
        showContent === constants.AllOrders &&
        (Array.isArray(allOrdersDataSuccess) &&
        allOrdersDataSuccess.length > 0 ? (
          <section className="flex flex-row flex-wrap  ">
            {allOrdersDataSuccess.map((item, index) => (
              <Slider
                items={item}
                handleClick={handleClick}
                TickImg={constants.DEFAULT_IMAGE + "/allorder_tick_img.png"}
              />
            ))}
            {allOrdersDataSuccess.map((item, index) => (
              <>
                {item?.title !== "" && !item?.Sliders && (
                  <div
                    onClick={() =>
                      handleClick({
                        title: constants.SingleOrder,
                        actionTitle: null,
                        orderId: item?.orderId,
                      })
                    }
                    style={{ boxShadow: " rgba(0, 0, 0, 0.13)" }}
                    className="flex   flex-row md:w-[580px]  h-[180px]  m-2 p-2 rounded-lg justify-start items-center cursor-pointer  border "
                  >
                    <div className="me-3">
                      <img
                        src={item?.image}
                        className="w-40 h-40"
                        alt="allorder-img"
                      />
                    </div>
                    <div className="py-5">
                      {item?.status?.map((item) => (
                        <span className="relative">
                          <span
                            className={`me-2 p-2  rounded-[50px] text-[12px] font-semibold ${
                              item === "Shipped"
                                ? "bg-[#FF9933] text-white"
                                : item === "Cancelled"
                                ? " bg-[#00AA63] text-white"
                                : item === "Refunded" || item === "Delivered"
                                ? "bg-[#1E91CF] text-white pe-7"
                                : null
                            }`}
                          >
                            {item}
                          </span>

                          {(item === "Refunded" || item === "Delivered") && (
                            <img
                              src={
                                constants.DEFAULT_IMAGE +
                                "/allorder_tick_img.png"
                              }
                              className="w-4 h-4  absolute right-2 transition -translate-x-2 -translate-y-5 "
                              alt="tickimg"
                            />
                          )}
                        </span>
                      ))}

                      <span className="block mt-2 font-Cormorant font-bold text-xl text-[#262626]">
                        {item?.title}
                      </span>
                      <span className="block text-[#262626] font-bold text-xl">
                        {formatCurrencywithamount(
                          item?.amount,
                          selectedcountrySuccess.currencyAlias,
                          `en-${selectedcountrySuccess.iso}`,
                          dailyMetalPriceSuccess?.data?.allMasterCountries
                            ?.nodes
                        )}
                      </span>
                      <div className="bg-[#EBEBEB] border-b w-[400px] mt-2"></div>
                      <span className="block text-[14px] font-medium mt-2 text-[#262626]">
                        Order Number :
                        <span className="underline text-[#4D4D4D]">
                          {item?.ordernumber}
                        </span>
                      </span>
                      <span className="block text-[13px] font-medium text-[#262626]">
                        Ordered Date :{item?.orderdate}
                      </span>
                    </div>
                  </div>
                )}
              </>
            ))}
          </section>
        ) : (
          <div className="text-center py-6 flex flex-col items-center">
            {/* SVG Image from Path */}
            <img
              src={constants.DEFAULT_IMAGE + "/no-product.svg"}
              alt="Sorry, No Product Found"
              className="w-120 h-120"
            />
            <p className="text-xl font-semibold text-[#262626]">
              Sorry, No Order Found
            </p>
          </div>
        ))}
      {singleOrderDataSuccess !== null &&
        showContent === constants.SingleOrder && (
          <section className="lg:grid lg:grid-cols-3 lg:gap-5 p-3">
            {singleOrderDataSuccess.map((item) => (
              <>
                <div className="py-3 relative col-span-2">
                  <span className="text-base font-medium  text-[#262626]">
                    Order Number :{item?.OrderNumber}
                  </span>
                  <div className="flex flex-row items-center mt-2">
                    <span className="text-2xl  text-[#20A75F] font-semibold">
                      Order Delivered Date:{item?.DeliveryDate}
                    </span>
                    <img
                      src={constants.DEFAULT_IMAGE + "/delivery_tick_img.png"}
                      className="w-6 h-6 ms-2"
                      alt="delivery tick img"
                    />
                  </div>
                  <span className="text-base mt-2 font-medium text-[#262626]">
                    Payment Mode:
                    <span className="font-bold">{item?.PaymentMode}</span>
                  </span>
                  <div className="grid grid-cols-2 justify-start xl:justify-between pt-8 pb-5">
                    <section className="col-span-1">
                      <span className="font-semibold text-xl">
                        Shipping Address
                      </span>
                      {item?.ShippingAddress?.map((data) => (
                        <div className="flex flex-col justify-between mt-2  text-base font-medium text-[#262626]">
                          <span>{data?.name}</span>
                          <span>{data?.address1}</span>
                          <span>{data?.address2}</span>
                          <span>{data?.address3}</span>
                          <span>{data?.address4}</span>
                          <span>T:{data?.mobile}</span>
                        </div>
                      ))}
                    </section>
                    <section className="xl:ms-5 col-span-1">
                      <span className="font-semibold text-xl">
                        Billing Address
                      </span>
                      {item?.BillingAddress?.map((data) => (
                        <div className="flex flex-col justify-start mt-2  xl:justify-between text-base font-medium text-[#262626]">
                          <span>{data?.name}</span>
                          <span>{data?.address1}</span>
                          <span>{data?.address2}</span>
                          <span>{data?.address3}</span>
                          <span>{data?.address4}</span>
                          <span>T:{data?.mobile}</span>
                        </div>
                      ))}
                    </section>
                  </div>
                  <div className="2xl:absolute lg:bottom-0 items-center justify-between flex flex-row flex-wrap  w-full">
                    <div className="flex justify-between lg:gap-0 gap-5 lg:pb-0 pb-4">
                      <img
                        src={
                          constants.DEFAULT_IMAGE + "/securedpayment_img.png"
                        }
                        className="w-44 h-8"
                        alt="secured payment img"
                      />
                      <img
                        src={constants.DEFAULT_IMAGE + "/cashfree_img.png"}
                        className="w-24 h-8 "
                        alt="cash free img"
                      />
                      <img
                        src={constants.DEFAULT_IMAGE + "/airpay_img.png"}
                        className="w-24 h-8"
                        alt="airpay img"
                      />
                    </div>
                    {/* <div className="flex flex-row bg-[#F1F1F1]  px-5 py-2 xl:me-8 items-center">
                      <img
                        src={constants.DEFAULT_IMAGE + "/invoice_download_img.png"}
                        className="w-5 h-5"
                        alt="invoice download img"
                      />
                      <span className="text-[#262626] ms-2">
                        Download Invoice
                      </span>
                    </div> */}
                  </div>
                </div>
                <div className=" col-span-1">
                  <div className="h-96  overflow-y-auto">
                    {item?.OrderItems?.map((data) => (
                      <div className="flex h-36 justify-start md:justify-normal items-center">
                        <img
                          src={data?.image}
                          className="w-32 h-32"
                          alt="order imges"
                        />
                        <div className="flex justify-between">
                          <div className="flex flex-col mx-2">
                            <p className="text-base font-semibold overflow-x-auto text-[#262626]">
                              {data?.title}
                            </p>
                            <p className="text-[#4D4D4D]   text-sm">
                              Product Code:{data?.productcode}
                            </p>
                            <p className="text-[#4D4D4D]  text-sm">
                              Gold Weight (Gm) :{data?.goldweight}
                            </p>
                            <p className="text-[#4D4D4D]  text-sm">
                              Metal :{data?.Metal}
                            </p>
                          </div>
                          <div className="flex flex-col ">
                            <p className="text-right text-base">
                              {formatCurrencywithamount(
                                data?.price,
                                selectedcountrySuccess.currencyAlias,
                                `en-${selectedcountrySuccess.iso}`,
                                dailyMetalPriceSuccess?.data?.allMasterCountries
                                  ?.nodes
                              )}
                            </p>
                            <p className="text-[#4D4D4D] text-sm">
                              Quantity:{data?.quantity}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    <div className="bg-[#DDDDDD] border border-b"></div>
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-col space-y-1 mt-2">
                        <p className="text-sm font-semibold">Subtotal</p>
                        {/* <p className="text-sm font-semibold">CGST (1.5%)</p>
                        <p className="text-sm font-semibold">SGST (1.5%)</p> */}
                        <p className="text-sm font-semibold">Shipping</p>
                        {/* <span className="text-sm font-semibold">
                          Shipping Insurance
                        </span> */}
                        <p className="text-sm font-semibold">Coupon Applied</p>
                        <p className="mt-1 text-lg font-semibold">
                          Grant Total
                        </p>
                      </div>
                      <div className="flex flex-col space-y-1 mt-2 text-right">
                        <p className="text-sm font-semibold">
                          {formatCurrencywithamount(
                            item?.Subtotal,
                            selectedcountrySuccess.currencyAlias,
                            `en-${selectedcountrySuccess.iso}`,
                            dailyMetalPriceSuccess?.data?.allMasterCountries
                              ?.nodes
                          )}
                        </p>
                        {/* <p className="text-sm font-semibold">
                          {formatCurrencywithamount(
                            item?.CGST,
                            selectedcountrySuccess.currencyAlias,
                            `en-${selectedcountrySuccess.iso}`,
                            dailyMetalPriceSuccess?.data?.allMasterCountries
                              ?.nodes
                          )}
                        </p>
                        <p className="text-sm font-semibold">
                          {formatCurrencywithamount(
                            item?.SGST,
                            selectedcountrySuccess.currencyAlias,
                            `en-${selectedcountrySuccess.iso}`,
                            dailyMetalPriceSuccess?.data?.allMasterCountries
                              ?.nodes
                          )}
                        </p> */}
                        <p className="text-sm font-semibold text-[#009A49]">
                          {formatCurrencywithamount(
                            item?.Shipping,
                            selectedcountrySuccess.currencyAlias,
                            `en-${selectedcountrySuccess.iso}`,
                            dailyMetalPriceSuccess?.data?.allMasterCountries
                              ?.nodes
                          )}
                        </p>
                        {/* <span className="text-sm font-semibold text-[#009A49]">
                          {item?.ShippingInsurance}
                        </span> */}
                        <p className="text-sm font-semibold text-[#009A49]">
                          {item?.CoupeApplied}
                        </p>
                        <p className=" mt-1 text-lg font-semibold">
                          {formatCurrencywithamount(
                            item?.GrantTotal,
                            selectedcountrySuccess.currencyAlias,
                            `en-${selectedcountrySuccess.iso}`,
                            dailyMetalPriceSuccess?.data?.allMasterCountries
                              ?.nodes
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </section>
        )}
    </main>
  );
}

export default Accountpageindividualcontent;
