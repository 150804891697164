import { ACTION_TYPE } from "../action/type";

const initialState = {
  //persional information
  persionalInformationDataLoading: false,
  persionalInformationDataSuccess: null,
  persionalInformationDataError: null,
  //address
  persionalAddressDataLoading: false,
  persionalAddressDataSuccess: null,
  persionalAddressDataError: null,
  //wishlist
  wishListDataLoading: false,
  wishListDataSuccess: null,
  wishListDataError: null,
  //all orders
  allOrdersDataLoading: false,
  allOrdersDataSuccess: null,
  allOrdersDataError: null,
  //single order
  singleOrderDataLoading: false,
  singleOrderDataSuccess: null,
  singleOrderDataError: null,
  //to update personal information
  personalinfoeditloading: false,
  personalinfoeditsuccess: null,
  personalinfoeditfail: null,
  //to update user password
  passwordeditloading: false,
  passwordeditsuccess: null,
  passwordeditfail: null,
  //remove wishlist
  removewishlistloading: false,
  removewishlistsuccess: null,
  removewishlisterror: null,
  //add address
  addressaddloading: false,
  addressaddsuccess: null,
  addressaddfail: null,
  //delete address
  deleteaddressloading: false,
  deleteaddresssuccess: null,
  deleteaddressfail: null,

};
export const myAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST:
      return {
        ...state,
        persionalInformationDataLoading: true,
        persionalInformationDataSuccess: null,
        persionalInformationDataError: null,
      };
    case ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST_SUCCESS:
      return {
        ...state,
        persionalInformationDataLoading: false,
        persionalInformationDataSuccess:
          action.payload.persionalInformationDataSuccess,
        persionalInformationDataError: null,
      };
    case ACTION_TYPE.CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS:
      return {
        ...state,
        persionalInformationDataLoading: false,
        persionalInformationDataSuccess: null,
        persionalInformationDataError: null,
      };
    //add or edit user address...
    case ACTION_TYPE.ADD_USER_ADDRESS_REQUEST:
      return {
        ...state,
        addressaddloading: true,
        addressaddsuccess: null,
        addressaddfail: null
      };
    case ACTION_TYPE.ADD_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        addressaddloading: false,
        addressaddsuccess: action.payload.result,
        addressaddfail: null
      };
    case ACTION_TYPE.ADD_USER_ADDRESS_FAIL:
      return {
        ...state,
        addressaddloading: false,
        addressaddsuccess: null,
        addressaddfail: action.errorMessage
      };
    //EDIT USER PASSWORD
    case ACTION_TYPE.EDIT_USER_PASSWORD_REQUEST:
      return {
        ...state,
        passwordeditloading: true,
        passwordeditsuccess: null,
        passwordeditfail: null,
      };
    case ACTION_TYPE.EDIT_USER_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        passwordeditloading: false,
        passwordeditsuccess: action.payload.successpasswordchange,
        passwordeditfail: null,
      };
    case ACTION_TYPE.EDIT_USER_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        passwordeditloading: false,
        passwordeditsuccess: null,
        passwordeditfail: action.errorMessage,
      };
    //WISHLIST REMOVE
    case ACTION_TYPE.REMOVE_WISHLIST_REQUEST:
      return {
        ...state,
        removewishlistloading: true,
        removewishlistsuccess: null,
        removewishlisterror: null
      };
    case ACTION_TYPE.REMOVE_WISHLIST_SUCCESS:
      return {
        ...state,
        removewishlistloading: null,
        removewishlistsuccess: action.payload.removewishlistdata,
        removewishlisterror: null
      };
    case ACTION_TYPE.REMOVE_WISHLIST_FAILURE:
      return {
        ...state,
        removewishlistloading: null,
        removewishlistsuccess: null,
        removewishlisterror: action.errorMessage,
      };
    //addresss REMOVE
    case ACTION_TYPE.REMOVE_ADDRESS_REQUEST:
      return {
        ...state,
        deleteaddressloading: true,
        deleteaddresssuccess: null,
        deleteaddressfail: null,
      };
    case ACTION_TYPE.REMOVE_ADDRESS_SUCCESS:
      return {
        ...state,
        deleteaddressloading: false,
        deleteaddresssuccess: action.payload.removeaddressmessage,
        deleteaddressfail: null,
      };
    case ACTION_TYPE.REMOVE_ADDRESS_FAILURE:
      return {
        ...state,
        deleteaddressloading: false,
        deleteaddresssuccess: null,
        deleteaddressfail: action.errorMessage,
      };
    //EDIT PERSONAL info request
    case ACTION_TYPE.EDIT_PERSIONAL_INFORMATION_REQUEST:
      return {
        ...state,
        personalinfoeditloading: true,
        personalinfoeditsuccess: null,
        personalinfoeditfail: null,
      };
    case ACTION_TYPE.EDIT_PERSIONAL_INFORMATION_REQUEST_SUCCESS:
      return {
        ...state,
        personalinfoeditloading: false,
        personalinfoeditsuccess: action.payload.persionalInformationeditSuccess,
        personalinfoeditfail: null,
      };
    case ACTION_TYPE.EDIT_PERSIONAL_INFORMATION_REQUEST_FAILURE:
      return {
        ...state,
        personalinfoeditloading: false,
        personalinfoeditsuccess: null,
        personalinfoeditfail: action.errorMessage,
      };
    case ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST_FAILURE:
      return {
        ...state,
        persionalInformationDataLoading: false,
        persionalInformationDataSuccess: null,
        persionalInformationDataError: action.errorMessage,
      };
    case ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST:
      return {
        ...state,
        persionalAddressDataLoading: true,
        persionalAddressDataSuccess: null,
        persionalAddressDataError: null,
      };
    case ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST_SUCCESS:
      return {
        ...state,
        persionalAddressDataLoading: false,
        persionalAddressDataSuccess: action.payload.persionalAddressDataSuccess,
        persionalAddressDataError: null,
      };
    case ACTION_TYPE.CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS:
      return {
        ...state,
        persionalAddressDataLoading: false,
        persionalAddressDataSuccess: null,
        persionalAddressDataError: null,
      };
    case ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST_FAILURE:
      return {
        ...state,
        persionalAddressDataLoading: false,
        persionalAddressDataSuccess: null,
        persionalAddressDataError: action.errorMessage,
      };
    case ACTION_TYPE.GET_WISH_LIST_DATA_REQUEST:
      return {
        ...state,
        wishListDataLoading: true,
        wishListDataSuccess: null,
        wishListDataError: null,
      };
    case ACTION_TYPE.GET_WISH_LIST_DATA_SUCCESS:
      return {
        ...state,
        wishListDataLoading: false,
        wishListDataSuccess: action.payload.wishListDataSuccess,
        wishListDataError: null,
      };
    case ACTION_TYPE.CLEAR_WISH_LIST_DATA_SUCCESS:
      return {
        ...state,
        wishListDataLoading: false,
        wishListDataSuccess: null,
        wishListDataError: null,
      };
    case ACTION_TYPE.GET_WISH_LIST_DATA_FAILURE:
      return {
        ...state,
        wishListDataLoading: false,
        wishListDataSuccess: null,
        wishListDataError: action.errorMessage,
      };
    case ACTION_TYPE.GET_ALL_ORDERS_DATA_REQUEST:
      return {
        ...state,
        allOrdersDataLoading: true,
        allOrdersDataSuccess: null,
        allOrdersDataError: null,
      };
    case ACTION_TYPE.GET_ALL_ORDERS_DATA_SUCCESS:
      return {
        ...state,
        allOrdersDataLoading: false,
        allOrdersDataSuccess: action.payload.allOrdersDataSuccess,
        allOrdersDataError: null,
      };
    case ACTION_TYPE.CLEAR_ALL_ORDERS_DATA_SUCCESS:
      return {
        ...state,
        allOrdersDataLoading: false,
        allOrdersDataSuccess: null,
        allOrdersDataError: null,
      };
    case ACTION_TYPE.GET_ALL_ORDERS_DATA_FAILURE:
      return {
        ...state,
        allOrdersDataLoading: false,
        allOrdersDataSuccess: null,
        allOrdersDataError: action.errorMessage,
      };
    case ACTION_TYPE.GET_SINGLE_ORDER_DATA_REQUEST:
      return {
        ...state,
        singleOrderDataLoading: true,
        singleOrderDataSuccess: null,
        singleOrderDataError: null,
      };
    case ACTION_TYPE.GET_SINGLE_ORDER_DATA_SUCCESS:
      return {
        ...state,
        singleOrderDataLoading: false,
        singleOrderDataSuccess: action.payload.singleOrderDataSuccess,
        singleOrderDataError: null,
      };
    case ACTION_TYPE.CLEAR_SINGLE_ORDER_DATA_SUCCESS:
      return {
        ...state,
        singleOrderDataLoading: false,
        singleOrderDataSuccess: null,
        singleOrderDataError: null,
      };
    case ACTION_TYPE.GET_SINGLE_ORDER_DATA_FAILURE:
      return {
        ...state,
        singleOrderDataLoading: false,
        singleOrderDataSuccess: null,
        singleOrderDataError: action.errorMessage,
      };

    default:
      return state;
  }
};
