import constants from "../../utilities/constants";
import { ACTION_TYPE } from "../action/type";
const initialState = {
  isLoggedIn: false,
  userProfile_id: null,
  loginResponse: null,
  cartID: null,
  userProfile_email: null,
  accessToken: null,
  loading: false,
  error: null,
  loginModalLoading: true,
  loginOpenModal: false,
  loginModalDefaultType: "login",
  loginModalType: "",
  authModalLoading: true,
  authModalType: null,
  authModalopen: false,
  signupLoading: false,
  signupSuccess: null,
  signupError: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION_TYPE.LOGIN_SUCCESS:
    console.log(action.payload,"action.payloadloginsuccesss")  
    return {
        ...state,
        loading: false,
        isLoggedIn: true,
        accessToken: action.payload.accessToken,
        userProfile_id: action.payload.userprofile.id,
        userProfile_email: action.payload.userprofile.email,
        cartID: action.payload.shoppingCartId,
        authModalopen: false,
        loginResponse: "success",
      };
    case ACTION_TYPE.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        loginResponse: "failure",
        error: action.payload,
      };
    case ACTION_TYPE.UPDATECART_ID:
      return {
        ...state,
        cartID: action.payload.newcartid
      };
    case ACTION_TYPE.CLEAR_LOGIN_RESPONSE:
      return { ...state, loginResponse: null };
    case ACTION_TYPE.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        userProfile_id: null,
        userProfile_email: null,
        accessToken: null,
        cartID: null,
      };
    case ACTION_TYPE.LOGIN_MODAL:
      return {
        ...state,
        loginModalLoading: false,
        loginOpenModal: action.payload.isOpenModal,
        loginModalType: action.payload.modalType,
      };
    case ACTION_TYPE.LOGIN_MODAL_TYPE:
      return {
        ...state,
        loginModalLoading: false,
        loginModalType: action.payload.modalType,
      };
    case ACTION_TYPE.AUTH_MODAL_TYPE_REQUEST:
      return {
        ...state,
        authModalLoading: true,
        authModalType: null,
      };
    case ACTION_TYPE.AUTH_MODAL_TYPE_SUCCESS:
      return {
        ...state,
        authModalLoading: true,
        authModalType: action.payload.authModalType,
        authModalopen: true,
      };
    case ACTION_TYPE.AUTH_MODAL_TYPE_CLEAR:
      return {
        ...state,
        authModalLoading: false,
        authModalType: null,
        authModalopen: false,
      };
    case ACTION_TYPE.SIGNUP_REQUEST:
      return {
        loading: true,
        error: null,
      };
    case ACTION_TYPE.SIGNUP_SUCCESS:
      return {
        loading: false,
        isLoggedIn: true,
        accessToken: action.payload.accessToken,
        userProfile_id: action.payload.user_profile_id,
        userProfile_email: action.payload.user.email,
        cartID: null,
        authModalopen: false,

        loginResponse: "signup_success",
      };
    case ACTION_TYPE.SIGNUP_ERROR:
      return {
        loading: false,
        userProfile_id: null,
        error: action.errorMessage,
        loginResponse: "signup_failure",
      };
    default:
      return state;
  }
};

export default authReducer;
