import { ACTION_TYPE } from "./type";
import {
  cart_products_detils,
  cart_products_count,
  you_may_like,
  removeCartItemData,
  updateCartLatestPrice,
  addToCart,
  forgotPasswordAPI,
  resetPasswordAPI,
  applyvoucher,
} from "../../utilities/api";
import { getValue } from "../../utilities/helper";
import Config from "../../utilities/config";
import constants from "../../utilities/constants";
import { Toast } from "../../components/Atoms/Toast";

const allTransSkuLists = {
  nodes: [
    {
      generatedSku: "SNLC10877-92570000",
      purity: "92.5",
      metalColor: "Silver",
      discountPrice: 33136.4,
      discount: null,
      minOrderQty: null,
      maxOrderQty: null,
      skuId: "1023573",
      markupPrice: 29822.760000000002,
      skuUrl:
        constants.ADDITIONAL_PATH +
        "/jewellery/long necklace/Erupting-Zircon Necklace Set?sku_id=1023573",
      productListByProductId: {
        productId: "SNLC10877",
        productName: "Erupting Zircon Necklace Set",
        isactive: true,
        productImagesByProductId: {
          nodes: [
            {
              imageUrl: "product/SNLC10877/SNLC10877-1S.jpg",
            },
          ],
        },
        productType: "Long Necklace",
        productMaterialsByProductSku: {
          nodes: [
            {
              materialName: "Silver",
            },
          ],
        },
        productDiamondsByProductSku: {
          nodes: [],
        },
        productGemstonesByProductSku: {
          nodes: [],
        },
      },
      skuWeight: 50.47,
      diamondType: null,
      skuSize: null,
    },
    {
      generatedSku: "SE11544-92570000",
      purity: "92.5",
      metalColor: "Gold",
      discountPrice: 7836.4,
      discount: null,
      minOrderQty: null,
      maxOrderQty: null,
      skuId: "1024257",
      markupPrice: 7052.759999999999,
      skuUrl:
        constants.ADDITIONAL_PATH +
        "/jewellery/earrings/Margaric-Colorful Ear Stud?sku_id=1024257",
      productListByProductId: {
        productId: "SE11544",
        productName: "Margaric Colorful Ear Stud",
        isactive: true,
        productImagesByProductId: {
          nodes: [
            {
              imageUrl: "product/SE11544/SE11544-1G.jpg",
            },
          ],
        },
        productType: "Earrings",
        productMaterialsByProductSku: {
          nodes: [
            {
              materialName: "Silver",
            },
          ],
        },
        productDiamondsByProductSku: {
          nodes: [],
        },
        productGemstonesByProductSku: {
          nodes: [],
        },
      },
      skuWeight: 15.54,
      diamondType: null,
      skuSize: null,
    },
  ],
};
export const clearvoucherdata = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.CLEAR_VOUCHER_SUCCESS });
};
export const getCartpagedata = (form_data) => async (dispatch) => {
  console.log(form_data, "form_data for getCartpagedata");
  dispatch({ type: ACTION_TYPE.GET_CART_DATA_REQUEST });

  try {
    // const  data  = await login(form_data);

    dispatch({
      type: ACTION_TYPE.GET_CART_DATA_RESPONSE_SUCCESS,
      payload: {
        cartResponseSuccess: allTransSkuLists,
      },
    });
  } catch (error) {
    Config.logGeneral && console.log("Error", error);
    dispatch({
      type: ACTION_TYPE.GET_CART_DATA_RESPONSE_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};

export const apply_voucher =
  (userProfile_id = null, cartID = null, promoCode = null) =>
  async (dispatch) => {
    dispatch({ type: ACTION_TYPE.VOUCHER_REQUEST });

    try {
      const data = await applyvoucher(userProfile_id, cartID, promoCode);

      dispatch({
        type: ACTION_TYPE.VOUCHER_SUCCESS,
        payload: {
          message: data.message,
          price_response: data.price_response,
        },
      });
    } catch (error) {
      Config.logGeneral && console.log("Error", error);
      dispatch({
        type: ACTION_TYPE.VOUCHER_FAILURE,
        errorMessage:
          error?.response?.data?.message || "Error in applying voucher",
      });
    }
  };

//TO GET CART COUNT...
export const getCartpagedata_count = (userid) => async (dispatch) => {
  Config.logGeneral && console.log(userid, "userid for getCartpagedata_count");
  dispatch({ type: ACTION_TYPE.CART_COUNT_REQUEST });

  try {
    const cart_count_data = await cart_products_count(userid);
    Config.logGeneral &&
      console.log(cart_count_data, "cart_count_data 77777777");

    dispatch({
      type: ACTION_TYPE.CART_COUNT_SUCCESS,
      payload: {
        cartCountResponseSuccess: cart_count_data?.data?.data?.allShoppingCarts,
        cartCountResponseSuccess_info: getValue(
          cart_count_data?.data?.data?.allShoppingCarts,
          "nodes.0.shoppingCartItemsByShoppingCartId.nodes"
        ),
        //getValue(cartItems, 'nodes.0.shoppingCartItemsByShoppingCartId.nodes');getValue(cart_count_data?.data?.data?.allShoppingCarts, 'nodes.0.shoppingCartItemsByShoppingCartId.nodes');
        // cartCountResponseSuccess:cart_count_data
      },
    });
  } catch (error) {
    Config.logGeneral && console.log("Error", error);
    dispatch({
      type: ACTION_TYPE.CART_COUNT_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};

//forgot password
export const forgotPassword = (email) => async (dispatch) => {
  console.log(email, "email for forgotPassword");

  dispatch({ type: ACTION_TYPE.FORGOT_PASSWORD_REQUEST });

  try {
    const forgotPasswordResponse = await forgotPasswordAPI(email); // Assuming forgotPasswordAPI is a function that makes the API call
    console.log(forgotPasswordResponse, "forgotPasswordResponse");

    dispatch({
      type: ACTION_TYPE.FORGOT_PASSWORD_SUCCESS,
      payload: {
        forgotpasswordmessage: forgotPasswordResponse?.message,
      },
    });
  } catch (error) {
    console.log("Error in forgotpasswor:", JSON.stringify(error));
    dispatch({
      type: ACTION_TYPE.FORGOT_PASSWORD_FAILURE,
      errorMessage: error,
    });
  }
};

//reset password
export const resetPassword = (password, token) => async (dispatch) => {
  console.log(password, "passwordandtokenforresetpassword", token);

  dispatch({ type: ACTION_TYPE.RESET_PASSWORD_REQUEST });

  try {
    const resetPasswordResponse = await resetPasswordAPI(password, token); // Assuming forgotPasswordAPI is a function that makes the API call
    console.log(resetPasswordResponse, "resetPasswordResponse");
    Toast(resetPasswordResponse, "succcess");
    dispatch({
      type: ACTION_TYPE.RESET_PASSWORD_SUCCESS,
      payload: {
        resetpasswordmessage: resetPasswordResponse,
      },
    });

    dispatch({
      type: ACTION_TYPE.AUTH_MODAL_TYPE_SUCCESS,
      payload: {
        authModalType: "login",
      },
    });
    window.location.href = `${constants.ADDITIONAL_PATH}/account`;
  } catch (error) {
    console.log("Error in resetpasswor:", JSON.stringify(error));
    dispatch({ type: ACTION_TYPE.RESET_PASSWORD_FAILURE, errorMessage: error });
    Toast("Password Reset Failed!!!", "error");
  }
};

//TO GET CART COUNT WITHOUT LOGIN...
export const getCartpagedata_count_without_login = () => async (dispatch) => {
  Config.logGeneral && console.log("getCartpagedata_count_without_login");
  dispatch({ type: ACTION_TYPE.CART_COUNT_REQUEST });

  try {
    // const cart_count_data_without_login = await cart_products_detils(["SBA11374-92570000", "SBA11212-92570000_2.3"]);
    const cart_count_data_without_login = await cart_products_detils([]);
    console.log(
      cart_count_data_without_login,
      "cart_count_data_without_login99"
    );
    let prod_details =
      cart_count_data_without_login?.data?.data?.allTransSkuLists?.nodes;
    let shipo = prod_details.map((e) => {
      return {
        qty: 1,
        productSku: e.generatedSku,
        price: e.markupPrice,
        comboMainProduct: null,
        transSkuListByProductSku: {
          discount: e.discount,
          discountPrice: e.discountPrice,
          generatedSku: e.generatedSku,
          isReadyToShip: e.isReadyToShip,
          markupPrice: e.markupPrice,
          purity: e.purity,
          skuSize: e.skuSize,
          skuUrl: e.skuUrl,
          productListByProductId: {
            productId: e.productListByProductId.productId,
            productName: e.productListByProductId.productName,
            productImagesByProductId:
              e.productListByProductId.productImagesByProductId,
          },
        },
      };
    });
    let gross_amt = prod_details.reduce((acc, cur, index) => {
      return acc + parseInt(cur.markupPrice);
    }, 0);
    let ship_format = {
      nodes: [
        {
          userprofileId: "",
          shippingCharge: 0, //if user not log in
          id: "",
          shoppingCartItemsByShoppingCartId: {},
          status: "pending",
          grossAmount: gross_amt,
          netAmount: gross_amt,
          discount: null,
          discountedPrice: gross_amt,
        },
      ],
    };
    ship_format.nodes[0].shoppingCartItemsByShoppingCartId.nodes = shipo;

    Config.logGeneral &&
      console.log(
        cart_count_data_without_login,
        "cart_count_data_without_login7"
      );
    Config.logGeneral && console.log(ship_format, "ship_format");

    dispatch({
      type: ACTION_TYPE.CART_COUNT_WITHOUT_LOGIN,
      payload: {
        cartCountResponseSuccess: ship_format,
        cartCountResponseSuccess_info: getValue(
          ship_format,
          "nodes.0.shoppingCartItemsByShoppingCartId.nodes"
        ),
      },
    });
    console.log("77777");
  } catch (error) {
    Config.logGeneral && console.log("Error", error);
    dispatch({
      type: ACTION_TYPE.CART_COUNT_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};

// Action to remove item from cart
export const removeCartItem =
  (cartId, productId, userid) => async (dispatch) => {
    try {
      // Call the API to remove the product from the cart
      const response = await removeCartItemData(cartId, productId, userid);

      if (response.data.message === "You removed this product successfully") {
        dispatch({
          type: ACTION_TYPE.REMOVE_CART_ITEM_SUCCESS,
          payload: productId, // Send the product ID that was removed
        });

        // After successfully removing the item, fetch updated cart items
        dispatch(getCartpagedata_count(userid));
      }
    } catch (error) {
      console.error("Error removing cart item:", error);
    }
  };

//TO REMOVE FROM CART WITHOUTLOGIN...
export const removeCartItemWithoutlogin = (productSku) => {
  return {
    type: ACTION_TYPE.REMOVE_CART_ITEM_SUCCESS_WITHOUT_LOGIN,
    payload: { productSku }, // The productSku of the item to remove
  };
};

//to updatecartlatestprice
export const Update_Cart_Latest_Price = (userprofileid) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.UPDATE_CART_LATEST_PRICE_REQUEST });
  try {
    const Update_Cart_Latest_Price_data = await updateCartLatestPrice(
      userprofileid
    );
    Config.logGeneral &&
      console.log(
        Update_Cart_Latest_Price_data,
        "Update_Cart_Latest_Price_data 9999999"
      );

    dispatch({
      type: ACTION_TYPE.UPDATE_CART_LATEST_PRICE_SUCCESS,
      payload: {
        updatecartmessage: Update_Cart_Latest_Price_data,
      },
    });
  } catch (error) {
    Config.logGeneral &&
      console.log("Error in Update_Cart_Latest_Price", error);
    dispatch({
      type: ACTION_TYPE.UPDATE_CART_LATEST_PRICE_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};

// to add products to cart...
export const Add_To_Cart = (user_id, products, cart_id) => async (dispatch) => {
  console.log("==>Add_To_Cartrunning");

  dispatch({ type: ACTION_TYPE.ADD_TO_CART_REQUEST });
  try {
    const addToCart_data = await addToCart(user_id, products, cart_id);

    Config.logGeneral && console.log(addToCart_data, "addToCart_data 9999999");

    dispatch({
      type: ACTION_TYPE.ADD_TO_CART_SUCCESS,
      payload: {
        addtocartresponsesuccesss: addToCart_data,
      },
    });
    dispatch(getCartpagedata_count(user_id));
    dispatch({
      type: ACTION_TYPE.UPDATECART_ID,
      payload: {
        newcartid: addToCart_data?.data?.cart_id,
      },
    });
  } catch (error) {
    Config.logGeneral && console.log("Error", error);
    dispatch({
      type: ACTION_TYPE.ADD_TO_CART_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};

// to get you may youMayLikeReducer...
export const youMayLikeData = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.YOU_MAY_LIKE_REQUEST });
  try {
    const youMayLike_data = await you_may_like();
    Config.logGeneral &&
      console.log(youMayLike_data, "youMayLike_data 77777777");

    dispatch({
      type: ACTION_TYPE.YOU_MAY_LIKE_SUCCESS,
      payload: {
        youmaylikeResponseSuccess: youMayLike_data,
      },
    });
  } catch (error) {
    Config.logGeneral && console.log("Error", error);
    dispatch({
      type: ACTION_TYPE.YOU_MAY_LIKE_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
