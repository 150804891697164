import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import { formatCurrencywithamount } from "../utilities/helper"; //formatCurrency(amount, currency = "INR", locale = "en-IN")
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Autoplay, Pagination } from "swiper/modules";
import "../styles/styles.css";
import constants from "../utilities/constants";

const Slider = ({ items, handleClick, TickImg }) => {
  const { selectedcountrySuccess, dailyMetalPriceSuccess } = useSelector(
    (state) => state.homeReducer
  );
  return (
    <div
      onClick={() =>
        handleClick({
          title: constants.SingleOrder,
          actionTitle: null,
          orderId: items?.orderId,
        })
      }
      className="h-full relative cursor-pointer  "
    >
      <Swiper
        direction="vertical"
        slidesPerView={1} // Show 3 slides at a time
        spaceBetween={10} // Spacing between slides
        freeMode={true}
        loop={true} // Infinite scroll
        autoplay={{
          delay: 2000, // Auto-scroll every 2 seconds
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          bulletClass: "swiper-pagination-bullet",
          bulletActiveClass: "swiper-pagination-bullet-active",
        }}
        modules={[FreeMode, Autoplay, Pagination]}
        className="max-md:mt-6 !h-[200px] w-full" // Small container
      >
        <section className="flex absolute   flex-row md:w-[580px]  m-2 p-2 rounded-lg justify-start items-center  ">
          {items.Sliders?.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                style={{ boxShadow: " rgba(0, 0, 0, 0.13)" }}
                className="flex flex-row  border  h-[180px] md:w-[580px] m-2 p-2 rounded-lg justify-start items-center"
              >
                <div className="me-3 ">
                  <img
                    src={item?.image}
                    className="max-md:!w-40 max-md:!h-40 w-40 h-40"
                    alt="allorder-img"
                  />
                </div>
                <div className="py-5  w-full flex flex-col  items-start ">
                  {item?.status?.map((item) => (
                    <span className="relative">
                      <span
                        className={`me-2 p-2  rounded-[50px] text-[12px]   font-semibold ${
                          item === "Shipped"
                            ? "bg-[#1E91CF] text-white"
                            : item === "Cancelled"
                            ? " bg-[#FF9933] text-white"
                            : item === "Refunded" || item === "Delivered"
                            ? "bg-[#00AA63] text-white pe-7"
                            : null
                        }`}
                      >
                        {item}
                      </span>

                      {(item === "Refunded" || item === "Delivered") && (
                        <img
                          src={TickImg}
                          className="w-4 h-4  absolute right-2 transition -translate-x-2 -translate-y-5 "
                          alt="tickimg"
                        />
                      )}
                    </span>
                  ))}

                  <span className="block mt-2 font-Cormorant font-bold text-xl">
                    {item?.title}
                  </span>
                  <span className="block text-[#262626] font-bold text-xl">
                    {formatCurrencywithamount(
                      item?.amount,
                      selectedcountrySuccess.currencyAlias,
                      `en-${selectedcountrySuccess.iso}`,
                      dailyMetalPriceSuccess?.data?.allMasterCountries?.nodes
                    )}
                  </span>
                  <div className="bg-[#EBEBEB] border-b w-[400px] mt-2"></div>
                  <span className="block text-[14px] font-medium mt-2 text-[#262626]">
                    Order Number :
                    <span className="underline text-[#4D4D4D]">
                      {item?.ordernumber}
                    </span>
                  </span>
                  <span className="block text-[13px] font-medium text-[#262626]">
                    Ordered Date :{item?.orderdate}
                  </span>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </section>
      </Swiper>
    </div>
  );
};

export default Slider;
