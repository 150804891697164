import React, { useState, useEffect } from "react";
import Textinput from "../../components/Atoms/Textinput";
import { Toast } from "../../components/Atoms/Toast";
import { fetchPincodeMasterData } from "../../utilities/api";
import Button from "../../components/Atoms/Button";
import constants from "../../utilities/constants";
import Datepic from "../../components/Atoms/Datepic";
import Dropdown from "../../components/Atoms/Dropdown";
import { useFormik } from "formik";
import Textarea from "../../components/Atoms/Textarea";
import Contact from "../../components/Atoms/Contact";
import Switch from "../../components/Atoms/Switch";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { parseISO, format } from "date-fns";
//../redux/action/myAccountAction
import {
  getPersionalData,
  getAllOrders,
  getPersionalAddress,
  getRedeemPointsDetails,
  getShoppingDetails,
  getSingleOrder,
  getWishList,
  addoredit_useraddress,
  logoutWebsite,
  updatePersonalInformation,
  updateuserpassword,
} from "../../redux/action/myAccountAction";
import PasswordInput from "../../components/Atoms/PasswordInput";
const Accountpagemodal = ({
  modelTitle,
  modelSubTitle,
  handleInputChange,
  handleButtonChange,
  showModelData,
  headerEditModal,
  value,
  setIsOpen,
  personalval,
  editManageAddressData,
}) => {
  const dispatch = useDispatch();

  console.log(
    value,
    "valuesss",
    modelTitle,
    "modelTitle",
    modelSubTitle,
    "modelSubTitle",
    showModelData,
    "showModelData",
    personalval,
    "personalval"
  );

  const { accessToken, userProfile_id, userProfile_email } = useSelector(
    (state) => state.authReducer
  );
  const { passwordeditfail, passwordeditsuccess } = useSelector(
    (state) => state.myAccountReducer
  );
  const { dailyMetalPriceSuccess } = useSelector((state) => state.homeReducer);
  const phonecode =
    dailyMetalPriceSuccess?.data?.allMasterCountries?.nodes?.map(
      (country, index) => {
        return `+${country?.phonecode}`;
      }
    );
  function convertArrayToObject(array) {
    const result = {};
    array?.forEach((item) => {
      result[item.label.split(" ").join("")] = item?.formatval || item?.value;
    });
    return result;
  }
  const personalArray = personalval && convertArrayToObject(personalval);
  const [pincodeData, setPincodeData] = useState([]);
  console.log(personalArray, "personalArray");
  var [personalInfo, setpersonalInfo] = useState(personalArray);
  console.log(personalInfo, "personalInfojhghh");

  const changeMobilenumber = [
    {
      label: "Old Mobile number",
      type: "text",
      placeholder: "Enter Old Mobile number",
      value: personalInfo?.MobileNumber,
      key: 1,
    },
    {
      label: "New Mobile number",
      type: "text",
      placeholder: "Enter New Mobile number",
      value: "",
      key: 2,
    },
    {
      label: "Enter OTP",
      type: "text",
      placeholder: "OTP",
      value: "",
      key: 3,
    },
  ];
  const changeEmailAddress = [
    {
      label: "Old Email Address",
      type: "text",
      placeholder: "Enter Old Email Address",
      value: personalInfo?.EmailAddress,
      key: 4,
    },
    {
      label: "New Email Address",
      type: "text",
      placeholder: "Enter New Email Address",
      value: "",
      key: 5,
    },
    {
      label: "Enter OTP",
      type: "text",
      placeholder: "OTP",
      value: "",
      key: 6,
    },
  ];
  const changePassword = [
    {
      label: "Enter Old Password",
      type: "password",
      placeholder: "Enter Old Password",
      value: "",
      key: 7,
    },
    {
      label: "Enter New Password",
      type: "password",
      placeholder: "Enter New Password",
      value: "",
      key: 8,
    },
    {
      label: "Re-enter your new password",
      type: "password",
      placeholder: "Enter password",
      value: "",
      key: 9,
    },
  ];
  const changePersionalInformation = [
    {
      label: "Title",
      type: "dropdowns",
      placeholder: "Enter Title ",
      value: personalInfo?.Title,
      options: ["Mr", "Miss", "Mrs"],
      key: 12,
    },
    {
      label: "First Name",
      type: "text",
      placeholder: "Enter First Name",
      value: personalInfo?.FirstName,
      key: 10,
    },
    {
      label: "Last Name",
      type: "text",
      placeholder: "Enter Last Name",
      value: personalInfo?.LastName,
      key: 11,
    },

    // {
    //   label: "Zip code",
    //   type: "text",
    //   placeholder: "Enter Zip code",
    //   value: personalInfo?.Zipcode,
    //   key: 13,
    // },
    {
      label: "Date of Birth",
      type: "date",
      placeholder: "Enter Date of Birth",
      value: personalInfo?.DateofBirth,
      key: 14,
    },
    {
      label: "Anniversary date",
      type: "date",
      placeholder: "Enter Anniversary date",
      value: personalInfo?.AnniversaryDate,
      key: 15,
    },
  ];
  // const [editAddress, setEditAddress] = useState([
  //   {
  //     label: "Name",
  //     type: "text",
  //     placeholder: "Enter Name",
  //     value: value,
  //     key: 16,
  //   },
  //   {
  //     label: "Zip code",
  //     type: "text",
  //     placeholder: "Enter Zip code",
  //     value: value,
  //     key: 20,
  //   },
  //   {
  //     label: "Street address",
  //     type: "textarea",
  //     placeholder: "Enter Street address",
  //     value: value,
  //     key: 18,
  //   },
  //   {
  //     label: "Contact",
  //     label2: "Contact Country",
  //     type: "contactdropdown",
  //     placeholder: "Enter Mobile No",
  //     value: value,
  //     value2: value,
  //     readOnly: true,
  //     key: 19,
  //     options: phonecode,
  //   },
  //   {
  //     label: "City",
  //     type: "text",
  //     placeholder: "Select City",
  //     value: "",
  //     key: 21,
  //     options: ["chennai", "Erode", "Salem", "Namakal"],
  //     readOnly: true
  //   },
  //   {
  //     label: "State",
  //     type: "text",
  //     placeholder: "Enter State",
  //     value: value,
  //     key: 22,
  //     readOnly: true
  //   },
  //   {
  //     label: "Country",
  //     type: "text",
  //     placeholder: "Select Country",
  //     value: "",
  //     key: 17,
  //     readOnly: true
  //   },
  //   {
  //     label: "Make this as a Primary Address",
  //     type: "switch",
  //     placeholder: "",
  //     value: false,
  //     key: 23,
  //   },
  // ])
  const editAddress = [
    {
      label: "Name",
      type: "text",
      placeholder: "Enter Name",
      value: editManageAddressData?.cilentName || "",
      key: 16,
    },
    {
      label: "Contact",
      label2: "Contact Country",
      type: "contactdropdown",
      placeholder: "Enter Mobile No",
      value: editManageAddressData?.mobile,
      value2: value,
      readOnly: true,
      key: 19,
      options: phonecode,
    },
    {
      label: "Street address",
      type: "textarea",
      placeholder: "Enter Street address",
      value: editManageAddressData?.address1,
      key: 18,
    },
    {
      label: "Zip code",
      type: "text",
      placeholder: "Enter Zip code",
      value: editManageAddressData?.pincode || "",
      key: 20,
    },
    {
      label: "City",
      type: "text",
      placeholder: "Select City",
      value: editManageAddressData?.city,
      key: 21,
      options: ["chennai", "Erode", "Salem", "Namakal"],
      readOnly: true,
    },
    {
      label: "State",
      type: "text",
      placeholder: "Enter State",
      value: editManageAddressData?.state,
      key: 22,
      readOnly: true,
    },
    {
      label: "Country",
      type: "text",
      placeholder: "Select Country",
      value: "",
      key: 17,
      readOnly: true,
    },
    {
      label: "Make this as a Primary Address",
      type: "switch",
      placeholder: "",
      value: editManageAddressData?.primary,
      key: 23,
    },
  ];
  const [addAddress, setAddAddress] = useState([
    {
      label: "Name",
      type: "text",
      placeholder: "Enter Name",
      value: "",
      key: 30,
    },
    {
      label: "Contact",
      label2: "Contact Country",
      type: "contactdropdown",
      placeholder: "Enter Mobile No",
      value: "",
      value2: value,
      readOnly: true,
      key: 33,
      options: phonecode,
    },
    {
      label: "Street address",
      type: "textarea",
      placeholder: "Enter Street address",
      value: "",
      key: 32,
    },
    {
      label: "Zip code",
      type: "text",
      placeholder: "Enter Zip code",
      value: "",
      key: 31,
    },
    {
      label: "City",
      type: "text",
      placeholder: "Select City",
      value: "",
      key: 34,
      options: ["chennai", "Erode", "Salem", "Namakal"],
      readOnly: true,
    },
    {
      label: "State",
      type: "text",
      placeholder: "Enter State",
      value: "",
      key: 35,
      readOnly: true,
    },
    {
      label: "Country",
      type: "text",
      placeholder: "Select Country",
      value: "",
      key: 36,
      readOnly: true,
    },
    {
      label: "Make this as a Primary Address",
      type: "switch",
      placeholder: "",
      value: "",
      key: 37,
    },
  ]);
  const deleteAddressData = [
    {
      title: "Are you sure you want to delete this Address?",
      key: 24,
    },
  ];
  const logOut = [
    {
      title: "Are you sure you want to Logout?",
      key: 26,
    },
  ];
  const RemoveWishlist = [
    {
      title: "Are you sure you want to Remove the product from Wishlist?",
      key: 27,
    },
  ];
  const getFormData = () => {
    switch (showModelData) {
      case constants.MobileNumber:
        return changeMobilenumber;
      case constants.EmailAddress:
        return changeEmailAddress;
      case constants.Password:
        return changePassword;
      case constants.AddressEdit:
        return editAddress;
      case constants.PersonalInformation:
        return changePersionalInformation;
      case constants.ManageAddress:
        return addAddress;
      case constants.AddressDelete:
        return deleteAddressData;
      case constants.Logout:
        return logOut;
      case constants.RemoveWishlist:
        return RemoveWishlist;

      default:
        return [];
    }
  };
  const createValidationSchema = (formdata) => {
    const validation = {};
    // console.log("createValidationSchema", formdata);
    formdata?.forEach((item) => {
      if (item) {
        console.log(item, "item99999");

        if (item.type === "text") {
          if (item.label === "Zip code") {
            validation[item.label] = Yup.string()
              .matches(/^\d{6}$/, "Zip Code must be exactly 6 digits")
              .required("Zip Code is Required");
          } else if (
            item.label === "Old Email Address" ||
            item.label === "New Email Address"
          ) {
            validation[item.label] = Yup.string()
              .email()
              .required(`${item.label} is Required`);
          } else if (item.label === "Enter OTP") {
            validation[item.label] = Yup.string().required(`OTP is Required`);
          } else {
            validation[item.label] = Yup.string().required(
              `${item.label} is Required`
            );
          }
        }
        if (item.type === "date") {
          if (item.label === "Date of Birth") {
            validation[item.label] = Yup.string().required(
              `${item.label} is Required`
            );
          } else if (item.label === "Anniversary date") {
            validation[item.label] = Yup.string()
              .required(`${item.label} is Required`)
              .notOneOf(
                [Yup.ref("Date of Birth")],
                "Anniversary date should not be the same as Date of Birth"
              );
          } else {
            validation[item.label] = Yup.string().required(
              `${item.label} is Required`
            );
          }
        }
        if (item.type === "dropdown") {
          validation[item.label] = Yup.string().required(
            `${item.label} is Required`
          );
        }
        if (item.type === "textarea") {
          validation[item.label] = Yup.string().required(
            `${item.label} is Required`
          );
        }
        if (item.type === "contactdropdown") {
          validation[item.label] = Yup.string()
            .required(`${item.label} is Required`)
            .test(
              "is-not-zero",
              "Invalid Mobile Number",
              (value) => parseInt(value, 10) !== 0
            );
        }
        if (item.type === "password") {
          console.log("passwordchktest", item.label);

          if (item.label === "Re-enter your new password") {
            validation[item.label] = Yup.string()
              .required(`Re-enter Password is Required`)
              .oneOf(
                [Yup.ref("Enter New Password")],
                "Password should be the same as New Password"
              );
          } else if (item.label === "Enter New Password") {
            validation[item.label] = Yup.string()
              .required("New Password is Required")
              .notOneOf(
                [Yup.ref("Enter Old Password")],
                "Password should not be the same as Old Password"
              )
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                "Must be at least 8 characters long and include at least one uppercase letter,one lowercase letter, one number, and one special character"
              );
          } else if (item.label === "Enter Old Password") {
            validation[item.label] = Yup.string().required(
              "Old Password is Required"
            );
          } else {
            validation[item.label] = Yup.string().required(
              `${item.label} is Required`
            );
          }
        }
      }
    });
    // Custom validation for "Enter New Password" and "Re-enter your new password"
    // validation["Re-enter your new password"] = Yup.string()
    //   .required("Re-enter Password is Required")
    //   .oneOf(
    //     [Yup.ref("Enter New Password")],
    //     "Password should be the same as New Password"
    //   );
    return Yup.object().shape(validation);
  };
  const initialValues = getFormData().reduce((acc, item) => {
    if (item.type === "date" && item.value) {
      acc[item.label] = format(parseISO(item.value), "yyyy-MM-dd");
    } else {
      acc[item.label] = item.value;
    }
    return acc;
  }, {});

  const validationSchema = createValidationSchema(getFormData());

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        console.log(showModelData, "showModelDataxxx");
        switch (showModelData) {
          // case constants.MobileNumber:
          //   await updateMobileNumber(values);
          //   break;
          // case constants.EmailAddress:
          //   await updateEmailAddress(values);
          //   break;
          case constants.Password:
            await dispatch(
              updateuserpassword(values, accessToken, personalInfo)
            );
            break;
          case constants.PersonalInformation:
            await dispatch(
              updatePersonalInformation(
                values,
                accessToken,
                personalInfo,
                userProfile_id
              )
            );
            break;
          case constants.AddressEdit:
            console.log("addressedit called", values);
            var {
              Name,
              Contact,
              City,
              State,
              "Zip code": pincode,
              "Street address": address,
              Country: country,
              "Make this as a Primary Address": isprimary,
            } = values;
            let addressforEditAPI = [
              {
                salutation: "",
                firstname: Name,
                lastname: "",
                addressline1: address,
                addressline2: "",
                pincode: pincode,
                city: City,
                state: State,
                country,
                country_code: "",
                contactno: Contact,
                addresstype: "",
                isprimary: isprimary,
              },
            ];
            console.log(State, "+++++++");
            await dispatch(
              addoredit_useraddress(
                addressforEditAPI,
                userProfile_id,
                editManageAddressData?.id
              )
            );
            break;
          case constants.ManageAddress:
            console.log(values, "addressadd called for dispatch");
            console.log(userProfile_id, "userProfile_id");
            var {
              Name,
              Contact,
              City,
              State,
              "Zip code": pincode,
              "Street address": address,
              Country: country,
              "Make this as a Primary Address": isprimary,
            } = values;
            let addressforAPI = [
              {
                salutation: "",
                firstname: Name,
                lastname: "",
                addressline1: address,
                addressline2: "",
                pincode: pincode,
                city: City,
                state: State,
                country,
                country_code: "",
                contactno: Contact,
                addresstype: "",
                isprimary: isprimary,
              },
            ];
            console.log(State, "+++++++");

            await dispatch(
              addoredit_useraddress(addressforAPI, userProfile_id)
            );
            break;
          // case constants.AddressDelete:
          //   await deleteAddress(values);
          //   break;
          // case constants.Logout:
          //   await logoutUser();
          //   break;
          // case constants.RemoveWishlist:
          //   await removeWishlistItem(values);
          //   break;
          default:
            console.log("No matching form data found");
        }
        console.log("Form submitted with values:", values);
        // alert('Form submitted successfully!');
        // showModelData !== constants.AddressDelete &&
        //   Toast("Form submitted successfully!", "success");
        // showModelData === constants.AddressDelete &&
        //   Toast("Address Deleted successfully!", "success");
        //await dispatch(getPersionalData(userProfile_id));
        resetForm();
        setIsOpen(false);
      } catch (error) {
        console.error(`API error in ${showModelData}:`, error);
        Toast("Failed to submit form. Please try again!!", "error");
      } finally {
        setSubmitting(false);
      }
    },
  });
  // useEffect(() => {
  //   if (formik.values["Zip code"]) {
  //     fetchPincodeMasterData(formik.values["Zip code"])
  //       .then((response) => {
  //         if (response && response.data) {
  //           const { state, country, district } = response?.data?.data?.allPincodeMasters?.nodes[0];
  //           formik.setFieldValue("State", state);
  //           formik.setFieldValue("Country", country);
  //           formik.setFieldValue("City", district);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching pincode data:", error);
  //       });
  //   }
  // }, [formik.values["Zip code"]]);
  useEffect(() => {
    if (formik.values["Zip code"]) {
      fetchPincodeMasterData(formik.values["Zip code"])
        .then((response) => {
          if (response && response.data) {
            const fetchedPincodeData =
              response?.data?.data?.allPincodeMasters?.nodes;
            setPincodeData(fetchedPincodeData);

            if (fetchedPincodeData.length > 0) {
              // Extract distinct countries
              const distinctCountries = Array.from(
                new Set(fetchedPincodeData.map((node) => node.country))
              );

              if (distinctCountries.length === 1) {
                // If there is only one unique country, set it as text
                const country = distinctCountries[0];
                const state = fetchedPincodeData[0].state;
                const city = fetchedPincodeData[0].district;

                formik.setFieldValue("State", state);
                formik.setFieldValue("Country", country);
                formik.setFieldValue("City", city);

                // Update the form fields to text for country
                let updatedAddress;
                if (showModelData === constants.ManageAddress) {
                  updatedAddress = addAddress.map((item) => {
                    if (item.label === "Country") {
                      return {
                        ...item,
                        type: "text",
                        value: country,
                      };
                    }
                    return item;
                  });
                  setAddAddress(updatedAddress);
                } else if (showModelData === constants.AddressEdit) {
                  updatedAddress = editAddress.map((item) => {
                    if (item.label === "Country") {
                      return {
                        ...item,
                        type: "text",
                        value: country,
                      };
                    }
                    return item;
                  });
                  // setEditAddress(updatedAddress);
                }
              } else {
                // If there are multiple unique countries, set it as a dropdown
                const prioritizedCountries = distinctCountries.includes("India")
                  ? [
                      "India",
                      ...distinctCountries.filter(
                        (country) => country !== "India"
                      ),
                    ]
                  : distinctCountries;

                // Set the state and city based on the first country in the list
                const firstCountryData = fetchedPincodeData.find(
                  (node) => node.country === prioritizedCountries[0]
                );
                console.log(JSON.stringify(firstCountryData), "987655");
                if (firstCountryData) {
                  formik.setFieldValue("State", firstCountryData.state || "");
                  formik.setFieldValue("City", firstCountryData.district || "");
                  formik.setFieldValue("Country", firstCountryData.country);
                }

                // Update the form fields to dropdown for country
                let updatedAddress;
                if (showModelData === constants.ManageAddress) {
                  updatedAddress = addAddress.map((item) => {
                    if (item.label === "Country") {
                      return {
                        ...item,
                        type: "dropdown",
                        options: prioritizedCountries,
                      };
                    }
                    return item;
                  });
                  setAddAddress(updatedAddress);
                } else if (showModelData === constants.AddressEdit) {
                  updatedAddress = editAddress.map((item) => {
                    if (item.label === "Country") {
                      return {
                        ...item,
                        type: "dropdown",
                        options: prioritizedCountries,
                      };
                    }
                    return item;
                  });
                  // setEditAddress(updatedAddress);
                }
              }
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching pincode data:", error);
        });
    } else {
      // If pincode is removed or length is zero, clear state, country, and city fields
      formik.setFieldValue("State", "");
      formik.setFieldValue("Country", "");
      formik.setFieldValue("City", "");

      // Update the form fields to default state
      let updatedAddress;
      if (showModelData === constants.ManageAddress) {
        updatedAddress = addAddress.map((item) => {
          if (item.label === "Country") {
            return {
              ...item,
              type: "text",
              value: "",
            };
          }
          return item;
        });
        setAddAddress(updatedAddress);
      } else if (showModelData === constants.AddressEdit) {
        updatedAddress = editAddress.map((item) => {
          if (item.label === "Country") {
            return {
              ...item,
              type: "text",
              value: "",
            };
          }
          return item;
        });
        // setEditAddress(updatedAddress);
      }
    }
  }, [formik.values["Zip code"], showModelData]);
  const handleCountryChange = (selectedCountry) => {
    const selectedCountryData = pincodeData.find(
      (node) => node.country === selectedCountry
    );
    console.log(selectedCountryData, "poiuuu");
    if (selectedCountryData) {
      formik.setFieldValue("State", selectedCountryData?.state || "");
      formik.setFieldValue("City", selectedCountryData?.district);
      formik.setFieldValue("Country", selectedCountryData?.country);
    }
  };

  const renderConformationComponent = (item) => {
    console.log("renderConformationComponent", item);

    return (
      <div className="py-5" key={item?.key}>
        <span className="text-base font-roboto text-[#353535] font-medium">
          {item?.title}
        </span>
      </div>
    );
  };

  const renderComponent = (item, index) => {
    console.log(item, "item in personal info");
    const isReadOnly =
      item.label === "State" ||
      item.label === "Country" ||
      item.label === "City";
    console.log(isReadOnly, "isReadOnlybbb");

    if (item.type === "text") {
      return (
        <Textinput
          label={item.label}
          placeholder={item.placeholder}
          value={formik.values[item.label]}
          disabled={item.readOnly || isReadOnly}
          onChange={formik.handleChange}
          type={item.type}
          keyvalue={item.key}
          name={item.label}
          error={formik.errors[item.label] && formik.touched[item.label]}
          errorMsg={formik.errors[item.label]}
        />
      );
    } else if (item.type === "date") {
      return (
        <Datepic
          label={item.label}
          placeholder={item.placeholder}
          value={formik.values[item.label]}
          onChange={(date) => formik.setFieldValue(item.label, date)}
          type={item.type}
          keyvalue={`date-input-${item.key}`}
          name={item.label}
          error={formik.errors[item.label] && formik.touched[item.label]}
          errorMsg={formik.errors[item.label]}
        />
      );
    } else if (item.type === "dropdown") {
      return (
        <Dropdown
          label={item.label}
          placeholder={item.placeholder}
          value={formik.values[item.label]}
          onChange={(e) => handleCountryChange(e.target.value)}
          type={item.type}
          keyvalue={`dropdown-input-${item.key}`}
          options={item?.options}
          name={item.label}
          error={formik.errors[item.label] && formik.touched[item.label]}
          errorMsg={formik.errors[item.label]}
          readOnly={item.readOnly || isReadOnly}
        />
      );
    } else if (item.type === "dropdowns") {
      return (
        <Dropdown
          label={item.label}
          placeholder={item.placeholder}
          value={formik.values[item.label]}
          onChange={formik.handleChange}
          type={item.type}
          keyvalue={`dropdown-input-${item.key}`}
          options={item?.options}
          name={item.label}
          error={formik.errors[item.label] && formik.touched[item.label]}
          errorMsg={formik.errors[item.label]}
          readOnly={item.readOnly || isReadOnly}
        />
      );
    } else if (item.type === "textarea") {
      return (
        <Textarea
          label={item.label}
          placeholder={item.placeholder}
          value={formik.values[item.label]}
          onChange={formik.handleChange}
          type={item.type}
          keyvalue={`textarea-input-${item.key}`}
          name={item.label}
          error={formik.errors[item.label] && formik.touched[item.label]}
          errorMsg={formik.errors[item.label]}
        />
      );
    } else if (item.type === "contactdropdown") {
      return (
        <Contact
          label={item.label}
          label2={item.label2}
          placeholder={item.placeholder}
          value={formik.values[item.label]}
          value2={formik.values[item.label2]}
          onChange={formik.handleChange}
          type={item.type}
          readOnly={item.readOnly}
          keyvalue={`textarea-input-${item.key}`}
          options={item?.options}
          name={item.label}
          name2={item.label2}
          error={formik.errors[item.label] && formik.touched[item.label]}
          errorMsg={formik.errors[item.label]}
        />
      );
    }
    if (item.type === "password") {
      return (
        <PasswordInput
          label={item.label}
          placeholder={item.placeholder}
          value={formik.values[item.label]}
          onChange={formik.handleChange}
          type={item.type}
          keyvalue={`dropdown-input-${item.key}`}
          //  options={item?.options}
          name={item.label}
          error={formik.errors[item.label] && formik.touched[item.label]}
          errorMsg={formik.errors[item.label]}
        />
      );
    } else if (item.type === "switch") {
      return (
        <Switch
          label={item.label}
          value={formik.values[item.label]}
          onToggle={() =>
            formik.setFieldValue(item.label, !formik.values[item.label])
          }
          name={item.label}
        />
      );
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <section>
        <div className="font-Cormorant font-bold flex flex-row justify-between items-center text-2xl text-[#353535]">
          <span>{modelTitle}</span>
          {(headerEditModal === constants.Confirmation ||
            showModelData === constants.ManageAddress ||
            showModelData === constants.AddressEdit) && (
            <img
              src={constants.DEFAULT_IMAGE + "/modelclose-icon.png"}
              onClick={() => setIsOpen(false)}
              className="w-3 h-3 cursor-pointer"
              alt="model close"
            />
          )}
        </div>
        {modelSubTitle !== null && (
          <div className=" font-medium text-base mt-2 text-[#646464]">
            {modelSubTitle}
          </div>
        )}
        <div className="mt-5 border border-b-[#BFBFBF]"></div>
      </section>
      {headerEditModal === false && (
        <section className="py-5">
          {/* {showModelData === constants.MobileNumber &&
            changeMobilenumber.map((item) => renderComponent(item))}
          {showModelData === constants.EmailAddress &&
            changeEmailAddress.map((item) => renderComponent(item))}
          {showModelData === constants.Password &&
            changePassword.map((item) => renderComponent(item))} */}

          {getFormData().map((item) => renderComponent(item))}
        </section>
      )}
      {headerEditModal === true && (
        <section className="mt-5">
          <div className="grid grid-cols-2 gap-2 mb-3">
            {/* {changePersionalInformation.map((item, index) =>
              renderComponent(item, index)
            )} */}
            {getFormData().map((item) => renderComponent(item))}
          </div>
        </section>
      )}
      {headerEditModal === constants.Confirmation && (
        <section>
          {getFormData().map((item) => renderConformationComponent(item))}
        </section>
      )}
      {headerEditModal !== constants.Confirmation ? (
        <section className="flex flex-row ">
          <Button
            label={"Cancel"}
            onClick={handleButtonChange}
            type="button"
            variant="secondary"
          />
          <Button
            label={"Submit"}
            onClick={handleButtonChange}
            type="submit"
            variant="primary"
          />
        </section>
      ) : headerEditModal === constants.Confirmation &&
        showModelData === constants.Logout ? (
        <section className="flex flex-row ">
          <Button
            label={"Cancel"}
            onClick={handleButtonChange}
            type="button"
            variant="secondary"
          />
          <button
            onClick={() => {
              if (localStorage.getItem("cartDetails")) {
                // Remove cartDetails from localStorage
                localStorage.removeItem("cartDetails");
                console.log("Cart has been cleared.");
              }
              handleButtonChange("log out confirm");
            }}
            className="w-full bg-[#CE2B37] text-white rounded-lg"
          >
            Confirm
          </button>
        </section>
      ) : (
        <section className="flex flex-row ">
          <Button
            label={"Cancel"}
            onClick={handleButtonChange}
            type="button"
            variant="secondary"
          />
          <Button
            label={"Confirm"}
            onClick={handleButtonChange}
            type="submit"
            variant="primary"
          />
        </section>
      )}
    </form>
  );
};

export default Accountpagemodal;
