import { useState } from "react";
import constants from "../../utilities/constants";

const PasswordInput = ({
  label,
  page = null,
  value,
  onChange,
  name,
  error,
  errorMsg,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      {page == null ? (
        <section>
          <div className="text-base justify-between pb-4 items-center font-medium text-[#353535]">
            <label>{label}</label>
          </div>
          <div className="relative w-full ">
            <input
              type={showPassword ? "text" : "password"}
              value={value}
              onChange={onChange}
              className={`w-[310px] md:w-full px-4  py-4 border ${
                error ? "border-[#E43138]" : "border-[#CACACA]"
              }  focus:outline-none `}
              placeholder={label}
              name={name}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-[80px]  md:right-3  flex items-center"
            >
              {showPassword ? (
                <img
                  src={constants.DEFAULT_IMAGE + "/password_hide.png"}
                  className="w-5 h-5"
                  alt="PasswordHide icon"
                />
              ) : (
                <img
                  src={constants.DEFAULT_IMAGE + "/password_not_hide.png"}
                  className="w-5 h-5"
                  alt="ShowPassword icon"
                />
              )}
            </button>
          </div>
          <div className={`${error ? "p-0 m-0" : "pt-3"}`}>
            {error && (
              <span className="text-[#E43138] text-sm">{errorMsg}</span>
            )}
          </div>
        </section>
      ) : (
        <div className="relative col-span-5 pb-2">
          {/* <label className="block text-sm font-medium text-gray-700">{label}</label> */}

          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={value}
              onChange={onChange}
              className={`col-span-5 pb-2 px-4  py-2 border ${
                error ? "border-[#E43138]" : "border-[#CACACA]"
              }  focus:outline-none `}
              placeholder={label}
              name={name}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-[80px]  md:right-3 flex  items-center"
            >
              {showPassword ? (
                <img
                  src={constants.DEFAULT_IMAGE + "/password_hide.png"}
                  className="w-5 h-5"
                  alt="PasswordHide icon"
                />
              ) : (
                <img
                  src={constants.DEFAULT_IMAGE + "/password_not_hide.png"}
                  className="w-5 h-5"
                  alt="ShowPassword icon"
                />
              )}
            </button>
          </div>
          <div className={`${error ? "p-0 m-0" : "pt-3"}`}>
            {error && (
              <span className="text-[#E43138] text-sm">{errorMsg}</span>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PasswordInput;
