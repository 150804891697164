import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getFaqData } from "../redux/action/homeAction";
import { parseData } from "../utilities/helper";
import constants from "../utilities/constants";
function Faqs() {
  const dispatch = useDispatch();
  const {
    faqDataResponseLoading,
    faqDataResponseSuccess,
    faqDataResponseError,
  } = useSelector((state) => state.homeReducer);
  useEffect(() => {
    dispatch(getFaqData());
  }, []);

  const Accordion = ({ children }) => {
    return <div className="md:w-1/2 w-full">{children}</div>;
  };

  const AccordionItem = ({ header, children }) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
      <div className="border-b w-full pb-5 pt-5">
        <button
          className=" flex flex-row justify-between lg:items-center max-lg:px-4 w-full"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className=" text-black ">{header}</div>
          <span className="text-xl">
            {isOpen ? (
              <img
                src={constants.DEFAULT_IMAGE + "/accortian_close.png"}
                alt="close icon"
                className="w-7 h-7 max-lg:ms-2"
              />
            ) : (
              <img
                alt="plus icon"
                className="w-5 h-5 max-lg:ms-2"
                src={constants.DEFAULT_IMAGE + "/accortian_open.png"}
              />
            )}
          </span>
        </button>
        {isOpen && (
          <div className="px-4  font-century font-medium text-sm">
            {children}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {faqDataResponseSuccess && faqDataResponseSuccess[0].isActive ? (
        <main>
          {faqDataResponseSuccess &&
            parseData(faqDataResponseSuccess[0]?.data).map((item, index) => (
              <div>
                {item.component === constants.HomePageBanner && (
                  <section>
                    <img
                      className="w-full h-[446px] object-cover md:block hidden"
                      src={item?.props?.banners[0]?.web}
                      alt="FaqBanner"
                    />
                    <img
                      className="w-full block md:hidden"
                      src={item?.props?.banners[0]?.mobile}
                      alt="FaqBanner"
                    />
                  </section>
                )}
                {item.component === constants.FaqTitle && (
                  <section className="bg-[#877359] text-white flex flex-col items-center p-2">
                    <span className="font-Cormorant font-bold text-3xl">
                      {item?.props?.title_Text}
                    </span>
                    <span className="font-century font-medium text-lg">
                      {item?.props?.sub_Text}
                    </span>
                  </section>
                )}
                {item.component === constants.FaqComponents && (
                  <section className="container mx-auto py-24">
                    <div key={index} className="flex flex-col ">
                      {(() => {
                        const groupedData = item?.props?.reduce((acc, curr) => {
                          if (!acc[curr.header.toLowerCase()]) {
                            acc[curr.header.toLowerCase()] = [];
                          }
                          acc[curr.header.toLowerCase()].push(curr);
                          return acc;
                        }, {});

                        return Object.entries(groupedData).map(
                          ([header, roles], headerIndex) => (
                            <>
                              <div className="flex flex-col justify-start items-center">
                                <span className="text-[#2F348C] font-semibold font-century text-[25px] max-sm:px-3 md:w-1/2 w-full pt-3">
                                  {header === "-" ? "" : header.toUpperCase()}
                                </span>
                              </div>
                              {roles.map((roleItem, roleIndex) => (
                                <div className="flex flex-col items-center">
                                  <Accordion key={headerIndex}>
                                    <AccordionItem header={roleItem.role}>
                                      <div key={roleIndex}>
                                        <div
                                          className="customfaq-content text-[#64636B] pt-2"
                                          dangerouslySetInnerHTML={{
                                            __html: roleItem.JobDescription,
                                          }}
                                        />
                                      </div>
                                    </AccordionItem>
                                  </Accordion>
                                </div>
                              ))}
                            </>
                          )
                        );
                      })()}
                    </div>
                  </section>
                )}
              </div>
            ))}
        </main>
      ) : faqDataResponseLoading ? (
        <div className="text-center py-6 flex flex-col items-center">
          <img
            src={constants.DEFAULT_IMAGE + "/loaderimagesilver.gif"}
            alt="loading logo"
            className="w-120 h-120"
          />
        </div>
      ) : (
        <section>
          <div className="text-center py-6 flex flex-col items-center">
            <img
              src={constants.DEFAULT_IMAGE + "/no-product.svg"}
              alt="Sorry, No Product Found"
              className="w-120 h-120"
            />
            <p className="text-xl font-semibold text-[#262626]">
              Sorry, No data Found
            </p>
          </div>
        </section>
      )}
    </>
  );
}

export default Faqs;
