import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getReadourblogsdata
} from "../redux/action/homeAction";
import { Link, useNavigate } from "react-router-dom";
import config from "../utilities/config";
import constants from "../utilities/constants";
import { formatDate } from "../utilities/helper";
function stripHtmlTags(html) {
  return html.replace(/<[^>]*>/g, '');
}
function formatJewelryList(text) {
  var texty=(text).replace(/<[^>]*>/g, '');
  console.log(texty,"887667")
  return (texty.replace(/(\d+)\.\s/g, '\n$1. '));
}

function StyloriBlog() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const {
    readOurBlogsDataResponseLoading,
    readOurBlogsDataResponseSuccess,
    readOurBlogsDataResponseError,
  } = useSelector((state) => state.homeReducer);

  const blogs = readOurBlogsDataResponseSuccess?.props?.cardContent || [];
 config.logGeneral &&
    console.log(
      "readOurBlogsDataResponseSuccess",
      readOurBlogsDataResponseSuccess,
    );
    

  useEffect(() => {
    dispatch(getReadourblogsdata());
  }, []);
  return (
    <>
      {/* Blogs */}
      <div className="container mx-auto mb-10">
        <div className="lg:mt-10 mt-5 lg:p-0 p-5">
        {blogs !== null &&
            blogs.length > 0 &&
            blogs?.map((blog, index) => (
              <>
                <div key={index}>
                  <div className="lg:mb-5 mb-2">
                    
                    <h2 class="text-[#1A1A1A] font-Cormorant font-bold lg:text-[40px] text-[24px] mb-3">
                      {blog.heading}
                    </h2>
                    <p className="text-[#676767] font-medium text-xl">
                      {formatDate(blog.date, 'Month DD, YYYY')}
                    </p>
                  </div>
                </div>
              </>
            ))}
        </div>
        <div
          onClick={() =>
            navigation(constants.ADDITIONAL_PATH + "/Blog/:title?")
          }
          className="container mx-auto cursor-pointer lg:p-0 p-5 lg:text-left text-justify"
        >
          {blogs !== null &&
            blogs.length > 0 &&
            blogs?.map((blog, index) => (
              <>
                <div key={index}>
                  <div className="">
                    <img src={blog.image} alt="Default" className="w-full" />
                    <div className="text-[#262626] font-medium text-xl leading-relaxed mt-4">
                      {formatJewelryList(blog.description_1)
                        .split("\n") // Split text by newline
                        .map((line, i) => (
                          <p key={i}>
                            {line} 
                          </p>
                        ))}
                    </div>
                    <div className="text-[#262626] font-medium text-xl leading-relaxed">
                      {formatJewelryList(blog.description_2)
                        .split("\n") // Split text by newline
                        .map((line, i) => (
                          <p key={i}>
                            {line} 
                          </p>
                        ))}
                    </div>
                    <div className="text-[#262626] font-medium text-xl leading-relaxed">
                      {formatJewelryList(blog.description_3)
                        .split("\n") // Split text by newline
                        .map((line, i) => (
                          <p key={i}>
                            {line}
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>

        <h2 className="font-semibold text-2xl text-[#262626] text-center mt-10 lg:p-0 p-5">
        From timeless classics to modern essentials, these must-have items add elegance and versatility to any wardrobe.<br />
        Shop from Stylori to find your perfect pieces!
        </h2>
      </div>

    </>
  );
}

export default StyloriBlog;
