import React, { useEffect, useState } from "react";
import Textinput from "../../components/Atoms/Textinput";
import Button from "../../components/Atoms/Button";
import Dropdown from "../../components/Atoms/Dropdown";
import PasswordInput from "../../components/Atoms/PasswordInput";

import { useDispatch, useSelector } from "react-redux";
import {
  clearAuthModalType,
  getAuthModalType,
  login,
  loginModalAction,
  signup,
} from "../../redux/action/authAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import constants from "../../utilities/constants";
import { Toast } from "../../components/Atoms/Toast";
import { useNavigate } from "react-router-dom";
import { forgotPassword, resetPassword } from "../../redux/action/cartAction";

const Authenticatemodal = ({ setIsModalOpen, closebtn, loginModalType }) => {
  const { authModalType, userProfile_id, authModalopen } = useSelector(
    (state) => state.authReducer
  );

  const [intialValue, setintialValue] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);
  const [token, settoken] = useState(null);
  const Navigate = useNavigate();

  const dispatch = useDispatch();
  console.log(authModalType, authModalopen, "==>authModalType");
  const handleModelClick = () => {
    // setIsModalOpen(false);
    if (window.location.href.includes("account")) {
      window.location.replace("/styloriSilver");
    } else if (window.location.href.includes("checkout")) {
      window.location.replace(constants.ADDITIONAL_PATH + "/cart");
    }
    // else if( window.location.href.includes("cart")){

    // }
    else {
      dispatch(clearAuthModalType());
    }
  };
  console.log(userProfile_id, "userProfile_id", authModalType);

  useEffect(() => {
    if (window.location.pathname.split("/")[2] === constants.Resetpassword) {
      settoken(window.location.pathname.split("/")[3]);
    }
  }, []);
  useEffect(() => {
    // setModelFormData(getModeldata());
    setValidationSchema(createValidationSchema(getFormvalues()));
    // getModeldata();
  }, [authModalType]);

  // const valid =(e)=>{
  //   console.log("eeeeeeee",e.target.name,"value",e.target.value)
  //   // const { name, value } = e.target;
  //   if (e.target.name === "register_Mobile_number") {
  //     // let newValue = e.target.value.replace(/^\s+/g, "");
  //      let newValue = e.target.value.trim();
  //     if (!/^[6-9]\d{9}$/.test(e.target.value)) {
  //       newValue =''
  //   console.log("calling",e.target.value)
  // }else{
  //       formik.setFieldValue(e.target.name,newValue)

  //     }
  //   }

  //     if( newValue.trim() === ''){
  //       newValue = ""
  //       formik.setFieldValue(e.target.name,'')
  //     }else{
  //       formik.setFieldValue(e.target.name,newValue)
  //     }

  // }
  const valid = (e) => {
    let { name, value } = e.target;
    value = value.trim();

    if (name === "register_Mobile_number") {
      // Remove any non-numeric characters
      value = value.replace(/\D/g, "");

      // Check if value starts with 6-9 and has exactly 10 digits
      if (["6", "7", "8", "9"].includes(value[0])) {
        formik.setFieldValue(name, value); // Update Formik if valid
      } else {
        console.log("Invalid mobile number:", value);
        formik.setFieldValue(name, ""); // Clear field if invalid
      }
    } else {
      // Trim other field values
      formik.setFieldValue(name, value);
    }
  };
  const renderingData = (item) => {
    if (item.type === "text") {
      return (
        <Textinput
          // label={fields.label}
          placeholder={item.label}
          value={formik.values[item.placeholder]}
          // onChange={formik.handleChange}
          onChange={(e) => valid(e)}
          type={item.type}
          keyvalue={item.key}
          name={item.placeholder}
          error={
            formik.errors[item.placeholder] && formik.touched[item.placeholder]
          }
          errorMsg={formik.errors[item.placeholder]}
        />
      );
    }
    if (item.type === "dropdown") {
      return (
        <Dropdown
          // label={item.label}
          placeholder={item.label}
          value={formik.values[item.placeholder]}
          onChange={formik.handleChange}
          type={item.type}
          keyvalue={`dropdown-input-${item.key}`}
          options={item?.options}
          name={item.placeholder}
          error={
            formik.errors[item.placeholder] && formik.touched[item.placeholder]
          }
          errorMsg={formik.errors[item.placeholder]}
        />
      );
    }
    if (item.type === "password") {
      return (
        <PasswordInput
          label={item.label}
          page={"authmodel"}
          placeholder={item.placeholder}
          value={formik.values[item.placeholder]}
          onChange={formik.handleChange}
          type={item.type}
          keyvalue={`dropdown-input-${item.key}`}
          //  options={item?.options}
          name={item.placeholder}
          error={
            formik.errors[item.placeholder] && formik.touched[item.placeholder]
          }
          errorMsg={formik.errors[item.placeholder]}
        />
      );
    }
  };
  const modelData = [
    {
      key: "signin",
      title: "Sign in with OTP",
      btnText: "Send OTP",
      field1: "Login with Google",
      field2: "Sign in with Facebook",
      field3: "Sign in with email",
      field4: "Don’t have an account?",
      field5: "Create a New Account",

      image: constants.DEFAULT_IMAGE + "/loginside_img.png",
      field1Img: constants.DEFAULT_IMAGE + "/googleimg.png",
      field2Img: constants.DEFAULT_IMAGE + "/facebookimg.png",
      field3Img: constants.DEFAULT_IMAGE + "/phone_img.png",
      mobileImg: constants.DEFAULT_IMAGE + "/loginside_mobile_img.png",
      inputfields: [
        {
          placeholder: "signin_Mobile_number",
          label: "Mobile number",
          type: "text",
          key: 1,
          value: "",
        },
        {
          placeholder: "signin_Email_ID",

          label: "Enter Email ID",
          type: "text",
          key: 2,
          value: "",
        },
      ],
    },
    {
      key: "signinotp",
      title: "Sign in with OTP",
      btnText: "Validate OTP",
      image: constants.DEFAULT_IMAGE + "/loginside_img.png",
      mobileImg: constants.DEFAULT_IMAGE + "/loginside_mobile_img.png",
      field4: "Don’t have an account?",
      field5: "Create a New Account",
      inputfields: [
        {
          placeholder: "signinotp Enter OTP",

          label: "Enter OTP",
          type: "text",

          key: 3,
          value: "",
        },
      ],
    },

    {
      key: "register",
      title: "Register for Your NAC Account",
      title2:
        "Join the platform and unlock access to streamlined services and resources tailored for you",
      btnText: "Register",
      image: constants.DEFAULT_IMAGE + "/loginside_img.png",
      mobileImg: constants.DEFAULT_IMAGE + "/loginside_mobile_img.png",
      field4: "Already have an account?",
      field5: "Log in here",
      inputfields: [
        {
          placeholder: "register_Dropdown",

          label: "Select Dropdown",
          type: "dropdown",
          options: ["Mr", "Miss", "Mrs"],
          key: 4,
          value: "",
        },
        {
          placeholder: "register_First_Name",

          label: "First Name",
          type: "text",
          key: 5,
          value: "",
        },
        {
          placeholder: "register_Last_Name",

          label: "Last Name",
          type: "text",
          key: 6,
          value: "",
        },
        {
          placeholder: "register_Mobile_number",

          label: "Mobile number",
          type: "text",
          key: 7,
          value: "",
        },
        {
          placeholder: "register_Enter_Email_ID",

          label: "Enter Email ID",
          type: "text",
          key: 8,
          value: "",
        },
        {
          placeholder: "register_Password",

          label: "Password",
          type: "password",
          key: 9,
          value: "",
        },
        {
          placeholder: "register_Confirm_Password",

          label: "Confirm Password",
          type: "password",
          key: 10,
          value: "",
        },
      ],
    },
    {
      key: "login",
      title: "Login to Complete Your Purchase",
      title2:
        "Please log in to proceed with purchasing your selected items from your wishlist.",
      title3: "You’re almost there!",
      btnText: "Login Securely",
      image: constants.DEFAULT_IMAGE + "/loginside_img.png",
      mobileImg: constants.DEFAULT_IMAGE + "/loginside_mobile_img.png",
      field1: "Login with Google",
      field2: "Sign in with Facebook",
      field3: "Sign in with OTP",
      field4: "Don’t have an account?",
      field5: "Create a New Account",
      forgot: "Forgot Password",
      // reset: "Reset Password",
      field1Img: constants.DEFAULT_IMAGE + "/googleimg.png",
      field2Img: constants.DEFAULT_IMAGE + "/facebookimg.png",
      field3Img: constants.DEFAULT_IMAGE + "/phone_img.png",

      inputfields: [
        {
          placeholder: "login_Email_ID_Mobile_number",
          label: "Enter Email ID / Mobile number",
          type: "text",
          key: 11,
          value: "",
        },
        {
          placeholder: "login_Password",

          label: "Password",
          type: "password",
          key: 12,
          value: "",
        },
      ],
    },
    {
      key: "forgot",
      title: "Forgot Password",
      title2: "Enter your email address to receive a verification email.",
      btnText: "Verify",
      image: constants.DEFAULT_IMAGE + "/forgetpasswordside_img.png",
      mobileImg: constants.DEFAULT_IMAGE + "/forgetpasswordside_img.png",
      field4: "Already have an account?",
      field5: "Log in here",
      field6: "Didn’t receive the link?",
      field7: "Check your spam or junk folder",
      inputfields: [
        {
          placeholder: "forgot_email_address",

          label: "Enter your email address",
          type: "text",
          key: 13,
          value: "",
        },
      ],
    },
    {
      key: "reset",
      title: "Reset Your Password",
      title2: "Create a new password to secure your account.",
      btnText: "Reset Password",

      field4: "Click here to ",
      field5: "Log in here",
      image: constants.DEFAULT_IMAGE + "/forgetpasswordside_img.png",
      mobileImg: constants.DEFAULT_IMAGE + "/forgetpasswordside_img.png",
      inputfields: [
        {
          label: "New Password",
          type: "password",
          key: 14,
          value: "",
          placeholder: "reset_New_Password",
        },
        {
          placeholder: "reset_Re-enter_new_password",

          label: "Re-enter your new password",
          type: "password",
          key: 15,
          value: "",
        },
      ],
    },
    {
      key: "subscribe",
      title: "Join Our Exclusive Club",
      title2:
        "Subscribe now and enjoy a flat 10% discount on your next purchase!",
      btnText: "Subscribe and Save!",
      field1: "Be the first to know about exclusive offers",
      field2: "Enjoy member-only discounts and perks.",
      field3: "We respect your privacy. Your details are safe with us",

      image: constants.DEFAULT_IMAGE + "/subscriper_leftside_img.png",
      field1Img: constants.DEFAULT_IMAGE + "/subscriper_Checked.png",
      field2Img: constants.DEFAULT_IMAGE + "/subscriper_schield.png",
      field3Img: constants.DEFAULT_IMAGE + "/subscriper_diamond_img.png",

      mobileImg: constants.DEFAULT_IMAGE + "/subscriper_leftside_img.png",
      inputfields: [
        {
          placeholder: "subscribe_Email_ID",

          label: "Your Email ID here",
          type: "text",
          key: 16,
          value: "",
        },
      ],
    },
  ];
  const getModeldata = () => {
    // signin signinotp register  login forgot reset subscribe
    const key = authModalType;
    const data = modelData?.filter((item) => item.key === key);

    return data;
  };

  const createValidationSchema = (formdata = null) => {
    const validation = {};
    console.log("createValidationSchema", formdata, "formikdsfsdf", validation);
    formdata !== null &&
      formdata?.forEach((item) => {
        if (item) {
          console.log("createValidationSchema", item);

          if (
            item.label === "Enter Email ID" ||
            item.label === "Your Email ID here"
          ) {
            validation[item.placeholder] = Yup.string()
              .email("Enter Valid Email Id ")
              .required(`Email Id is Required`);
          } else if (item.label === "Mobile number") {
            validation[item.placeholder] = Yup.string()
              .min(10, "Must be Exactly 10 digit")
              .required("Mobile number is Required");
          } else if (item.label === "Enter Email ID / Mobile number") {
            validation[item.placeholder] = Yup.string().required(
              `Email ID / Mobile number is Required`
            );
          } else if (item.label === "Enter OTP") {
            validation[item.placeholder] =
              Yup.string().required(`OTP  is Required`);
          } else if (item.label === "Select Dropdown") {
            validation[item.placeholder] = Yup.string().required(
              `Dropdown  is Required`
            );
          } else if (item.label === "Enter your email address") {
            validation[item.placeholder] = Yup.string()
              .email("Enter Valid Email Address")
              .required(`Email Address  is Required`);
          } else if (item.label === "Re-enter your new password") {
            validation[item.placeholder] = Yup.string()
              .required(`Re-enter Password is Required`)
              .oneOf(
                [Yup.ref("reset_New_Password")],
                "Password should be the same as New Password"
              );
          } else if (item.label === "Confirm Password") {
            validation[item.placeholder] = Yup.string()
              .required(`Confirm Password is Required`)
              .oneOf(
                [Yup.ref("register_Password")],
                "Password should be the same as Password Field"
              );
          } else if (item.placeholder === "register_Password") {
            validation[item.placeholder] = Yup.string()
              .required(`Password is Required`)
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                "Must be at least 8 characters long and include at least one uppercase letter,one lowercase  letter, one number, and one special character"
              );
          } else if (item.label === "New Password") {
            validation[item.placeholder] = Yup.string()
              .required(`New Password is Required`)
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                "Must be at least 8 characters long and include at least one uppercase letter,one lowercase  letter, one number, and one special character"
              );
          }

          // else if (item.label === "Your Email ID here") {
          //   validation[item.label] = Yup.string().required(
          //     ` is Required`
          //   );
          // }
          else {
            validation[item.placeholder] = Yup.string().required(
              `${item.label} is Required`
            );
          }
        }
      });

    return Yup.object().shape(validation);
  };
  const getFormvalues = () => {
    return getModeldata().flatMap((item) => {
      return item.inputfields;
    });
  };

  const initialValues = getFormvalues().reduce((acc, item) => {
    acc[item.placeholder] = item.value || "";
    return acc;
  }, {});

  // const validationSchema = createValidationSchema(getFormvalues());

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      // console.log("loginModalTypeauth", values, authModalType);

      // dispatch(getAuthModalType(authModalType));
      // console.log("Formsubmittedwithvalues:", values);
      if (authModalType === constants.Login) {
        // handleModelClick();
        // Toast("Login success", "success");
        dispatch(
          login(values.login_Email_ID_Mobile_number, values?.login_Password)
        );

        // dispatch(clearAuthModalType());
      } else if (authModalType === constants.Register) {
        dispatch(signup(values));
        // console.log("=====>enterRegister", values);

        // signup()
      } else if (authModalType === constants.Reset) {
        console.log("=====>enterreset", values);
        dispatch(resetPassword(values.reset_New_Password, token));
        return;
      } else if (authModalType === constants.Forgot) {
        console.log("=====>enterforgot", values);
        dispatch(forgotPassword(values?.forgot_email_address));
      }
    },
    enableReinitialize: true,
  });

  const handleButtonclick = (item) => {
    console.log("authmodalhandleButtonclick", item);
    formik.resetForm({
      values: initialValues,
    });
    if (item === "Sign in with OTP") {
      dispatch(getAuthModalType(constants.Signin));
    }
    if (item === "Create a New Account") {
      dispatch(getAuthModalType(constants.Register));
    }
    if (item === "Log in here" || item === "Sign in with email") {
      dispatch(getAuthModalType());
    }
    if (item === "Send OTP") {
      dispatch(getAuthModalType(constants.SigninOtp));
    }

    if (item === "Forgot Password") {
      dispatch(getAuthModalType(constants.Forgot));
    }
    if (item === "Reset Password") {
      dispatch(getAuthModalType(constants.Reset));
    }
  };
  const handlePrimaryBtn = (item) => {
    console.log("handlePrimaryBtn", item);
    formik.handleSubmit();
  };
  const handleTextinput = () => {};
  const onHandleClick = () => {
    // Navigate(constants.ADDITIONAL_PATH);
    window.location.replace(constants.ADDITIONAL_PATH);
    setTimeout(() => {
      handleModelClick();
    }, 1000);
  };
  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      //  onSubmit={formik.handleSubmit}
    >
      {getModeldata()?.map((item) => {
        return item.key !== "subscribe" ? (
          <section className="flex flex-row flex-wrap ">
            <div className=" lg:w-1/2 ">
              <img
                src={item?.image}
                className={`hidden lg:block w-full  h-[845px]
                    `}
                alt="sign in left side img"
              />
              <img
                src={item?.mobileImg}
                className={`block lg:hidden rounded-t-2xl ${
                  item?.key === "forgot" || item?.key === "reset"
                    ? "w-[600px] h-[300px] "
                    : "w-full "
                }`}
                alt="sign in left side img"
              />
            </div>
            <div
              className={`flex flex-col ${
                item?.key === "forgot" ||
                item?.key === "signinotp" ||
                item?.key === "reset"
                  ? "lg:py-32 py-10"
                  : "lg:py-[100px] py-10"
              }  items-center lg:w-1/2 relative px-6 w-full `}
            >
              {
                <div
                  onClick={() => handleModelClick()}
                  className="absolute top-2 lg:top-0 transition right-4 py-4 cursor-pointer "
                >
                  <img
                    src={
                      constants.DEFAULT_IMAGE + "/login_modal_close_icon.png"
                    }
                    className="w-6 h-6 "
                    alt="CloseIcon"
                  />
                </div>
              }
              <div className="flex flex-col items-center ">
                <div className="cursor-pointer" onClick={onHandleClick}>
                  <img
                    src={constants.DEFAULT_IMAGE + "/loginmodal_log.png"}
                    className="w-[150px] h-[32px]"
                    alt="sign in modal logo"
                  />
                </div>
                <div className=" flex flex-col items-center py-2">
                  <span className="text-[#222222] pt-3 block  text-lg  font-roboto">
                    {item.title}
                  </span>
                  {item.title3 && (
                    <span className="text-[#06AB9F] pt-5 block text-base font-medium  font-roboto">
                      {item.title3}
                    </span>
                  )}
                  {item.title2 && (
                    <span className="text-[#696969] pt-5 text-xs mx-6 text-center block font-normal font-roboto">
                      {item.title2}
                    </span>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 ">
                {item.inputfields.map((fields) => renderingData(fields))}
              </div>
              <div className="pt-2">
                <Button
                  variant="primary"
                  label={item.btnText}
                  btnfrom="login"
                  onClick={handlePrimaryBtn}
                  // onClick={handleButtonclick}
                />
              </div>
              {/* {item.field1 && item.field2 && (
                    <div className=" flex flex-row   py-5 w-full items-center px-20 md:px-14 lg:px-2 ">
                      <div className="w-55">
                        <img src={Line} className="h-px" alt="line img" />
                      </div>
                      <div className="flex flex-col p-1 justify-center font-roboto items-center ">
                        <span className="text-[#696969]">OR</span>
                      </div>
    
                      <div className="w-55">
                        <img src={Line} className="h-px" alt="line img" />
                      </div>
                    </div>
                  )} */}
              {/* {item.field1 && item.field2 && (
                    <div className="flex flex-row max-md:justify-center max-md:items-center max-md:flex-wrap">
                      <Button
                        icon={item.field1Img}
                        variant="secondary"
                        label={item.field1}
                        btnfrom="login icon"
                        onClick={handleButtonclick}
                      />
                      <Button
                        icon={item.field2Img}
                        variant="secondary"
                        label={item.field2}
                        btnfrom="login icon"
                        onClick={handleButtonclick}
                      />
                    </div>
                  )} */}
              {/* {item.field3 && (
                    <div
                      onClick={() => {
                        handleButtonclick(item.field3);
                      }}
                      className="flex flex-row items-center pt-4 cursor-pointer "
                    >
                      <img
                        src={item.field3Img}
                        className="w-5 h-5 pe-1"
                        alt="phone icon"
                      />
                      <span className="text-[#696969] font-roboto text-xs">
                        {item.field3}
                      </span>
                    </div>
                  )} */}
              {item.forgot && (
                <div className="flex flex-row text-base pt-4 cursor-pointer">
                  <span onClick={() => handleButtonclick(item.forgot)}>
                    {item.forgot}
                  </span>
                </div>
              )}
              {item.reset && (
                <div className="flex flex-row text-base pt-4 cursor-pointer">
                  <span onClick={() => handleButtonclick(item.reset)}>
                    {item.reset}
                  </span>
                </div>
              )}
              {item.field6 && item.field7 && (
                <div className="flex flex-row text-xs pt-4">
                  <span className="pe-2 font-roboto text-[#696969] font-normal">
                    {item.field6}
                  </span>
                  <span
                    onClick={() => {
                      handleButtonclick(item.field7);
                    }}
                    className="font-roboto text-[#696969] font-normal cursor-pointer"
                  >
                    {item.field7}
                  </span>
                </div>
              )}
              {item.field4 && item.field5 && (
                <div className="flex flex-row text-xs pt-4">
                  <span className="pe-2 font-roboto text-[#696969]">
                    {item.field4}
                  </span>
                  <span
                    onClick={() => {
                      handleButtonclick(item.field5);
                    }}
                    className="text-[#06AB9F] cursor-pointer font-roboto"
                  >
                    {item.field5}
                  </span>
                </div>
              )}
            </div>
          </section>
        ) : (
          <section className="flex flex-row flex-wrap ">
            <div className=" lg:w-[40%]  ">
              <img
                src={item?.image}
                className="hidden lg:block w-full lg:h-[650px] lg:rounded-l-2xl "
                alt="sign in left side img"
              />
              <img
                src={item.mobileImg}
                className="block lg:hidden w-[600px] h-[300px] rounded-t-2xl "
                alt="sign in left side img"
              />
            </div>
            <div
              className={`flex flex-col ${
                item.key === "forgot" ||
                item.key === "signinotp" ||
                item.key === "reset"
                  ? "lg:py-32 py-10"
                  : "lg:py-[100px] py-10"
              }  justify-center items-center lg:w-[60%] relative w-full  `}
            >
              <div className="absolute transition right-1 bottom-20">
                <img
                  src={item.field3Img}
                  className="w-32 h-32 hidden lg:block"
                  alt="diamond img"
                />
              </div>
              <div
                onClick={() => handleModelClick()}
                className="absolute top-2 lg:top-0 transition right-4 py-4 cursor-pointer"
              >
                <img
                  src={constants.DEFAULT_IMAGE + "/modelclose-icon.png"}
                  className="w-6 h-6 "
                  alt="CloseIcon"
                />
              </div>
              <div className="flex flex-col items-center ">
                <div>
                  <img
                    src={constants.DEFAULT_IMAGE + "/loginmodal_log.png"}
                    className="w-[170px] h-[40px]"
                    alt="sign in modal logo"
                  />
                </div>
                <div className=" flex flex-col items-center py-2">
                  <span className="text-[#020307] pt-5 block  text-[34px] font-bold font-Cormorant">
                    {item.title}
                  </span>
                  {item.title3 && (
                    <span className="text-[#06AB9F] pt-5 block text-base font-medium font-century  ">
                      {item.title3}
                    </span>
                  )}
                  {item.title2 && (
                    <span className="text-[#696969] pt-5 text-xs mx-6 text-center block font-normal font-roboto">
                      {item.title2}
                    </span>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2">
                {item.inputfields.map((fields) => renderingData(fields))}
              </div>
              <div className="pt-2">
                <Button
                  variant="primary"
                  label={item.btnText}
                  btnfrom="login"
                  onClick={handlePrimaryBtn}
                />
              </div>
              <div className=" py-10 flex flex-col max-md:-ms-10  ">
                {item.field1 && (
                  <div className="flex flex-row mt-2">
                    <img
                      className="w-5 h-5"
                      src={item.field1Img}
                      alt="tick img"
                    />
                    <span className="text-[#696969] font-roboto text-sm">
                      {item.field1}
                    </span>
                  </div>
                )}
                {item.field2 && (
                  <div className="flex flex-row mt-2">
                    <img
                      className="w-5 h-5"
                      src={item.field1Img}
                      alt="tick img"
                    />
                    <span className="text-[#696969] font-roboto text-sm">
                      {item.field2}
                    </span>
                  </div>
                )}
              </div>
              <div className=" absolute bottom-6  ms-5 max-md:-ms-4 ">
                {item.field3 && (
                  <div className="flex flex-row  items-center ">
                    <img
                      src={item.field2Img}
                      className="w-6 h-5 pe-1"
                      alt="shield icon"
                    />
                    <span className="text-[#696969] font-roboto text-xs">
                      {item.field3}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </section>
        );
      })}
    </form>
  );
};

export default Authenticatemodal;
