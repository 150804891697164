import React, { useState } from "react";
import constants from "../../utilities/constants";

const Dropdown = ({
  label = null,
  placeholder = "test",
  value,
  onChange,
  type = "text",
  keyvalue,
  options,
  name,
  error,
  errorMsg,
}) => {
  return (
    <>
      {label !== null ? (
        <section key={keyvalue}>
          <div className="text-base flex flex-row items-center justify-between font-medium text-[#353535]">
            <label>{label}</label>
            {error && <p className="text-[#E43138] text-sm">{errorMsg}</p>}
          </div>
          <div className="relative mt-4 ">
            <select
              className={`block w-full appearance-none  border ${
                error ? "border-[#E43138]" : "border-[#CACACA] "
              } p-2  focus:outline-none`}
              value={value}
              onChange={onChange}
              name={name}
            >
              <option value="" disabled>
                {`Select the ${label}`}
              </option>
              {options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <div>
              <img
                src={constants.DEFAULT_IMAGE + "/chevron-down.svg"}
                alt="Dropdown Icon"
                className="absolute top-1/2 right-3 w-6 h-6 transform -translate-y-1/2 pointer-events-none"
              />
            </div>
          </div>
        </section>
      ) : (
        <section key={keyvalue} className="col-span-2 pb-2">
          <div className="relative w-[310px] md:w-[384px]">
            <select
              className={`block w-full appearance-none  border ${
                error ? "border-[#E43138]" : "border-[#CACACA] "
              } p-2  focus:outline-none`}
              value={value}
              onChange={onChange}
              name={name}
            >
              <option value="">{` ${placeholder}`}</option>
              {options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <div>
              <img
                src={constants.DEFAULT_IMAGE + "/chevron-down.svg"}
                alt="Dropdown Icon"
                className="absolute top-1/2 right-3 w-6 h-6 transform -translate-y-1/2 pointer-events-none"
              />
            </div>
          </div>
          <div className={`${error ? "p-0 m-0" : "pt-3"}`}>
            {error && <p className="text-[#E43138] text-sm">{errorMsg}</p>}
          </div>
        </section>
      )}
    </>
  );
};

export default Dropdown;
